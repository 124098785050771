<template>
  <component :is="tag" class="container-lr">
    <slot />
  </component>
</template>

<script>
export default {
  props: {
    tag: {
      type: String,
      default: "div"
    }
  }
};
</script>

<style lang="scss" scoped>
@use "@/assets/styles/settings/_variables.scss";
.container-lr {
  display: flex;
  justify-content: center;
  flex-direction: column;
}
@media (min-width: 768px) {
  .container-lr {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
}
</style>
