<template>
  <div class="timeline">
    <div class="timeline__item" v-for="(item, index) in data" :key="index">
      <Title tag="h3" underlined>{{ item.point }}</Title>
      <b>{{ item.subtitle }}</b>
      <p>{{ item.tag }}</p>
    </div>
  </div>
</template>
<script>
import Title from "@/components/ui/atoms/Title.vue";
export default {
  components: {
    Title
  },
  props: {
    data: {
      type: Array,
      default: () => []
    }
  },
  setup(props) {
    return {
      columns: props.data.length
    };
  }
};
</script>
<style lang="scss" scoped>
$columns: 6;
$i: 0;
.timeline {
  display: block;
  font-size: 90%;
  line-height: 1.3;
  letter-spacing: 0;
}
@media (min-width: 992px) {
  .timeline {
    display: grid;
    grid-template-columns: repeat(#{$columns}, 1fr);
    grid-gap: 1em;
    align-items: end;
    font-size: 85%;
    &__item {
      height: auto;
      overflow-y: scroll;
      @while $columns > 0 {
        &:nth-child(#{$columns}) {
          height: calc(100% - #{$i * 57}px);
        }
        $i: $i + 1;
        $columns: $columns - 1;
      }
    }
  }
}
</style>
