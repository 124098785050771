<template>
  <section class="hero-background">
    <swiper
      :loop="true"
      slides-per-view="auto"
      effect="fade"
      :autoplay="{ delay: 4000, disableOnInteraction: false }"
    >
      <swiper-slide
        v-for="(slide, index) in slides"
        :key="index"
        :style="
          `background: url(${slide});
        background-size: cover !important; 
        background-position: center !important; 
        background-repeat: no-repeat !important;`
        "
      />
    </swiper>
    <slot />
  </section>
</template>

<script>
import SwiperCore, { EffectFade, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/swiper.scss";
import "swiper/components/effect-fade/effect-fade.scss";

SwiperCore.use([EffectFade, Autoplay]);

export default {
  name: "Hero",
  components: {
    Swiper,
    SwiperSlide
  },
  props: {
    slides: {
      type: Array,
      default: () => []
    }
  }
};
</script>
<style lang="scss" scoped>
.swiper-container {
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: -1;
}
.hero-background {
  background-size: cover;
  position: relative;
  height: 622px;
  background-position: bottom;
}

</style>
