<template>
  <Row>
    <!-- <Row>
      <ColumnUi class="pag-hide">
        Seleccionar tipo de webservice
      </ColumnUi>
      <ColumnUi>
        <select
          v-model="paginacionOpcional"
          name="igp-table_length"
          aria-controls="igp-table"
        >
          <option value="">Seleccionar</option>
          <option value="wfs">WFS</option>
          <option value="wms">WMS</option>
        </select>
      </ColumnUi>
    </Row>
    <br />
    <br /> -->
    <Row>
      <ColumnUi md="3">
        <Row>
          <ColumnUi class="pag-hide">
            Mostrar
          </ColumnUi>

          <ColumnUi>
            <select
              v-model="paginacionCtrl"
              name="igp-table_length"
              aria-controls="igp-table"
            >
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="30">30</option>
              <option value="-1">Todos</option>
            </select>
          </ColumnUi>
        </Row>
      </ColumnUi>
      <ColumnUi md="4" > </ColumnUi>
      <!-- <ColumnUi md="5" style="margin-left:40px">
        <label>
          <i class="fa fa-search" aria-hidden="true"> </i> 
          <span>Buscar: </span>
        </label>
        <input class="search" v-model="filteredData" placeholder="Ingrese el nombre o fecha para realizar su búsqueda"/>
      </ColumnUi> -->
    </Row>
  </Row>
  <slot name="datosViwer"></slot>
  <div>
    <div v-if="!datosTmp.length">
      {{ textoNulo }}
    </div>
    <div v-if="datosTmp.length && !filteredData">
      {{ sustantivo }} del {{ posicion }} al
      {{
        posicion + paginacion - 1 > datosTmp.length
          ? datosTmp.length
          : posicion + paginacion - 1
      }}
      de un total de {{ datosTmp.length }}
    </div>
    <div v-if="datosTmp.length && filteredData">
      {{ datos.length }} {{ sustantivo }} de un total de {{ datosTmp.length }}
    </div>
    <Row>
      <ColumnUi :class="posicion == 1 ? 'desactivado' : ''" @click="anterior()">
        <span>&lt;&lt;&nbsp; </span>
        <span>Anterior</span>
      </ColumnUi>
      <ColumnUi
        style="margin-left:40px"
        :class="posicion + paginacion >= datosTmp.length ? 'desactivado' : ''"
        @click="siguiente()"
      >
        <span>Siguiente</span>
        <span> >></span>
      </ColumnUi>
    </Row>
  </div>
</template>
<script>
import Row from "@/components/ui/atoms/Row.vue";
import ColumnUi from "@/components/ui/atoms/ColumnUi.vue";
export default {
  name: "paginacion",
  props: {
    datosTmp: Array,
    textoNulo: String,
    sustantivo: String
  },
  data() {
    return {
      posicion: 1,
      paginacion: 10,
      paginacionCtrl: 10,
      datos: [],
      filteredData: "",
      paginacionOpcional: ""
    };
  },
  compoenents: {
    Row,
    ColumnUi
  },
  watch: {
    paginacionCtrl: function(val) {
      // this.obtenerEventos(this.year,this.mes,this.paginacion)
      this.paginacion = parseInt(this.paginacionCtrl);
      if (val == -1) {
        this.paginacion = this.datosTmp.length;
      }
      this.posicion = 1;
      this.datos = this.datosTmp.slice(
        this.posicion - 1,
        this.posicion - 1 + this.paginacion
      );
      this.$emit("passPaginado", this.datos);
    },
    filteredData: function(filterKey) {
      this.filtrado(filterKey);
    },
    paginacionOpcional: function(value) {
      this.filtrado(value);
    }
  },
  methods: {
    filtrado: function(filterKey) {
      filterKey = filterKey.toLowerCase();
      if (filterKey) {
        let data = this.datos;
        data = data.filter(function(row) {
          return Object.keys(row).some(function(key) {
            return (
              String(row[key])
                .toLowerCase()
                .indexOf(filterKey) > -1
            );
          });
        });
        this.datos = data;
        this.posicion = data.length;
        this.$emit("passPaginado", this.datos);
      } else {
        this.datos = this.datosTmp;
        this.$emit("passPaginado", this.datos);
      }
    },
    siguiente: function() {
      this.filteredData = "";
      this.paginacionOpcional = "";
      if (this.posicion + this.paginacion < this.datosTmp.length) {
        this.posicion = this.posicion + this.paginacion;
        this.datos = this.datosTmp.slice(
          this.posicion - 1,
          this.posicion - 1 + this.paginacion
        );
        this.$emit("passPaginado", this.datos);
      }
    },
    anterior: function() {
      this.filteredData = "";
      this.paginacionOpcional = "";
      if (this.posicion !== 1) {
        this.posicion = this.posicion - this.paginacion;
        this.datos = this.datosTmp.slice(
          this.posicion - 1,
          this.posicion - 1 + this.paginacion
        );
        this.$emit("passPaginado", this.datos);
      }
    }
  },
  created() {
    this.posicion = 1;
    this.paginacion = 10;
    this.datos = this.datosTmp.slice(
      this.posicion - 1,
      this.posicion - 1 + this.paginacion
    );
    this.$emit("passPaginado", this.datos);
  }
};
</script>
<style lang="scss">
@use "@/assets/styles/settings/_variables.scss";
.desactivado {
  color: rgba(40, 40, 40, 0.3) !important;
}
.desactivado:hover {
  background-color: transparent !important;
}
.pag-show {
  display: none;
}
.search{
  padding: 10px;
  width: 60%;
  border: 1.5px solid #000,
}

@media (max-width: 770px) {
  .pag-hide {
    display: none;
  }
  .pag-show {
    display: unset;
  }

}
</style>
