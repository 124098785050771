<template>
    <div class="card">
        <Row>
            <ColumnUi md="12">
                <div class="card__img">
                  <Tag class="actions__tag">{{ TagApp }}</Tag>
                  <img class="imageApp" :src="ImageApp" alt="Aplicaciones IDE" >
                </div>
            </ColumnUi>
            <ColumnUi md="12">
                <div class="card__title">
                   <h3>{{NombreApp}}</h3>
                </div>
            </ColumnUi>
            <ColumnUi md="12" class="card__actions">
              <Button
                :href="UrlApp"
                theme="primary"
                target="_blank"
              >
                Acceder
              </Button>
            </ColumnUi>
        </Row>
    </div>
    
</template>
<script>
import Row from "@/components/ui/atoms/Row";
import ColumnUi from "@/components/ui/atoms/ColumnUi";
import Tag from "@/components/ui/atoms/Tag";
import Button from "@/components/ui/atoms/Button";


export default {
    props:{
        NombreApp: String,
        ImageApp: String,
        TagApp: String,
        UrlApp: String
    },
    components: {
      Row,
      ColumnUi,
      Tag,
      Button
    },
}
</script>



<style lang="scss" scoped>
@use "@/assets/styles/settings/_variables.scss";
.card{
    border: 0.5px solid #dbdbdb;
    border-radius: 5px;
    padding: 20px;
    max-height: 350px;
    position: relative;
    &__title{
      padding-bottom: 1.4rem;
    }
    &__img{
        margin-bottom: 0.85rem;
    }
  &__actions{
    bottom: 10px;
    position: absolute;
  }
}
.actions__tag{
  position: absolute;
}
.imageApp{
    border-radius: 5px;
}
</style>