import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";

import VueGtag from "vue-gtag-next";
import { trackRouter } from "vue-gtag-next";

trackRouter(router);

const app = createApp(App);
app.use(router);

app.use(VueGtag, {
  property: {
    id: "G-3E981XP4DE"
  }
});

app.mount("#app");
