<template>
  <nav class="trail">
    <Container>
      <List class="trail__list">
        <li class="trail__item" v-for="(item, index) in trail" :key="index">
          <a v-if="index == 0" class="trail__link icon-link" :href="item.path">
            <Icon svg="home" />
            {{ item.tag }}
          </a>
          <template v-else-if="index == trail.length - 1">
            {{ item.tag }}
          </template>
          <router-link v-else :to="item.path">
            {{ item.tag }}
          </router-link>
        </li>
      </List>
    </Container>
  </nav>
</template>
<script>
import { useRoute } from "vue-router";
import { getTrail } from "@/interface-data.js";
import { getLaboratories } from "@/laboratories.js";
import programs from "@/data.js";
import { getProjects } from "@/projects.js";
import Icon from "@/components/ui/atoms/Icon";
import Container from "@/components/ui/objects/Container";
import List from "@/components/ui/objects/List";
export default {
  components: {
    Container,
    List,
    Icon
  },

  setup() {
    const route = useRoute();
    let tag = "";
    const program = programs[process.env.VUE_APP_PREFIX];
    switch (route.name) {
      case "Subprogram":
        try {
          tag = program.subprograms.find(
            subprogram => subprogram.slug == String(route.params.subprogram)
          ).title;
        } catch {
          tag = "Error";
        }
        break;
      case "Projects":
        try {
          tag = getProjects(process.env.VUE_APP_PREFIX).find(
            projects => projects.slug == String(route.params.type)
          ).type;
        } catch {
          tag = "Error";
        }
        break;
      case "Laboratory":
        try {
          tag = getLaboratories(process.env.VUE_APP_PREFIX)[
            String(route.params.laboratory)
          ].find(section => section.slug == String(route.params.section)).title;
        } catch {
          tag = "Error";
        }
        break;

      default:
        tag = route.name;
    }

    const staticTrail = getTrail();
    const trail =
      String(route.name) === "Home"
        ? staticTrail
        : [
            ...staticTrail,
            {
              tag
            }
          ];
    return {
      trail
    };
  }
};
</script>
<style lang="scss" scoped>
@use "@/assets/styles/settings/_variables.scss";
.trail {
  padding: 1em 0;
  background-color: variables.$background-lighter-color;
  border-bottom: 1px solid variables.$border-trail-color;
  border-top: 0;
  &__list {
    display: flex;
    flex-grow: 1;
    overflow: auto;
    grid-column-gap: 0.2rem;
    column-gap: 0.2rem;
    list-style: none;
    mask-image: linear-gradient(270deg, transparent 1%, #000 40px);
  }
  &__item {
    display: inline;
    color: var(--color-txt);
    white-space: nowrap;
    &:not(:first-of-type):before {
      content: "|";
      margin: 1em;
    }
  }
}
</style>
