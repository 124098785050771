import { h } from "vue";
import List from "@/components/ui/objects/List";
import Card from "@/components/ui/atoms/Card";
import Button from "@/components/ui/atoms/Button";
import Row from "@/components/ui/atoms/Row";
import ColumnUi from "@/components/ui/atoms/ColumnUi";
const programs = {
  cts: {
    title: "Ciencias de la Tierra Sólida",
    description:
      "El programa de Ciencias de la Tierra Sólida (CTS) tiene por objetivo realizar investigaciones para contribuir en el conocimiento de la estructura interna de la tierra en el territorio peruano usando información sobre los parámetros geofísicos que definen la dinámica de las placas tectónicas.",
    subprograms: [
      {
        slug: "procesos-dinamica-interna",
        title: "Procesos de Dinámica Interna",
        content: () => [
          <p>
            Este subprograma se encarga del estudio y monitoreo de todos los
            procesos geofísicos que se originan en el interior de la Tierra
            debido a las variaciones de su energía interna produciendo
            terremotos y generando erupciones volcánicas, creación y
            reactivación de fallas en la corteza, movimiento de las placas
            tectónicas y efectos asociados como la generación de tsunamis, entre
            otros.
          </p>,
          <p>
            En el marco de este subprograma se han identificado cinco (05)
            líneas de investigación orientadas a conocer los procesos de
            dinámica interna de la Tierra:
          </p>,
          <List marked>
            <li>Dinámica de placas y sismotectónica</li>
            <li>Deformación de la corteza y estructuras volcánicas</li>
            <li>Patrones de sismicidad y efectos asociados</li>
            <li>
              Patrones pre-eruptivos y predicción de erupciones volcánicas
            </li>
            <li>Estructura interna de volcanes</li>
          </List>,
        ],
      },
      {
        slug: "procesos-fisicos-suelos",
        title: "Procesos Físicos de los Suelos",
        content: () => [
          <p>
            La física del suelo trata el estudio de las propiedades físicas del
            suelo y su comportamiento dinámico al ser afectadas por procesos
            internos de la Tierra como los sismos, erupciones volcánicas y
            agentes erosionales que controlan su morfología. La evaluación de
            las características físicas del subsuelo permite investigar el
            comportamiento de los suelos al paso de las ondas sísmicas, cuyos
            niveles de amplificación y periodos de respuesta son de gran
            incidencia para la planificación urbana de las ciudades. Los
            diferentes eventos sísmicos ocurridos en el Perú de variada magnitud
            han generado altos niveles de intensidad, con evidencia de daños
            observados post-sismo a nivel nacional. Diversos estudios muestran,
            que los suelos ante la incidencia de ondas sísmicas asociadas a
            movimientos débiles y/o fuertes, responden de acuerdo a sus
            condiciones locales, pudiendo estos modificar el contenido
            frecuencial de las ondas y/o generar amplificaciones de las ondas
            sísmicas.
          </p>,
          <p>
            En el marco de este subprograma se han identificado dos (02) líneas
            de investigación:
          </p>,
          <List marked>
            <li>Características geofísicas y dinámicas de suelos</li>
            <li>Fenomenología de suelos inestables</li>
          </List>,
        ],
      },
    ],

    lastpub: {
      title:
        "Sismotectónica del sismo de Arequipa del 16 de diciembre, 2020 (M5.5)",
      path: "https://repositorio.igp.gob.pe/handle/20.500.12816/4896",
      thumb:
        "https://repositorio.igp.gob.pe/bitstream/handle/20.500.12816/4896/Sismotectonica-del-sismo-de-Arequipa-del-16-de-diciembre-2020-%28M5.5%29.pdf.jpg?sequence=4&isAllowed=y",
      abstract:
        "La Comisión Multisectorial del ENFEN mantiene el estado del “Sistema de alerta ante El Niño y La Niña Costeros” como “No activo”, ya que la temperatura superficial del mar en la regiEn la región sur del Perú, el día 16 de diciembre del 2020 ocurre un sismo de magnitud moderada (M5.5) y epicentro ubicado a 44 km SO de la ciudad de Arequipa (región Arequipa). El sismo ocurrió a una profundidad de 93 km (foco intermedio) y en general, presentó un área de percepción con radio desde...",
    },
    community: "https://repositorio.igp.gob.pe/handle/20.500.12816/58",
    about: () => [
      <p>
        El programa de Ciencias de la Tierra Sólida tiene por objetivo
        contribuir al bienestar del país a través de los estudios de los
        parámetros geofísicos que permiten discernir lo que sucede en el
        interior de la Tierra. Tiene como función generar, promover, coordinar y
        ejecutar investigación científica y estudios interdisciplinarios en los
        campos de la Tierra Sólida, a través de líneas de investigación
        orientadas a conocer, ampliar y generar nuevo conocimiento geofísico
        para el desarrollo de la ciencia y contribuir al proceso de la
        estimación del peligro en la gestión del riesgo de desastres.
      </p>,
    ],
    slides: [
      "http://test.igp.gob.pe/paginaigp/images/slider/cts_slide_1.png",
      "http://test.igp.gob.pe/paginaigp/images/slider/cts_slide_2.png",
      "http://test.igp.gob.pe/paginaigp/images/slider/cts_slide_3.png",
      "http://test.igp.gob.pe/paginaigp/images/slider/cts_slide_4.png",
    ],
  },
  cah: {
    title: "Ciencias de la Atmósfera e Hidrósfera",
    description:
      "Este programa realiza investigación científica para generar evidencias que son base sólida para la toma de decisiones en la gestión de riesgos de desastres, el manejo del ambiente y la adaptación al cambio climático, entre otros....",
    subprograms: [
      {
        slug: "oscilacion-sur",
        title: "El Niño - Oscilación del Sur (ENSO)",
        content: () => [
          <p>
            El ENSO (El Niño-Oscilación del Sur) es un fenómeno natural que
            integra la dinámica del océano y la atmósfera y cuyas
            manifestaciones alteran el clima global. Los términos El Niño / La
            Niña corresponden a la fase positiva/negativa del ENSO en su
            componente oceánico el cual se define como el
            calentamiento/enfriamiento anormal y sostenido de la superficie del
            Océano Pacífico tropical. Por otra parte, el término Fenómeno El
            Niño (FEN) se asocia a perturbaciones climáticas como lluvias
            intensas en la costa norte y/o sequías en otras regiones del Perú,
            aumento de nivel del mar, entre otras. El ENSO (en el sentido más
            amplio de su definición) es un fenómeno recurrente, pero no
            periódico, en escala interanual.{" "}
          </p>,
          <p>
            Las investigaciones desarrolladas en este subprograma de
            investigación están orientadas a conocer mejor la naturaleza física
            del evento, los tipos de manifestaciones y sus impactos en el
            territorio nacional. De esta manera, se pretende contribuir a la
            generación de información y productos que ayuden a mejorar su
            pronóstico y los impactos que se generan durante su manifestación.
          </p>,
          <p>
            En el marco de este subprograma se han identificado tres (03) líneas
            de investigación:
          </p>,
          <List>
            <li>
              Mecanismos físicos asociados a la génesis de El Niño/La Niña y su
              predicción
            </li>
            <li>Efectos del ENSO en la dinámica atmosférica continental</li>
            <li>Impactos del ENSO en la hidrosedimentología</li>
          </List>,
        ],
      },
      {
        slug: "variabilidad-cambio-climatico",
        title: "Variabilidad y Cambio Climático en el Perú",
        content: () => [
          <p>
            El Perú debido a su ubicación geográfica y su topografía, posee
            diferentes climas, los cuales están afectados por la variabilidad
            del clima de escala regional y global. Este subprograma, considera
            estudiar las variaciones del clima a escalas temporales que van
            desde la escala intraestacional hasta la escala de cambio climático.
            Para conocer las causas de las variaciones del clima local, se
            identifican los procesos físicos y dinámicos locales, así como a
            nivel regional que condicionan los patrones climáticos asociados a
            los océanos, cuya variabilidad afectarán al clima local, mediante
            oscilaciones o teleconexiones. También se consideran las
            reconstrucciones del clima pasado y sus proyecciones futuras
            considerando los efectos antrópicos que influyen en la variabilidad
            natural del clima.
          </p>,
          <p>
            Se usan datos históricos, así como datos de modelos estadísticos y
            dinámicos para caracterizar el clima local, así como para
            identificar los factores que influyen en su variabilidad. Este
            conocimiento sirve para desarrollar modelos físicos y/o estadísticos
            que permitan mejorar los pronósticos a diversas escalas
            espacio-temporales en territorio peruano. Se analiza la interacción
            entre las diferentes escalas de variabilidad y el cambio climático,
            siendo importante los impactos que estos generan en la variabilidad
            de la dinámica costera y la corriente de Humboldt, la hidrología,
            los suelos y los ecosistemas.
          </p>,
          <p>
            El objetivo de este subprograma es generar conocimiento que apoye en
            la toma de decisiones y permita una gestión adecuada de los impactos
            de la variabilidad climática en el Perú, así como para las medidas
            de adaptación al cambio climático.
          </p>,
          <p>
            En el marco de este subprograma se han identificado seis (06) líneas
            de investigación:
          </p>,
          <List marked>
            <li>Dinámica del clima en el Perú</li>
            <li>Reconstrucciones paleoambientales en el Perú</li>
            <li>Efectos hidrológicos de la variabilidad climática</li>
            <li>Actividad antrópica y efectos ecosistémicos</li>
            <li>
              Dinámica y sensibilidad al cambio climático de los sistemas de
              afloramiento de bordes orientales
            </li>
            <li>Procesos radiativos y aerosoles</li>
          </List>,
        ],
      },
      {
        slug: "eventos-meteorologicos-hidroclimaticos",
        title: "Eventos Meteorológicos e Hidroclimáticos Extremos",
        content: () => [
          <p>
            Los eventos extremos en general definen como aquellos que ocurren
            muy rara vez, el 5% del tiempo, y pueden ser potencialmente
            peligrosos y generar riesgo en zonas con alta vulnerabilidad. El
            Perú, por su geografía, está expuesto a fenómenos climáticos, como
            El Niño, que generan eventos de lluvias extremas en la costa norte y
            sequías en los Andes centrales y el sur. Por otro lado, la sierra
            donde hay población y actividades agrícolas por encima de los 4000
            msnm, están expuestas a bajas temperaturas y heladas durante la
            temporada seca, alta insolación durante el día y a lluvias intensas
            durante la temporada húmeda, que causan huaicos, deslizamientos y
            avalanchas, a la vez que pueden ocurrir sequías y veranillos. La
            Amazonía, por su parte, también alterna periodos de sequías e
            inundaciones, así como friajes en la Amazonía con graves
            consecuencias para la población y el ecosistema.
          </p>,
          <p>
            Por ello, los eventos meteorológicos (de corta duración) e
            hidroclimáticos extremos serán objeto de estudios de este
            subprograma, con el propósito de conocer mejor la frecuencia, la
            intensidad, las tendencias y las proyecciones futuras de los mismos,
            así como caracterizar los eventos y conocer los procesos físicos y
            dinámicos que lo originan, a fin de generar herramientas que
            permitan elevar su predictibilidad en diferentes escalas de espacio
            y de tiempo. Para ello, será imprescindible elevar la capacidad de
            observación de las variables y fenómenos meteorológicos e
            hidro-climáticos sobre el territorio peruano, potenciar los
            observatorios o laboratorios e implementar nuevos puntos de
            monitoreo o campañas de mediciones usando instrumentos, como radares
            meteorológicos, que permitan obtener información para validar los
            modelos. Paralelamente, es imprescindible potenciar las capacidades
            de cálculo, de manera que sea posible la implementación de modelos
            numéricos a escala de tiempo y de clima.
          </p>,
          <p>
            Este subprograma también contempla realizar estudios sobre los
            impactos de los eventos meteorológicos e hidroclimáticos extremos en
            los ecosistemas, en la hidrología y suelos, así como el efecto de
            los aerosoles en la radiación, con el objeto de aportar conocimiento
            para la GRD.
          </p>,
          <p>
            En el marco de este subprograma se han identificado seis (03) líneas
            de investigación:
          </p>,
          <List marked>
            <li>Eventos meteorológicos extremos</li>
            <li>Eventos hidro-climáticos extremos</li>
            <li>Efectos de los eventos extremos en los sistemas ambientales</li>
          </List>,
        ],
      },
    ],
    lastpub: {
      title: "Comunicado Oficial ENFEN N°01-2021",
      path: "https://repositorio.igp.gob.pe/handle/20.500.12816/4899",
      thumb:
        "https://repositorio.igp.gob.pe/bitstream/handle/20.500.12816/4899/Comunicado_Oficial_ENFEN_N%c2%b001-2021.pdf.jpg?sequence=4&isAllowed=y",
      abstract:
        "La Comisión Multisectorial del ENFEN mantiene el estado del “Sistema de alerta ante El Niño y La Niña Costeros” como “No activo”, ya que la temperatura superficial del mar en la región Niño 1+2, que incluye la zona norte y centro del mar peruano, se mantendría dentro de su rango normal hasta abril, inclusive. Por su parte, La Niña en el Pacífico central tenderá a debilitarse en lo que resta del presente verano. Sin embargo, continuaría contribuyendo a la ocurrencia de lluvias superiores a lo normal en gran parte de los sectores central y sur del país durante lo que resta del verano, especialmente en la zona andina. En la selva peruana no se descartaría episodios de lluvia superiores a sus valores normales. La Comisión Multisectorial del ENFEN continuará monitoreando e informando sobre la evolución de las condiciones oceánicas y atmosféricas, y actualizando sus perspectivas.",
    },
    community: "https://repositorio.igp.gob.pe/handle/20.500.12816/55",
    about: () => [
      <p>
        El programa de Ciencias de la Atmósfera e Hidrósfera, tiene por ojetivo
        generar conocimiento sobre los fenómenos físicos que ocurren en el
        sistema climático (atmósfera, océano, suelo, criósfera y biósfera), los
        cuales interactúan entre sí, produciendo variaciones climáticas en
        diferentes escalas espaciales y temporales. Dicha labor se realiza con
        datos in situ obtenidos en los observatorios, laboratorios, campañas de
        observaciones y mediciones, así como información de sensores remotos y
        aquellos generados por los modelos numéricos. Se consideran también los
        datos históricos, así como reconstrucciones ambientales o registros
        paleoclimáticos. Toda esta gama de información es usada tanto para
        corregir y validar los modelos climáticos, hidrológicos,
        sedimentológicos, entre otros, así como para evaluar los impactos
        físicos del cambio climático en el Perú.
      </p>,
    ],
    slides: [
      "http://test.igp.gob.pe/paginaigp/images/slider/cah_slide_1.png",
      "http://test.igp.gob.pe/paginaigp/images/slider/cah_slide_2.png",
      "http://test.igp.gob.pe/paginaigp/images/slider/cah_slide_3.png",
      "http://test.igp.gob.pe/paginaigp/images/slider/cah_slide_4.png",
    ],
  },
  cga: {
    title: "Ciencias del Geoespacio y Astronomía",
    description:
      "Tiene por objetivo realizar investigaciones acerca de los fenómenos que ocurren en las capas más altas de la atmósfera terrestre y en el espacio que nos rodea, a fin de entender...",
    subprograms: [
      {
        slug: "aeronomia-clima-espacial",
        title: "Aeronomía y Clima Espacial",
        content: () => [
          <p>
            La variabilidad de los fenómenos en el Sol y del comportamiento del
            medio interplanetario es conocida como Clima o Tiempo Espacial 5 , y
            tienen efecto en las capas más altas y externas de la Tierra, como
            la magnetósfera y la ionósfera, generando perturbaciones en estas
            regiones cuyas características e impactos son variables. Estas
            regiones, denominadas geoespacio, también se ven afectadas por los
            procesos internos y superficiales de la Tierra, que generan
            perturbaciones que se pueden propagar hasta estas regiones.
          </p>,
          <p>
            Estas perturbaciones no sólo afectan las características físicas de
            estas capas altas sino también a las señales de radio frecuencia que
            se propagan a través de ellas. Por ello, el clima espacial tiene
            influencia sobre los sistemas de navegación y comunicación
            desarrollados por el hombre y en consecuencia en los medios de vida
            de las personas 6 . En el caso particular del Perú, al estar su
            territorio ubicado en la región del Ecuador magnético, nuestro país
            se ve afectado por fenómenos ionosféricos propios de esta región,
            por ejemplo, el electrochorro ecuatorial 7 y las burbujas de plasma
            o F-dispersa ecuatoriales 8 . Estas perturbaciones ionosféricas
            pueden degradar o bloquear comunicaciones satelitales o inducir
            errores significativos en los sistemas de posicionamiento y
            navegación, razón por la cual se requiere estudiar sus causas e
            impactos.
          </p>,
          <p>
            De este modo, con el fin de atender la problemática descrita
            anteriormente, se han identificado cuatro (04) líneas de
            investigación:
          </p>,
          <List marked>
            <li>Caracterización del geoespacio</li>
            <li>Física de la corona solar y medio interplanetario espacial</li>
            <li>Física de las inestabilidades ionosféricas</li>
            <li>
              Impacto de las perturbaciones ionosféricas en las tecnologías del
              país
            </li>
          </List>,
        ],
      },
      {
        slug: "meteoros-objetos-espaciales",
        title: "Meteoros y Objetos Espaciales",
        content: () => [
          <p>
            La Tierra es continuamente bombardeada por partículas y objetos de
            diferentes tamaños y composiciones. Algunas de estas partículas se
            desintegran en la atmósfera de la Tierra y tienen influencia en el
            proceso de circulación de la atmósfera pudiendo afectar la
            variabilidad de nuestro clima. Otros objetos, sin embargo, sí logran
            atravesar la atmósfera impactando directamente nuestra superficie.
            Esto representa una amenaza a la integridad tanto de la
            infraestructura física como de la vida de sus pobladores, por ello
            la relevancia de estudiar y monitorear los objetos que rodean la
            Tierra.
          </p>,
          <p>
            Este sub-programa ha identificado dos (02) líneas de investigación
            con el fin de dar respuesta a la pregunta de investigación
            planteada.
          </p>,
          <List marked>
            <li>Dinámica de meteoros y objetos espaciales</li>
            <li>
              Física de interacción entre objetos espaciales y
              ionósfera/atmósfera
            </li>
          </List>,
        ],
      },
      {
        slug: "astronomia",
        title: "Astronomía",
        content: () => [
          <p>
            Existen objetos astronómicos y regiones del espacio que sólo pueden
            ser observados desde el hemisferio sur de la Tierra y más
            específicamente desde nuestro país. Utilizando tanto instrumentación
            óptica como de radio frecuencia. Aplicando técnicas de procesamiento
            de señales e imágenes sobre los datos adquiridos, se pueden
            determinar diferentes parámetros físicos de estos objetos, como su
            tamaño, la distancia a la que se encuentran e inclusive su
            composición.
          </p>,
        ],
      },
    ],
    lastpub: {
      title:
        "Astrometric observations of the Potentially Hazardous Asteroid (52768) 1998 OR2 near its maximum approach to earth",
      path: "https://repositorio.igp.gob.pe/handle/20.500.12816/4868",
      thumb:
        "https://repositorio.igp.gob.pe/bitstream/handle/20.500.12816/4868/ATel%2313733.pdf.jpg?sequence=4&isAllowed=y",
      abstract:
        "Our team presents astrometric measurements of the PHA (52768) 1998 OR2 obtained close to its maximum approach to Earth. The observations were made from Observatorio Planetario Moche, located in Trujillo, Peru (Long. 79 00 39.2 W, Lat. 08 06 47.9 S, Alt. 48 m), on April 23, 24, 25 and 29, 2020. It is",
    },
    community: "https://repositorio.igp.gob.pe/handle/20.500.12816/56",
    about: () => [
      h(
        "p",
        {},
        "Este subprograma se encarEl programa de Ciencias del Geoespacio y Astronomía tiene por objetivo contribuir al bienestar del país generando conocimiento científico sobre los fenómenos que ocurren en las capas más altas de la atmósfera terrestre (particularmente en la ionósfera), así como, sobre la variabilidad del clima del espacio que rodea la Tierra."
      ),
      h(
        "p",
        {},
        "Además, se busca promover la formación de conciencia en la sociedad sobre nuestra vulnerabilidad ante los peligros o impactos que estos fenómenos pueden generar sobre los medios de vida de las personas. También comprende prepararnos adecuadamente en el manejo de los posibles desastres que los eventos geoespaciales causen sobre el territorio peruano. Es mediante la investigación científica que se busca entender el origen y dinámica de estos fenómenos con el objetivo de poder modelar la formación de los mismos y así generar pronósticos de su ocurrencia que sean de utilidad al estado y a la sociedad en general."
      ),
    ],
    slides: [
      "http://test.igp.gob.pe/paginaigp/images/slider/geoa_slide_1.png",
      "http://test.igp.gob.pe/paginaigp/images/slider/geoa_slide_2.png",
      "http://test.igp.gob.pe/paginaigp/images/slider/geoa_slide_3.png",
      "http://test.igp.gob.pe/paginaigp/images/slider/geoa_slide_4.png",
    ],
  },
  ide: {
    title: "INFRAESTRUCTURA DE DATOS ESPACIALES DEL IGP (IDE – IGP)",
    description:
      "La Infraestructura de Datos Espaciales del Instituto Geofísico del Perú (IDE-IGP) es un conjunto articulado de estándares, procesos, tecnologías y recursos humanos que se encuentran integrados y destinados a facilitar al ciudadano su acceso a la información geográfica contenida en los servicios que brinda el IGP en el campo de la geofísica y disciplinas afines.",
    subprogramList: {
      title: "Aplicaciones",
      content: () => [
        <p>
          La IDE-IGP dispone de facilidades para que los ciudadanos accedan a
          diversos servicios, tales como consultas, geoprocesamiento de la
          información geofísica, intersección de capas, impresión de mapas,
          entre otros. Su objetivo principal es brindar al ciudadano la
          información de manera fácil e interactiva al momento de su
          visualización o consulta.
        </p>,
      ],
      links: [
        {
          title: "Monitoreo Sísmico (dashboard)",
        },
        {
          title: "Monitoreo Volcánico (dashboard)",
        },
      ],
    },
    subprograms: [
      {
        slug: "que-es-la-IDE-IGP",
        origin: "acerca-de",
        title: "¿Qué es la IDE-IGP?",
        content: () => [
          <p>
            La Infraestructura de Datos Espaciales del Instituto Geofísico del
            Perú (IDE-IGP) es un conjunto articulado de estándares, procesos,
            tecnologías y recursos humanos que se encuentran integrados y
            destinados a facilitar al ciudadano su acceso a la información
            geográfica contenida en los servicios que brinda el IGP en el campo
            de la geofísica y campos afines.
          </p>,
          <p>
            La IDE-IGP establece una plataforma tecnológica de integración de
            datos, servicios y aplicaciones geoespaciales garantizando la
            interoperabilidad de los datos producidos por el Instituto Geofísico
            del Perú y que éstos puedan ser reutilizados por los ciudadanos.
          </p>,
        ],
      },
      /*    {
        slug: "que-es-un-geoportal",
        origin: "acerca-de",
        title: "¿Qué es un geoportal?",
        content: () => [
          <p>
            Es un sitio web cuya finalidad es ofrecer a los usuarios el acceso a una serie de 
            recursos y servicios basados en la información geográfica. Permite el descubrimiento, 
            el acceso y la visualización de los datos geoespaciales, utilizando un navegador 
            estándar y posibilita la integración, la interoperabilidad y el intercambio de
            información entre las diversas instituciones, profesionales, empresas de 
            servicios, entre otros.
          </p>
        ]
      }, */
      {
        slug: "cuales-son-los-objetivos",
        origin: "acerca-de",
        title: "¿Cuáles son los objetivos del IDE-IGP?",
        content: () => [
          <List marked>
            <li>
              Dar a conocer la información geográfica del Instituto Geofísico
              del Perú facilitando el acceso público a ésta.
            </li>
            <li>
              Impulsar la difusión de los estudios y proyectos generados por el
              Instituto Geofísico del Perú en el campo de la geofísica y ramas
              afines.
            </li>
            <li>
              Elaborar estándares que describan la información disponible y
              asistan a sus productores.
            </li>
            <li>
              Recopilar y sistematizar los metadatos para presentarlos a
              disposición del público a través de un Catálogo de Metadatos.
            </li>
            <li>
              Facilitar el acceso a la información geográfica mediante
              procedimientos en línea, de manera eficiente y rápida.
            </li>
            <li>
              Promover la importancia de la información geográfica y contribuir
              a crear y ampliar su demanda.
            </li>
            <li>
              Dar a conocer al público la oferta de datos geográficos,
              facilitando su reutilización.
            </li>
            <li>
              Facilitar la integración de los datos geográficos entre las
              diversas entidades que generan el mismo tipo de información.
            </li>
            <li>
              Contribuir a mantener y mejorar la calidad de los datos
              geográficos que el Instituto Geofísico del Perú ofrece al
              ciudadano.
            </li>
          </List>,
        ],
      },
      {
        slug: "visor-geografico",
        origin: "",
        title: "Visor Geográfico",
        content: () => [
          <p>
            El Visor Geográfico del IGP es un portal web que permite al
            ciudadano explorar de manera visual la información geoespacial.
            Mediante esta aplicación, los usuarios pueden visualizar, consultar,
            analizar e imprimir información generada por el IGP en el campo de
            la geofísica, la cual está ordenada y distribuida en un listado de
            capas por temáticas.
          </p>,
          <p>
            <b>Temáticas: </b>
          </p>,
          <Row>
            <ColumnUi lg="6" md="6">
              <Card name="Zonifica Perú : Zonificación Geofísica y Geotécnica de los suelos">
                <Button
                  href="https://ide.igp.gob.pe/geovisor/zonificaperu/acerca-de"
                  target="_blank"
                  tag="a"
                  theme="primary"
                >
                  Acceder
                </Button>
              </Card>
            </ColumnUi>
            <ColumnUi lg="6" md="6">
              <Card name="Monitoreo de la vegetación para la prevención de incendios forestales">
                <Button
                  href="https://ide.igp.gob.pe/geovisor/ndvi/"
                  target="_blank"
                  tag="a"
                  theme="primary"
                >
                  Acceder
                </Button>
              </Card>
            </ColumnUi>
          </Row>,
        ],
      },
      {
        slug: "metadatos",
        origin: "",
        title: "Catálogo de Metadatos",
        content: () => [
          <p>
            El catálogo de metadatos es una herramienta que permite la
            publicación y búsqueda de Metadatos de datos, servicios y
            aplicaciones Web, obtener información detallada sobre los datos
            geoespaciales y los servicios web OGC del IGP, sus características y
            cómo acceder a ellas. Los metadatos son los registros que describen
            las características de los datos geoespaciales o recursos de
            información geoespacial del IGP. Su funcionalidad es la de conocer
            la calidad de los datos publicados, consistente en información que
            caracteriza a los datos, describen el contenido, condiciones,
            historia, disponibilidad y otras características de los datos.
          </p>,
          <Button
            href="https://ide.igp.gob.pe/geonetwork/srv/spa/catalog.search#/home"
            target="_blank"
            tag="a"
            theme="primary"
          >
            Acceder
          </Button>,
        ],
      },
      {
        slug: "datos-abiertos",
        origin: "",
        title: "Datos abiertos",
        content: () => [
          <p>
            El Portal de Datos Abiertos es un portal donde la transparencia es
            una herramienta fundamental para hacer conocer lo que realiza el
            IGP. Es una iniciativa global, ligada a las políticas de Gobierno
            Abierto, que persigue que los datos y la información, especialmente
            las que poseen las administraciones públicas, se publiquen de forma
            abierta, regular y reutilizable para todo el mundo, sin
            restricciones de acceso, copyright, patentes u otros mecanismos de
            control.
          </p>,
          <Button
            href="https://www.datosabiertos.gob.pe/?query=igp&sort_by=changed&sort_order=DESC"
            target="_blank"
            tag="a"
            theme="primary"
          >
            Acceder
          </Button>,
        ],
      },
    ],
    lastpub: {
      title:
        "Astrometric observations of the Potentially Hazardous Asteroid (52768) 1998 OR2 near its maximum approach to earth",
      path: "https://repositorio.igp.gob.pe/handle/20.500.12816/4868",
      thumb:
        "https://repositorio.igp.gob.pe/bitstream/handle/20.500.12816/4868/ATel%2313733.pdf.jpg?sequence=4&isAllowed=y",
      abstract:
        "Our team presents astrometric measurements of the PHA (52768) 1998 OR2 obtained close to its maximum approach to Earth. The observations were made from Observatorio Planetario Moche, located in Trujillo, Peru (Long. 79 00 39.2 W, Lat. 08 06 47.9 S, Alt. 48 m), on April 23, 24, 25 and 29, 2020. It is",
    },
    community: "https://repositorio.igp.gob.pe/handle/20.500.12816/56",
    about: () => [
      h(
        "p",
        {},
        "Este subprograma se encarEl programa de Ciencias del Geoespacio y Astronomía tiene por objetivo contribuir al bienestar del país generando conocimiento científico sobre los fenómenos que ocurren en las capas más altas de la atmósfera terrestre (particularmente en la ionósfera), así como, sobre la variabilidad del clima del espacio que rodea la Tierra."
      ),
      h(
        "p",
        {},
        "Además, se busca promover la formación de conciencia en la sociedad sobre nuestra vulnerabilidad ante los peligros o impactos que estos fenómenos pueden generar sobre los medios de vida de las personas. También comprende prepararnos adecuadamente en el manejo de los posibles desastres que los eventos geoespaciales causen sobre el territorio peruano. Es mediante la investigación científica que se busca entender el origen y dinámica de estos fenómenos con el objetivo de poder modelar la formación de los mismos y así generar pronósticos de su ocurrencia que sean de utilidad al estado y a la sociedad en general."
      ),
    ],
    slides: [
      "/servicios/infraestructura-de-datos-espaciales/images/slider/ide_1.webp",
      "/servicios/infraestructura-de-datos-espaciales/images/slider/ide_2.webp",
    ],
  },
};

export default programs;
