<template>
  <main>
    <Trail />
    <Container class="container--main">
      <Row>
        <ColumnUi :md="three ? 9 : 12">
          <Title tag="h1">{{ title }}</Title>
          <Content
            v-if="singleTypeProjects.content"
            :content="singleTypeProjects.content"
          />
          <!-- <Paginacion :datosTmp="datosTmp" @passPaginado="passPaginado" 
                    sustantivo="Servicios webs" 
                    textoNulo="No tenemos servicios webs disponibles, Tan pronto se levanten los servicios, se estarán publicando.">
                         <template v-slot:datosViwer> -->
          <Row style="row-gap: 2rem;">
            <ColumnUi md="4">
              <CardApps
                ImageApp="images/apps/monitoreo_sismico.webp"
                NombreApp="Monitoreo Sísmico"
                TagApp="Tablero de control"
                UrlApp="https://ide-igp.maps.arcgis.com/apps/dashboards/1ee1b5f32a424426aca0b1b81660e34c"
              >
              </CardApps>
            </ColumnUi>
            <ColumnUi md="4">
              <CardApps
                ImageApp="images/apps/monitoreo_volcanico.webp"
                NombreApp="Monitoreo Volcánico"
                TagApp="Tablero de control"
                UrlApp="https://ide.igp.gob.pe/portal/apps/dashboards/1c26d652f18f4771b218afa82fc94897"
              >
              </CardApps>
            </ColumnUi>
            <ColumnUi md="4">
              <CardApps
                ImageApp="images/apps/monitoreo_ubinas.webp"
                NombreApp="Monitoreo volcán Ubinas"
                TagApp="Tablero de control"
                UrlApp="https://ide.igp.gob.pe/portal/apps/experiencebuilder/experience/?id=b9871ac0531047fd8a33a28f6c173eda"
              >
              </CardApps>
            </ColumnUi>
            <ColumnUi md="4">
              <CardApps
                ImageApp="images/apps/monitoreo_sabancaya.webp"
                NombreApp="Monitoreo volcán Sabancaya"
                TagApp="Tablero de control"
                UrlApp="https://ide.igp.gob.pe/portal/apps/experiencebuilder/experience/?id=dbb582d5662d4fb89338f4a6cec42371"
              >
              </CardApps>
            </ColumnUi>
          </Row>
        </ColumnUi>
        <ColumnUi md="3" v-if="three">
          <EnEstaSeccion :three="three"></EnEstaSeccion>
        </ColumnUi>
      </Row>
    </Container>
  </main>
</template>
<script>
import { getProjects } from "@/projects.js";
import { Cabecera, Datos } from "@/tablaAplicaciones.js";
import { useRoute } from "vue-router";
import Container from "@/components/ui/objects/Container.vue";
import Title from "@/components/ui/atoms/Title.vue";
import Trail from "@/components/common/Trail.vue";
/* import TableUi from "@/components/ui/objects/TableUi.vue"; */
/* import Modal from "@/components/common/Modal.vue"; */
import Content from "../components/common/Content.vue";
import Row from "../components/ui/atoms/Row.vue";
import ColumnUi from "../components/ui/atoms/ColumnUi.vue";
/* import List from '../components/ui/objects/List.vue'; */
/* import Input from "@/components/ui/atoms/Input" */
import { getSection } from "@/interface-data.js";
import EnEstaSeccion from "@/components/ui/objects/EnEstaSeccion";
/* import Paginacion from '@/components/ui/objects/Paginacion' */
import { reactive, ref, watch } from "vue";
import CardApps from "@/components/ui/atoms/CardApps.vue";

export default {
  components: {
    Trail,
    Container,
    /*    Input, */
    /*TableUi,*/
    Title,
    EnEstaSeccion,
    Content,
    Row,
    ColumnUi,
    CardApps,
    /*  Paginacion */
    /*         List, */
  },
  emits: ["setOverlay"],
  setup(props, { emit }) {
    const filter = ref("");
    const type = useRoute()
      .path.split("/")
      .slice(-1)[0];
    const allProjects = getProjects(process.env.VUE_APP_PREFIX);
    const singleTypeProjects = allProjects.find(
      (projects) => projects.slug == type
    );
    const title =
      (singleTypeProjects && singleTypeProjects.type) ||
      `No se encontraron los proyectos ${type}`;
    const projectTypes = [];
    allProjects.forEach((e) => {
      projectTypes.push({ slug: e.slug, title: e.type });
    });
    let showModal = ref(false);
    let showProject = reactive({ data: {} });
    const openModal = (project) => {
      showModal.value = true;
      showProject.data = project;
    };
    const close = () => {
      showModal.value = false;
    };

    let filteredProjects =
      (singleTypeProjects && reactive({ data: singleTypeProjects.data })) || [];
    const getQuery = (query) => {
      filter.value = query;
      filterProjects(query);
    };
    function filterProjects(query) {
      filteredProjects.data = singleTypeProjects.data.filter((project) =>
        project.title.toLowerCase().includes(query.toLowerCase())
      );
    }

    watch(showModal, () => {
      emit("setOverlay", showModal.value);
    });
    const view = useRoute().fullPath;
    const three = getSection(process.env.VUE_APP_PREFIX, view);

    const cabecera = Cabecera();

    const datosTmp = Datos();
    let datos = ref([]);

    const passPaginado = (paginado) => {
      datos.value = paginado;
    };
    const copiar = (dato) => {
      //let value = dato
      var tempInput = document.createElement("input");
      tempInput.style = "position: absolute; left: -1000px; top: -1000px";
      tempInput.value = dato;
      document.body.appendChild(tempInput);
      tempInput.select();
      document.execCommand("copy");
      document.body.removeChild(tempInput);
    };

    return {
      title,
      filteredProjects,
      showModal,
      showProject,
      openModal,
      close,
      projectTypes,
      type,
      getQuery,
      singleTypeProjects,
      three,
      datos,
      cabecera,
      datosTmp,
      passPaginado,
      copiar,
    };
  },
};
</script>
