<script>
import { h } from "vue";
export default {
  name: "IconPNG",
  props: {
    png: {
      type: String,
      default: "default"
    }
  },
  setup(props) {
    return () =>
      h("img", {
        class: [props.class],
        src: props.png
      });
  }
};
</script>
