import { createRouter, createWebHistory } from "vue-router";
import Home from "@/views/Home.vue";
//import About from "@/views/About.vue";
import Subprogram from "@/views/Subprogram.vue";
//import Projects from "@/views/Projects.vue";
import ViewWithTable from "@/views/ViewWithTable.vue";
import WebService from "@/views/WebService.vue";
import Aplicaciones from "@/views/Aplicaciones.vue";
//import Laboratory from "@/views/Laboratory.vue";
import NotFound from "@/views/NotFound.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home
  },

  {
    path: `/acerca-de/:subprogram`,
    component: Subprogram,
    name: "¿Qué es la IDE-IGP?"
  },
  {
    path: `/acerca-de/normatividad-IDE-IGP`,
    component: ViewWithTable,
    name: "Normatividad IDE-IGP"
  },
  {
    path: "/componentes/webservice",
    name: "Webservice",
    component: WebService
  },
  {
    path: "/componentes/:subprogram",
    name: "Subprogram",
    component: Subprogram
  },

  {
    path: `/aplicaciones`,
    component: Aplicaciones,
    name: "Aplicaciones"
  },

  {
    path: "/:catchAll(.*)",
    name: "404",
    component: NotFound
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to,from,savedPosition){
    if (savedPosition) {
      return savedPosition
    } else {
      return { top: 0 }
    }
  }
});

export default router;
