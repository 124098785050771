const Datos = () => {
  return [
    {
        tema: "Sismología",
        titulo: "Catálogo Sísmico histórico de 1471 a 1959",
        descripcion: "Catálogo Sísmico histórico de 1471 a 1959",
        fecha: "01/01/2019",
        wms:
            "https://ide.igp.gob.pe/geoserver/CTS_sismohistorico/wms?",
        wfs:
            "https://ide.igp.gob.pe/geoserver/CTS_sismohistorico/wfs?"
      },
      {
          tema: "Sismología",
          titulo: "Catálogo Sísmico desde 1960",
          descripcion: "Catálogo Sísmico desde 1960",
          fecha: "01/01/2019",
          wms:
              "https://ide.igp.gob.pe/geoserver/CTS_sismoinstrumental/wms",
          wfs:
              "https://ide.igp.gob.pe/geoserver/CTS_sismoinstrumental/wfs"
      },
    //   {
    //       tema: "Sismología",
    //       titulo: "Sismos reportados 2005",
    //       descripcion: "Eventos sísmicos reportados en el 2005",
    //       fecha: "01/01/2019",
    //       wms:
    //           "https://ide.igp.gob.pe/geoserver/CTS_sismo2005/wms?",
    //       wfs:
    //           "https://ide.igp.gob.pe/geoserver/CTS_sismo2005/wfs?"
    //   },
    //   {
    //       tema: "Sismología",
    //       titulo: "Sismos reportados 2006",
    //       descripcion: "Eventos sísmicos reportados en el 2006",
    //       fecha: "01/01/2019",
    //       wms:
    //           "https://ide.igp.gob.pe/geoserver/CTS_sismo2006/wms?",
    //       wfs:
    //           "https://ide.igp.gob.pe/geoserver/CTS_sismo2006/wfs?"
    //   },
    //   {
    //       tema: "Sismología",
    //       titulo: "Sismos reportados 2007",
    //       descripcion: "Eventos sísmicos reportados en el 2007",
    //       fecha: "01/01/2019",
    //       wms:
    //           "https://ide.igp.gob.pe/geoserver/CTS_sismo2007/wms?",
    //       wfs:
    //           "https://ide.igp.gob.pe/geoserver/CTS_sismo2007/wfs?"
    //   },
    //   {
    //       tema: "Sismología",
    //       titulo: "Sismos reportados 2008",
    //       descripcion: "Eventos sísmicos reportados en el 2008",
    //       fecha: "01/01/2019",
    //       wms:
    //           "https://ide.igp.gob.pe/geoserver/CTS_sismo2008/wms?",
    //       wfs:
    //           "https://ide.igp.gob.pe/geoserver/CTS_sismo2008/wfs?"
    //   },
    //   {
    //       tema: "Sismología",
    //       titulo: "Sismos reportados 2009",
    //       descripcion: "Eventos sísmicos reportados en el 2009",
    //       fecha: "01/01/2019",
    //       wms:
    //           "https://ide.igp.gob.pe/geoserver/CTS_sismo2009/wms?",
    //       wfs:
    //           "https://ide.igp.gob.pe/geoserver/CTS_sismo2009/wfs?"
    //   },
    //   {
    //       tema: "Sismología",
    //       titulo: "Sismos reportados 2010",
    //       descripcion: "Eventos sísmicos reportados en el 2010",
    //       fecha: "01/01/2019",
    //       wms:
    //           "https://ide.igp.gob.pe/geoserver/CTS_sismo2010/wms?",
    //       wfs:
    //           "https://ide.igp.gob.pe/geoserver/CTS_sismo2010/wfs?"
    //   },
    //   {
    //       tema: "Sismología",
    //       titulo: "Sismos reportados 2011",
    //       descripcion: "Eventos sísmicos reportados en el 2011",
    //       fecha: "01/01/2019",
    //       wms:
    //           "https://ide.igp.gob.pe/geoserver/CTS_sismo2011/wms?",
    //       wfs:
    //           "https://ide.igp.gob.pe/geoserver/CTS_sismo2011/wfs?"
    //   },
    //   {
    //       tema: "Sismología",
    //       titulo: "Sismos reportados 2012",
    //       descripcion: "Eventos sísmicos reportados en el 2012",
    //       fecha: "01/01/2019",
    //       wms:
    //           "https://ide.igp.gob.pe/geoserver/CTS_sismo2012/wms?",
    //       wfs:
    //           "https://ide.igp.gob.pe/geoserver/CTS_sismo2012/wfs?"
    //   },
    //   {
    //       tema: "Sismología",
    //       titulo: "Sismos reportados 2013",
    //       descripcion: "Eventos sísmicos reportados en el 2013",
    //       fecha: "01/01/2019",
    //       wms:
    //           "https://ide.igp.gob.pe/geoserver/CTS_sismo2013/wms?",
    //       wfs:
    //           "https://ide.igp.gob.pe/geoserver/CTS_sismo2013/wfs?"
    //   },
    //   {
    //       tema: "Sismología",
    //       titulo: "Sismos reportados 2014",
    //       descripcion: "Eventos sísmicos reportados en el 2014",
    //       fecha: "01/01/2019",
    //       wms:
    //           "https://ide.igp.gob.pe/geoserver/CTS_sismo2014/wms?",
    //       wfs:
    //           "https://ide.igp.gob.pe/geoserver/CTS_sismo2014/wfs?"
    //   },
    //   {
    //       tema: "Sismología",
    //       titulo: "Sismos reportados 2015",
    //       descripcion: "Eventos sísmicos reportados en el 2015",
    //       fecha: "01/01/2019",
    //       wms:
    //           "https://ide.igp.gob.pe/geoserver/CTS_sismo2015/wms?",
    //       wfs:
    //           "https://ide.igp.gob.pe/geoserver/CTS_sismo2015/wfs?"
    //   },
    //   {
    //       tema: "Sismología",
    //       titulo: "Sismos reportados 2016",
    //       descripcion: "Eventos sísmicos reportados en el 2016",
    //       fecha: "01/01/2019",
    //       wms:
    //           "https://ide.igp.gob.pe/geoserver/CTS_sismo2016/wms?",
    //       wfs:
    //           "https://ide.igp.gob.pe/geoserver/CTS_sismo2016/wfs?"
    //   },
    //   {
    //       tema: "Sismología",
    //       titulo: "Sismos reportados 2017",
    //       descripcion: "Eventos sísmicos reportados en el 2017",
    //       fecha: "01/01/2019",
    //       wms:
    //           "https://ide.igp.gob.pe/geoserver/CTS_sismo2017/wms?",
    //       wfs:
    //           "https://ide.igp.gob.pe/geoserver/CTS_sismo2017/wfs?"
    //   },
      {
          tema: "Sismología",
          titulo: "Último Sismo",
          descripcion: "Último sismo reportado, actualizado en tiempo real",
          fecha: "01/01/2019",
          wms:
              "https://ide.igp.gob.pe/geoserver/CTS_ultimosismo/wms",
          wfs:
              "https://ide.igp.gob.pe/geoserver/CTS_ultimosismo/wfs"
      },
      {
          tema: "Vulcanología",
          titulo: "Volcanes Activos",
          descripcion: "Mapa de Volcanes según el nivel de alerta en el que se encuentra actualmente de acuerdo al monitoreo del IGP",
          fecha: "01/01/2019",
          wms:
              "https://ide.igp.gob.pe/geoserver/CTS_alertavolcan/wms",
          wfs:
              "https://ide.igp.gob.pe/geoserver/CTS_alertavolcan/wfs"
      },
      {
        tema: "Estudios de zonificación sísmica-geotécnica ",
        titulo: "Mapa de Capacidad Portante",
        descripcion: "",
        fecha: "2/2/2024",
        wms: "https://ide.igp.gob.pe/geoserver/CapacidadPortante/wms?",
        wfs: "https://ide.igp.gob.pe/geoserver/CapacidadPortante/wfs?",
      },
      {
        tema: "Estudios de zonificación sísmica-geotécnica ",
        titulo: "Mapa de Geomorfología",
        descripcion: "",
        fecha: "2/2/2024",
        wms: "https://ide.igp.gob.pe/geoserver/Geomorfologia/wms?",
        wfs: "https://ide.igp.gob.pe/geoserver/Geomorfologia/wfs?",
      },
      {
        tema: "Estudios de zonificación sísmica-geotécnica ",
        titulo: "Mapa de Geología",
        descripcion: "",
        fecha: "2/2/2024",
        wms: "https://ide.igp.gob.pe/geoserver/Geologia/wms?",
        wfs: "https://ide.igp.gob.pe/geoserver/Geologia/wfs?",
      },
      {
        tema: "Estudios de zonificación sísmica-geotécnica ",
        titulo: "Mapa de Geodinámica",
        descripcion: "",
        fecha: "2/2/2024",
        wms: "https://ide.igp.gob.pe/geoserver/Geodinamica/wms?",
        wfs: "https://ide.igp.gob.pe/geoserver/Geodinamica/wfs?",
      },
      {
        tema: "Estudios de zonificación sísmica-geotécnica ",
        titulo: "Mapa de Suelos",
        descripcion: "",
        fecha: "2/2/2024",
        wms: "https://ide.igp.gob.pe/geoserver/Suelos/wms?",
        wfs: "https://ide.igp.gob.pe/geoserver/Suelos/wfs?",
      },
      {
        tema: "Estudios de zonificación sísmica-geotécnica ",
        titulo: "Mapa de Zonificación Sísmica",
        descripcion: "",
        fecha: "2/2/2024",
        wms: "https://ide.igp.gob.pe/geoserver/ZonificacionSismica/wms?",
        wfs: "https://ide.igp.gob.pe/geoserver/ZonificacionSismica/wfs?",
      }
  ];
};

const Cabecera = () => {
  return [
    { label: "Tema", width: 105 },
    { label: "Nombre", width: 220 },
    { label: "Fecha de registro", width: 100 },
    { label: "WFS", width: 100 },
    { label: "WMS", width: 120 }
  ];
};
export { Datos, Cabecera };
/*  export {cabecera} */
