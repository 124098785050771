<template>
  <section class="projects" id="section_featured">
    <Container>
      <Title tag="h2" class="is-centered">DESTACADO</Title>
      <agile
        :dots="false"
        :nav-buttons="false"
        :autoplaySpeed="5000"
        :speed="2000"
        autoplay
      >
        <div class="slide">
          <Row>
            <ColumnUi lg="8" md="6" class="project">
              <div class="project__info">
                <h3 class="project__title">
                  Mapa de Zonificación Sísmica – Geotécnica de Suelos: Piura
                </h3>
                <p class="project__content">
                  Este mapa permite conocer el Comportamiento Dinámico del Suelo
                  ante la ocurrencia de sismos y es elaborado usando información
                  obtenida con la aplicación métodos sísmicos (H/V, MASW, MAN),
                  eléctricos, gravimétricos, geológicos, geomorfológicos,
                  geodinámicos y geotécnicos.
                </p>
                <span
                  ><Button
                    href="https://ide.igp.gob.pe/geonetwork/srv/spa/catalog.search#/metadata/ba31b480-d3b2-4f6a-a4a0-c4b6a9192a30"
                    theme="primary"
                    target="_blank"
                    >Acceder</Button
                  ></span
                >
              </div>
            </ColumnUi>
            <ColumnUi lg="4" md="6" class="project">
              <IconPNG
                png="images/featured/ide_featured__2.webp"
                class="icon-wrapper"
              />
            </ColumnUi>
          </Row>
        </div>
        <div class="slide">
          <Row>
            <ColumnUi lg="8" md="6" class="project">
              <div class="project__info">
                <h3 class="project__title">
                  Mapa de Zonificación Sísmica – Geotécnica de Suelos: Tacna
                </h3>
                <p class="project__content">
                  Este mapa permite conocer el Comportamiento Dinámico del Suelo
                  ante la ocurrencia de sismos y es elaborado usando información
                  obtenida con la aplicación métodos sísmicos (H/V, MASW, MAN),
                  eléctricos, gravimétricos, geológicos, geomorfológicos,
                  geodinámicos y geotécnicos.
                </p>
                <span
                  ><Button
                    href="https://ide.igp.gob.pe/geonetwork/srv/spa/catalog.search#/metadata/ff97c412-6ba4-4b66-b95b-b77823452731"
                    theme="primary"
                    target="_blank"
                    >Acceder</Button
                  ></span
                >
              </div>
            </ColumnUi>
            <ColumnUi lg="4" md="6" class="project">
              <IconPNG
                png="images/featured/ide_featured.webp"
                class="icon-wrapper"
              />
            </ColumnUi>
          </Row>
        </div>

        <div class="slide">
          <Row>
            <ColumnUi lg="8" md="6" class="project">
              <div class="project__info">
                <h3 class="project__title">
                  Mapa de Zonificación Sísmica – Geotécnica de Suelos: Arequipa
                </h3>
                <p class="project__content">
                  Este mapa permite conocer el Comportamiento Dinámico del Suelo
                  ante la ocurrencia de sismos y es elaborado usando información
                  obtenida con la aplicación métodos sísmicos (H/V, MASW, MAN),
                  eléctricos, gravimétricos, geológicos, geomorfológicos,
                  geodinámicos y geotécnicos.
                </p>
                <span
                  ><Button
                    href="https://ide.igp.gob.pe/geonetwork/srv/spa/catalog.search#/metadata/26f6f0eb-8bc4-4427-b2a0-f5c33b479752"
                    theme="primary"
                    target="_blank"
                    >Acceder</Button
                  ></span
                >
              </div>
            </ColumnUi>
            <ColumnUi lg="4" md="6" class="project">
              <IconPNG
                png="images/featured/ide_featured_arequipa.webp"
                class="icon-wrapper"
              />
            </ColumnUi>
          </Row>
        </div>

        <div class="slide">
          <Row>
            <ColumnUi lg="8" md="6" class="project">
              <div class="project__info">
                <h3 class="project__title">
                  Mapa de Zonificación Sísmica – Geotécnica de Suelos: Moquegua
                </h3>
                <p class="project__content">
                  Este mapa permite conocer el Comportamiento Dinámico del Suelo
                  ante la ocurrencia de sismos y es elaborado usando información
                  obtenida con la aplicación métodos sísmicos (H/V, MASW, MAN),
                  eléctricos, gravimétricos, geológicos, geomorfológicos,
                  geodinámicos y geotécnicos.
                </p>
                <span
                  ><Button
                    href="https://ide.igp.gob.pe/geonetwork/srv/spa/catalog.search#/metadata/5dccb458-516e-47a3-b6c9-9fe7519d3228"
                    theme="primary"
                    target="_blank"
                    >Acceder</Button
                  ></span
                >
              </div>
            </ColumnUi>
            <ColumnUi lg="4" md="6" class="project">
              <IconPNG
                png="images/featured/ide_featured_moquegua.webp"
                class="icon-wrapper"
              />
            </ColumnUi>
          </Row>
        </div>
      </agile>
    </Container>
  </section>
</template>

<script>
import { VueAgile } from "vue-agile";
import Title from "../ui/atoms/Title.vue";
import Row from "../ui/atoms/Row.vue";
import ColumnUi from "../ui/atoms/ColumnUi.vue";
import Container from "@/components/ui/objects/Container";
import IconPNG from "@/components/ui/atoms/IconPNG";
import Button from "@/components/ui/atoms/Button";
export default {
  components: {
    agile: VueAgile,
    Title,
    Row,
    ColumnUi,
    Container,
    IconPNG,
    Button,
  },
};
</script>

<style lang="scss" scoped>
@use "@/assets/styles/settings/_variables.scss";
@use "@/assets/styles/tools/_mixins.scss";
.projects {
  padding: variables.$spacing-l 0;
}
.project {
  display: flex;
  align-items: center;
  grid-gap: 0 1.5em;
  margin-bottom: 2em;
  scroll-behavior: smooth;

  &__info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0px 1rem 0px 1rem;
  }
  &__title {
    font-weight: 700;
  }
}

.project .icon-wrapper {
  min-width: 90%;
  height: 90%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  filter: grayscale(1);
  transition-property: filter;
  transition-duration: 1s;
}

.project .icon-wrapper:hover {
  filter: grayscale(0);
}
</style>
