<template>
  <main>
    <Trail />
    <Hero :program="program">
      <template v-slot:customButtons>
      </template>
    </Hero>
    <About :program="program">
      
    </About>
    <SubprogramList
      :subprogramList="program.subprogramList"
      :subprograms="program.subprograms"
    />
    <ProjectList title="Componentes" />
    <Featured></Featured>
    <!--  <Publications :program="program" /> -->
  </main>
</template>

<script>
import programs from "@/data.js";
import Featured from "@/components/home/Featured";
import Hero from "@/components/home/Hero";
import Trail from "@/components/common/Trail";
import SubprogramList from "@/components/home/SubprogramList";
import ProjectList from "@/components/home/ProjectList";
import About from "@/components/home/About";
/* import Publications from "@/components/home/Publications"; */

export default {
  components: {
    Hero,
    Trail,
    SubprogramList,
    ProjectList,
    About,
    Featured,
    /*     Publications, */
  },
  setup() {
    const program = programs[process.env.VUE_APP_PREFIX];
    return {
      program
    };
  }
};
</script>
<style lang="scss" scoped>
@use "@/assets/styles/settings/_variables.scss";
.trail {
  margin-bottom: 0;
}
</style>
