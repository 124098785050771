<template>
  <component :is="tag" :class="`title${(underlined && ' underlined') || ''}`">
    <span v-if="underlined">
      <slot />
    </span>
    <slot v-else />
  </component>
</template>

<script>
export default {
  name: "Title",
  props: {
    tag: {
      type: String,
      default: "h",
      validator(value) {
        return ["h1", "h2", "h3", "h4", "h5", "h6"].includes(value);
      }
    },
    underlined: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style lang="scss" scoped>
@use "@/assets/styles/settings/_variables.scss";
.title {
  color: variables.$primary-color;
  &.underlined span {
    position: relative;
    &::after {
      position: absolute;
      display: block;
      content: "";
      width: 100%;
      border-bottom: 3px solid currentColor;
      padding-top: 0.2em;
    }
  }
}
h1.title {
  font-size: variables.$fs-title-l;
}
</style>
