<template>
  <component :is="tag" class="row">
    <slot />
  </component>
</template>

<script>
export default {
  name: "Row",
  props: {
    tag: {
      type: String,
      default: "div"
    }
  }
};
</script>

<style lang="scss" scoped>
@use "@/assets/styles/settings/_variables.scss";
@use "@/assets/styles/tools/_mixins.scss";
.row {
  margin-right: -(variables.$padding-container);
  margin-left: -(variables.$padding-container);
  display: flex;
  flex-wrap: wrap;
}
</style>
