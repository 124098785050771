<template>
  <component :is="tag" class="container">
    <slot />
  </component>
</template>

<script>
export default {
  props: {
    tag: {
      type: String,
      default: "div"
    }
  }
};
</script>

<style lang="scss" scoped>
@use "@/assets/styles/settings/_variables.scss";
.container {
  width: 100%;
  padding-right: variables.$padding-container;
  padding-left: variables.$padding-container;
  margin-right: auto;
  margin-left: auto;
  &--main {
    padding-top: variables.$spacing-l;
    padding-bottom: variables.$spacing-l;
  }
}
@media (min-width: variables.$sm) {
  .container {
    max-width: variables.$sm-width;
  }
}
@media (min-width: variables.$md) {
  .container {
    max-width: variables.$md-width;
  }
}
@media (min-width: variables.$lg) {
  .container {
    max-width: variables.$lg-width;
  }
}
@media (min-width: variables.$xl) {
  .container {
    max-width: variables.$xl-width;
  }
}
</style>
