<template>
  <HeroBackground :slides="program.slides">
    <Container tag="section" class="hero">
      <!-- <div class="hero__info">
        <Title tag="h1">{{ program.title }}</Title>
        <p>
          {{ program.description }}
        </p>
        <div class="hero__buttons">
          <slot name="customButtons"></slot>
        </div>
      </div>
      <div class="hero__media"></div> -->
    </Container>
  </HeroBackground>
</template>
<script>
import HeroBackground from "@/components/home/HeroBackground";

import Container from "@/components/ui/objects/Container";
/* import Title from "@/components/ui/atoms/Title"; */

export default {
  name: "Hero",
  components: {
    HeroBackground,
    Container,
    /* Title */
  },
  props: {
    program: {
      type: Object,
      default: () => {}
    }
  }
};
</script>
<style lang="scss" scoped>
@use "@/assets/styles/settings/_variables.scss";
.swiper-container {
  background-color: yellow;
  height: 100%;
  .swiper-slide {
    background-color: tomato;
  }
}
.hero {
  &__info {
    padding: variables.$spacing-l 0;
  }
}
@media (min-width: 768px) {
  .hero {
    display: grid;
    grid-gap: variables.$spacing-xl;
    grid-template-columns: repeat(2, 1fr);
  }
}
</style>
