<template>
  <footer class="footer">
    <Container>
      <Row>

        <ColumnUi class="footer__contact" lg="8" md="8">
          <h4>CONTÁCTENOS</h4>
          <List>
            <li><p>Lunes a Viernes de 08:30 a 16:30</p></li>
            <li><p>Calle Badajoz Nº 169 Urb. Mayorazgo IV Etapa Ate, Lima 15012</p></li>
            <li><p><a class="footer__link" href="mailto:comunicaciones@igp.gob.pe">comunicaciones@igp.gob.pe</a></p></li>
            <li><p>+51 13172300</p></li>
          </List>
        </ColumnUi>
        <ColumnUi class="footer__social" lg="4" md="6">
          <h4>SOCIAL</h4>
          <List class="list--grid">
            <li>
              <p><a class="footer__link icon-link" target="_blank" href="https://www.facebook.com/igp.peru"><Icon svg="facebook" class="footer__icon" />Facebook</a></p>
            </li>
            <li>
              <p><a class="footer__link icon-link" target="_blank" href="https://twitter.com/igp_peru"><Icon svg="twitter" class="footer__icon" />Twitter</a></p>
            </li>
            <li>
              <p><a class="footer__link icon-link" target="_blank" href="https://www.instagram.com/igp.peru"><Icon svg="instagram" class="footer__icon" />Instagram</a></p>
            </li>
            <li>
              <p><a class="footer__link icon-link" target="_blank" href="https://www.youtube.com/igp_videos"><Icon svg="youtube" class="footer__icon" />Youtube</a></p>
            </li>
            <li>
              <p><a class="footer__link icon-link" target="_blank" href="https://www.linkedin.com/company/igpperu/"><Icon svg="linkedin" class="footer__icon" />Linkedin</a></p>
            </li>
            <li>
              <p><a class="footer__link icon-link" target="_blank" href="https://www.flickr.com/people/156092703@N08/"><Icon svg="flickr" class="footer__icon" />Flickr</a></p>
            </li>
          </List>
        </ColumnUi>
      </Row>
    </Container>
  </footer>
  <Destacados></Destacados>
</template>
<script>
import List from "@/components/ui/objects/List";
import Container from "@/components/ui/objects/Container";
import Row from "@/components/ui/atoms/Row";
import ColumnUi from "@/components/ui/atoms/ColumnUi";
import Icon from "@/components/ui/atoms/Icon";
import Destacados from "@/components/ui/atoms/Destacado";
export default {
  components: {
    Container,
    List,
    Row,
    ColumnUi,
    Icon,
    Destacados
  }
};
</script>
<style lang="scss" scoped>
@use "@/assets/styles/settings/_variables.scss";
.footer {
  background-color: variables.$background-footer-color;
  padding-top: 4em;
  padding-bottom: 4em;
  font-size: variables.$fs-footer;
  color: variables.$text-inverted-color;
  &__link {
    color: variables.$text-inverted-color;
    text-decoration: none;
    &:hover {
      color: variables.$secondary-color;
      .footer__icon {
        fill: variables.$secondary-color;
      }
    }
  }
  &__icon {
    height: variables.$fs-footer;
  }
  &__logo{
    width: 180px;
    height: 200px;
    color: white;
    margin-top:-60px;
  }
}
</style>
