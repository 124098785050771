const Datos = () => {
  return [
    {
      titulo: "Monitoreo Volcánico",
      tipo: "Tablero de control",
      link:
        "http://ide.igp.gob.pe/portal/apps/opsdashboard/index.html#/d75702e19a114c39a806bcc873f2ac59"
    },
    {
      titulo: "Monitoreo Sísmico",
      tipo: "Tablero de control",
      link:
        "https://ide-igp.maps.arcgis.com/apps/dashboards/1ee1b5f32a424426aca0b1b81660e34c"
    },
    {
      titulo: "Monitoreo de Índice Global de Humedad en la vegetación (GVMI)",
      tipo: "Visor geográfico",
      link:
        "https://ide.igp.gob.pe/portal/apps/webappviewer/index.html?id=2b63791ff92246b18e8fd0a056e897a6"
    }
  ];
};

export { Datos };

const Cabecera = () => {
  return [
    { label: "Titulo", width: 320 },
    { label: "Tipo", width: 320 },
    { label: "Enlace", width: 320 }
  ];
};

export { Cabecera };

