<template>
  <Header v-on:setOverlay="setOverlay" />
  <router-view :key="$route.fullPath" v-on:setOverlay="setOverlay" />
  <Footer />
</template>
<script>
import "@/assets/styles/index.scss";
// import { useRoute } from "vue-router";
import Header from "@/components/common/Header";
import Footer from "@/components/common/Footer";
// import { onMounted } from 'vue';

export default {
  components: {
    Header,
    Footer
  },
  setup() {
    const setOverlay = isOverlay => {
      const scrollWidth = window.innerWidth - document.body.clientWidth;

      document.body.scrollHeight - window.innerHeight > 0 && isOverlay
        ? document.body.setAttribute(
            "style",
            `${document.body.getAttribute("style") ||
              ""} margin-right:${scrollWidth}px;`
          )
        : document.body.setAttribute(
            "style",
            `${document.body.getAttribute("style") &&
              document.body
                .getAttribute("style")
                .replace(` margin-right:${scrollWidth}px;`, "")}`
          );

      isOverlay &&
        document.body.setAttribute(
          "class",
          `${document.body.getAttribute("class") || ""} overlay`
        );
      !isOverlay &&
        document.body.setAttribute(
          "class",
          `${document.body.getAttribute("class") &&
            document.body.getAttribute("class").replace(" overlay", "")}`
        );
    };
    return {
      setOverlay
    };
  }
};
</script>
<style lang="scss" scope>
@use "@/assets/styles/settings/_variables.scss";

main {
  min-height: calc(100vh - (417px + 67px));
}
/*
@media (min-width: 992px) {
  main {
    min-height: calc(100vh - (302px + 67px));
  }
}*/
@media (min-width: 1200px) {
  main {
    min-height: calc(100vh - (388px + 67px));
  }
}
</style>
