<template>
  <main>
    <Trail />
    <Container class="container--main">
      <Row>
        <ColumnUi :md="three ? 9 : 12">
          <Title tag="h1" v-if="subprogram">{{ subprogram.title }}</Title>
          <Title tag="h1" v-else>CONTENIDO NO ENCONTRADO</Title>
          <Content v-if="content" :content="content" />
          <p v-else>No hay contenido disponible...</p>
          <br />
        </ColumnUi>
        <ColumnUi md="3" v-if="three">
          <EnEstaSeccion :three="three"></EnEstaSeccion>
        </ColumnUi>
      </Row>
    </Container>
  </main>
</template>
<script>
import programs from "@/data.js";
import { getSection } from "@/interface-data.js";
import EnEstaSeccion from "@/components/ui/objects/EnEstaSeccion";
import Container from "@/components/ui/objects/Container";
import Row from "@/components/ui/atoms/Row.vue";
import ColumnUi from "@/components/ui/atoms/ColumnUi.vue";
import Trail from "@/components/common/Trail.vue";
import Title from "@/components/ui/atoms/Title.vue";
import { useRoute } from "vue-router";
import Content from "@/components/common/Content.vue";

export default {
  components: {
    Trail,
    Container,
    Row,
    ColumnUi,
    Title,
    Content,
    EnEstaSeccion
  },
  setup() {
    const view = useRoute().fullPath;
    const three = getSection(process.env.VUE_APP_PREFIX, view);
    const subprograms = programs[process.env.VUE_APP_PREFIX].subprograms;
    const route = useRoute().params.subprogram;
    const subprogram = subprograms.find(subprogram => subprogram.slug == route);
    const content = subprogram && subprogram.content;
    return {
      subprogram,
      content,
      subprograms,
      three,
      view
    };
  }
};
</script>
