import Stair from "@/components/common/TimeLine/Stair";
import List from "@/components/ui/objects/List";
import ColumnUi from "@/components/ui/atoms/ColumnUi";
import Row from "@/components/ui/atoms/Row";
import PublicationList from "@/components/common/Publication/PublicationsList";
import TableUi from "@/components/ui/objects/TableUi";
import { h } from "vue";
const laboratories = {
  cah: {
    dinamica: [
      {
        title: "Laboratorio de Dinámica de Fluídos Geofísicos Computacional",
        slug: "acerca-de",
        content: () => [
          <p>
            El Laboratorio de Dinámica de Fluidos Geofísicos Computacional tiene
            como objetivo fundamental estudiar el comportamiento complejo de los
            fluídos (e.g., el océano, la atmósfera) desarrollados a diferentes
            escalas de movimiento, en diferentes sistemas y bajo diferentes
            formas, los cuales se rigen por una dinámica similar (i.e. los
            principios fundamentales de la dinámica de fluidos y la
            termodinámica). El enfoque metodológico que se emplea se basa en la
            modelación numérica que incluye el uso, la implementación, la
            calibración, la optimización y el desarrollo de modelos numérico
            para resolver problemas que involucren la dinámica de la Tierra, su
            interacción con los seres vivos, los procesos naturales que pueden
            afectar al ser humano y el efecto de éste sobre los procesos físicos
            naturales en diferentes escalas de espacio y tiempo (e.g., sobre
            todo el planeta o una región en particular y además, en el pasado,
            presente o futuro).
          </p>,
          <p>
            El laboratorio cuenta con el Sistema Computacional de Alto
            Rendimiento de última generación (HPC-Linux-Clúster) dedicado a
            suplir la “necesidad computacional” (capacidad de cálculo o
            procesamiento) de las actuales investigaciones científicas de alto
            nivel que se desarrollan en el Instituto Geofísico del Perú, las
            cuales están dedicadas a resolver y analizar los fenómenos
            geofísicos (naturales y/o de origen antropogénico) que afectan a la
            población peruana y sus actividades. Asimismo, provee a las
            universidades, instituciones y centros de investigación que lo
            requieran (siguiendo el protocolo establecido) la capacidad de
            cómputo necesaria a fin de fortalecer las investigaciones
            científicas y el desarrollo tecnológico en beneficio del Perú.
          </p>,
          <hr />,
          <b>Contacto:</b>,
          <p>
            Dra. Ivonne Montes <br />
            Instituto Geofísico del Perú <br />
            Calle Badajoz #169 Mayorazgo IV Etapa Ate Vitarte - Perú <br />
            <a href="mailto:dfgc@igp.gob.pe">dfgc@igp.gob.pe</a>,{" "}
            <a href="mailto:">imontes@igp.gob.pe</a>
            <br />
            (511) 317-2300 Anexo 135 <br />
          </p>
        ]
      },
      {
        title: "Inicio del Modelado Numérico en el IGP",
        slug: "modelado-numerico",
        content: () => [
          h(
            Stair,
            {
              data: [
                {
                  point: 1998,
                  subtitle: "4 GFLOPS",
                  tag:
                    "Modelo Atmosférico de Predicción del Tiempo (PSU/NCAR mesoscale model - MMS)"
                },
                {
                  point: 2002,
                  subtitle: "16 GFLOPS",
                  tag:
                    "Modelo oceánico lineal de dos dimensiones de la onda Kelvin ecuatorial (Mosquera, 2009; Mosquera et al., 2011; Mosquera 2014)"
                },
                {
                  point: 2009,
                  subtitle: "101.7 GFLOPS",
                  tag:
                    "Experimentos numéricos relacionados con el clima y la dinámica del viento Paracas (Quijano, 2013)"
                },
                {
                  point: 2012,
                  subtitle: "912 GFLOPS",
                  tag:
                    "Proyecto SPIRALES2012 IGP-IRD: Serie de experimentos numéricos del océano y atmósfera para estudios del clima en el Perú"
                },
                {
                  point: 2014,
                  subtitle: "1 TFLOPS",
                  tag:
                    "Modelo climático de alta resolución (3km) para estudiar transporte de la humedad de la Amazonía a los Andes y su impacto en la distribución de las precipitaciones (Junquas, 2014). Simulaciones de alta resolución (7km) para estudiar los efectos de la variabilidad remota sobre el afloramiento costero (Montes et al., 2014)"
                },
                {
                  point: 2016,
                  subtitle: "19 TFLOPS",
                  tag:
                    "'Sistema Computacional de Alto Rendimiento para la simulación de Fluídos Geofísicos' pbtenido vía Convenio de Subvención Nº 101-2014-FONDECYT dedicado a suplir la necesidad computacional de las actuales investigaciones científicas de alto nivel que se desarrollan en el IGP y, por la comunidad científica peruana con fines de investifación."
                }
              ]
            },
            () => {}
          ),
          <p>
            A fines del año 1998, el IGP logra revolucionar la meteorología en
            el Perú al implementar un modelo numérico de la atmosféra (PSU/NCAR
            mesoscale model-MM5 instalado en una ‘Estación de Trabajo’ (también
            conocido como Workstation: Compaq Server DS20 que alcanzaba los 4
            GFLOPS) para la predicción en tiempo real de las condiciones
            atmosféricas en la región de sudamérica; lo cual permitió brindar al
            público especializado (nacional e internacional) un nuevo insumo
            para el pronóstico de hasta tres días de las condiciones
            atmosféricas (ver Figueroa et al., 1998 para mayor detalles).
            Asimismo, en el aspecto de la investigación, este nueva herramienta
            numérica fue usada para responder algunas preguntas científicas (ver
            Silva y Takahashi (2000)).
          </p>,
          <p>
            A inicios del siglo XXI, gracias a un préstamo del Banco Mundial
            para un proyecto relacionado con el Estudio del Fenómeno El Niño, el
            IGP (junto con otras instituciones) logró adquirir estaciones de
            trabajo de dos tipos con una capacidad de cálculo de 4 y 16 GFLOPS
            (Compaq Server DS20 y ES40). Estos equipos fueron de utilidad para
            continuar con la investigación del clima de la atmósfera y océano
            usando modelos numéricos, así como para mantener una base de datos
            en tiempo real de información oceánica y atmosférica de estaciones
            automáticas. Fueron en estas estaciones de trabajo donde se inició
            el desarrollo de un modelo oceánico lineal de dos dimensiones de la
            onda Kelvin ecuatorial (Mosquera, 2009; Mosquera et al., 2011;
            Mosquera 2014), cuyos resultados se usan, hasta la actualidad, como
            uno de los insumos para el diagnóstico y predicción de la dinámica
            oceánica relacionada al fenómeno El Niño.
          </p>,
          <p>
            En el 2012, gracias al proyecto de colaboración SPIRALES2012 IRD-IGP
            y recursos propios, el IGP adquiere una arquitectura computacional
            tipo cluster para la implementado modelos numéricos paralelizados;
            es decir que hacen uso del lenguaje de programación en paralelo
            (interface de programación de aplicaciones – OpenMP - para la
            programación multiproceso de memoria compartida y la interfaz de
            paso de mensajes – MPI - para la ejecución de múltiples tareas
            creados por un único programa) para mejorar la eficiencia de cálculo
            y tener una mejor descripción de la evolución espacial y temporal
            del proceso geofísico.
          </p>,
          <p>
            Actualmente, gracias al Convenio de Subvención Nº 101-2014-FONDECYT,
            el IGP cuenta con el ‘Sistema computacional de Alto Rendimiento para
            la Simulación de Fluidos Geofísicos’ el cual está dedicado a suplir
            la necesidad computacional de las actuales investigaciones
            científicas de alto nivel que se desarrollan en el IGP y, por la
            comunidad científicas peruana con fines de investigación.
          </p>
        ]
      },
      {
        title: "HPC-LINUX-CLUSTER",
        slug: "hpc-linux-cluster",
        content: () => [
          <p>
            El HPC-Linux-Cluster está compuesto por <b>Cinnamon</b>:
          </p>,
          <List marked>
            <li>
              25 Nodos de Cómputo
              <List marked>
                <li>
                  20 de 2 Procesadores Xeon 2.5GHz (480 cores), memoria
                  principal total 2.56TB.
                </li>
                <li>
                  5 de 2 Procesadores Xeon 1.9GHz (60 cores), memoria principal
                  total 0.120TB.
                </li>
              </List>
            </li>
            <li>Almacenamiento 400 Terabytes.</li>
            <li>Conexión Infiniband QDR y FDR 56 Gbps.</li>
            <li>Capacidad teórica pico de procesamiento 20.112 TFlops​.</li>
          </List>,
          <p>
            <b>Sistema de Copias de Respaldo:</b>
          </p>,
          <List marked>
            <li>
              Librería de cintas de 2 drives LTO6 - 48 slots
              <List marked>
                <li>
                  Capacidad de almacenamiento total sin comprimir 120 Terabytes.
                </li>
              </List>
            </li>
          </List>,
          <p>
            <b>Software y Librerías</b> ‘Programas de desarrollo de
            computacióncientífica‘
          </p>,
          <List marked>
            <li>
              Compiladores para desarrollo de programas:
              <List marked>
                <li>Compiladores GNU: gfortran, gcc y g++.</li>
                <li>Compilador Intel: ifort, icc y icpc.</li>
              </List>
            </li>
            <li>
              Paralelización MPI de programas:
              <List marked>
                <li>Compiladores GNU: mpif90, mpifcc y mpicxx.</li>
                <li>Compilador Intel: mpiifort, mpiicc y mpiicpc.</li>
              </List>
            </li>
            <li>
              Libreria científicas y software de procesamiento:
              <List marked>
                <li>
                  NetCDF, HDF, NCL, Octave, GrADS, Ferret, Python, entre otros.
                </li>
              </List>
            </li>
          </List>,
          <h2>Equipo técnico</h2>,
          <Row>
            <ColumnUi md="6">
              <p>
                <b>Soporte Técnico</b>
              </p>
              <List marked>
                <li>Ing. Oscar Santillán</li>
                <li>Ing. C. Augusto Ingunza</li>
                <li>Msc. Huber Gilt</li>
                <li>
                  Dr. Didier Gazen (Colaborador externo:{" "}
                  <a href="http://www.cnrs.fr/">CNRS</a>-
                  <a href="http://www.aero.obs-mip.fr/">LA</a>, Francia)
                </li>
              </List>
            </ColumnUi>
            <ColumnUi md="6">
              <p>
                <b>Comité Científico</b>
              </p>
              <List marked>
                <li>Dra. Ivonne Montes</li>
                <li>Dr. Jhan Carlo Espinoza</li>
                <li>Dr. Kobi Mosquera</li>
                <li>
                  Dr. Boris Dewitte (Colaborador externo:{" "}
                  <a href="https://es.ird.fr/">IRD</a>-
                  <a href="http://www.legos.obs-mip.fr/">LEGOS</a>, Francia)
                </li>
              </List>
            </ColumnUi>
          </Row>,
          <img
            class="thumbnail"
            src="https://www.igp.gob.pe/programas-de-investigacion/ciencias-de-la-atmosfera-e-hidrosfera/img/cluster-team2_0"
          />
        ]
      },
      {
        title: "Acceso a Nuestros Recursos",
        slug: "recursos",
        content: () => [
          <p>
            Para acceder al Sistema Computacional de alto rendimiento para la
            simulación de fluidos geofísicos 'HPC-Linux-Cluster', disponible a
            través del Laboratorio de Dinámica de Fluidos Geofísicos
            Computacional del Instituto Geofísico del Perú para la comunidad
            científica nacional sin fines de lucro, usted deberá:
          </p>,
          <List marked>
            <li>
              Enviar el formulario 'proyecto', debidamente llenado al correo:{" "}
              <a href="mailto:dfgc@igp.gob.pe">dfgc@igp.gob.pe</a>
            </li>
          </List>,
          <p>
            Si usted ya dispone de las credenciales de acceso y desea
            extenderlas o renovarlas, deberá:
          </p>,
          <List marked>
            <li>
              Enviar el formulario 'renovación', debidamente llenado al correo:{" "}
              <a href="mailto:dfgc@igp.gob.pe">dfgc@igp.gob.pe</a>
            </li>
          </List>,
          <p>
            Las propuestas enviadas serán evaluadas por el comité científico;
            una vez aprobado por el comité, nuestro equipo técnico-científico se
            comunicará con usted.{" "}
          </p>,
          <br />,
          <h2>Políticas y Procedimientos</h2>,
          <Row>
            <ColumnUi md="6">
              <p>
                <b>Formulario proyecto</b>
              </p>
              <List marked>
                <li>
                  <a href="https://scah.igp.gob.pe/sites/scah.igp.gob.pe//files/public/formulario_proyecto.xlsx">
                    Formulario proyecto
                  </a>
                </li>
                <li>
                  <a href="https://scah.igp.gob.pe/sites/scah.igp.gob.pe//files/public/formulario_renovacion.xlsx">
                    Formulario de renovación
                  </a>
                </li>
                <li>
                  <a href="https://scah.igp.gob.pe/sites/scah.igp.gob.pe//files/public/formulario_visitas_HPC-Linux-Cluster.xlsx">
                    Formulario de visita
                  </a>
                </li>
              </List>
            </ColumnUi>
            <ColumnUi md="6">
              <p>
                <b>Seguridad de la Información:</b>
              </p>
              <List marked>
                <li>
                  <a href="https://scah.igp.gob.pe/sites/scah.igp.gob.pe//files/public/mapro_hpc-linux-cluster_0.pdf">
                    Manual de Procedimientos
                  </a>
                </li>
                <li>
                  <a href="https://scah.igp.gob.pe/sites/scah.igp.gob.pe//files/public/politicas_seguridad_uso.pdf">
                    Políticas de seguridad
                  </a>
                </li>
              </List>
            </ColumnUi>
          </Row>,
          <p>
            Al momento de enviar el formulario proyecto, todo investigador
            interesado en utilizar la infraestructura, HPC-Linux-Clúster, del
            Laboratorio de Dinámica de Fluidos Geofísicos Computacional,
            reconoce explícitamente que ha leído y que entiende sus
            responsabilidades y deberes como usuario establecidas en el
            documento "Políticas de seguridad y Uso". Si usted tiene preguntas
            sobre estas Políticas de Seguridad y Uso, póngase en contacto con el
            equipo técnico-científico a través del correo electrónico{" "}
            <a href="mailto:dfgc@igp.gob.pe"></a>dfgc@igp.gob.pe.
          </p>
        ]
      },
      {
        title: "Investigaciones",
        slug: "investigaciones",
        content: () => [
          <PublicationList
            collection_id="2144"
            collection_handle="handle/20.500.12816/4538"
          />
        ]
      }
    ],
    microfisica: [
      {
        title: "Acerca del Laboratorio",
        slug: "acerca-de",
        content: () => [
          <p>
            El Observatorio de Huancayo (12°02'18''S, 75°19'22''W, 3350 msnm), a
            pocos kilómetros del poblado de Huayao, es la cuna del Instituto
            Geofísico del Perú. Fue fundado como "Observatorio Magnético de
            Huancayo" en 1922 por la fundación Carnegie en ese lugar debido a su
            cercanía al ecuador magnético. En 1947, con mayor capacidad y
            funciones, se convierte en el "Observatorio Geofísico de Huancayo"
            y, quince años después, se une con otras entidades para formar el
            Instituto Geofísico del Perú.
          </p>,
          <p>
            Desde su inicio en 1922, este observatorio fue sede de importantes
            mediciones e investigaciones geofísicas, entre las cuales se
            encuentra la serie de mediciones climáticas más larga del país. Este
            largo historial hace que esta información sea crucial para el
            estudio del cambio climático en el Perú, mientras que su calidad y
            diversidad en cuanto a instrumental la hacen de utilidad para una
            gran variedad de estudios relacionados al clima.
          </p>,
          <p>
            El Laboratorio de Microfísica Atmosférica y Radiación (LAMAR) del
            IGP tiene como objetivo mejorar la comprensión de los procesos
            físicos que afectan el clima de los Andes, para mejorar la capacidad
            de modelado necesario para la evaluación de los impactos futuros del
            cambio climático, así como apoyar en la validación de técnicas de
            sensoramiento remoto. Se basa en la serie climática larga
            (1921-presente) de la estación meteorológica de Huayao como en
            sofisticados instrumentos especializados, como un radar perfilador
            de vientos, radiómetros de onda corta y larga, un anemómetro sónico,
            entre otros. Actualmente se están adquiriendo un radar perfilador de
            nubes y disdrómetros, que permitirán a LAMAR formar parte de la red
            de validación de la misión GPM, así como estudiar en profundidad la
            física de las precipitaciones en los Andes.
          </p>,
          <img
            src="https://scah.igp.gob.pe/sites/scah.igp.gob.pe/files/public/hpc/img/huayao2.jpg"
            class="thumbnail"
          />
        ]
      },
      {
        title: "Instrumentos",
        slug: "instrumentos",
        content: () => [
          <p>
            La estación de Huayao es el corazón de LAMAR en el Observatorio de
            Huancayo y es considerada como una "Estación Climatológica
            Principal". Cuenta con instrumentos "tradicionales" y las
            observaciones se realizan tres veces al día a través de un convenio
            con{" "}
            <a href="http://www.senamhi.gob.pe/" target="_blank">
              SENAMHI
            </a>
            . Por otro lado, la estación cuenta con instrumental automático que
            registra continuamente.
          </p>,
          <p>
            Los instrumentos "tradicionales" actualmente en operación en la
            estación son:
          </p>,
          <List marked>
            <li>
              <b>Lectura directa</b>
              <List marked>
                <li>Termómetro de máxima</li>
                <li>Termómetro de mínima</li>
                <li>Geotermómetro a 100 cm</li>
                <li>Barómetro patrón</li>
                <li>Anemómetro y veleta</li>
                <li>Psicrómetro</li>
                <li>Termómetro de mínima a 10 cm del suelo</li>
              </List>
            </li>
            <li>
              <b>Registradores</b>
              <List marked>
                <li>Microbarógrafo</li>
                <li>Termógrafo</li>
                <li>Higrógrafo</li>
                <li>Pluviógrafo</li>
              </List>
            </li>
            <li>
              <b>Totalizadores</b>
              <List marked>
                <li>Heliógrafo</li>
                <li>Tanque de evaporación tipo A</li>
                <li>Pluviómetro</li>
              </List>
            </li>
          </List>,
          <p>Los instrumentos automáticos son:</p>,
          <List marked>
            <li>Sensores de temperatura a 2 m y 10 cm del suelo</li>
            <li>Sensor de humedad relativa</li>
            <li>Piranómetros Campbell y Lanat</li>
            <li>Sensor de presión</li>
            <li>Pluviómetro</li>
            <li>Anemómetros y veletas a 3 y 10 m de altura</li>
            <li>Geotermómetros a 20 y 50 cm de profundidad</li>
          </List>,
          <hr />,
          <b>Contacto:</b>,
          <p>
            <a href="mailto:fsilva@igp.gob.pe">Yamina Silva</a>
          </p>
        ]
      },
      {
        title: "Monitoreo LAMAR",
        slug: "monitoreo",
        content: () => [
          <h2>Registro de parámetros atmosféricos</h2>,
          h(
            TableUi,
            {},
            {
              header: (
                <tr>
                  <th width="33.3333%">Temperatura del Aire</th>
                  <th width="33.3333%">Temperatura del Suelo</th>
                  <th>Humedad Atmósferica</th>
                </tr>
              ),
              body: (
                <tr>
                  <td>
                    <img
                      src="http://met.igp.gob.pe/lamar/datos/Tair/Tair.png"
                      alt=""
                    />
                  </td>
                  <td>
                    <img
                      src="http://met.igp.gob.pe/lamar/datos/Tsfc/Tsfc.png"
                      alt=""
                    />
                  </td>
                  <td>
                    <img
                      src="http://met.igp.gob.pe/lamar/datos/HR/HR.png"
                      alt=""
                    />
                  </td>
                </tr>
              )
            }
          ),
          h(
            TableUi,
            {},
            {
              header: (
                <tr>
                  <th width="33.3333%">Precipitación (Pluviómetro)</th>
                  <th width="33.3333%">Precipitación (Distrómetro)</th>
                  <th>Radiación Infraroja</th>
                </tr>
              ),
              body: (
                <tr>
                  <td>
                    <img
                      src="http://met.igp.gob.pe/lamar/datos/PP/PP.png"
                      alt=""
                    />
                  </td>
                  <td>
                    <img
                      src="http://met.igp.gob.pe/lamar/datos/PPD/PPD.png"
                      alt=""
                    />
                  </td>
                  <td>
                    <img
                      src="http://met.igp.gob.pe/lamar/datos/IR/IR.png"
                      alt=""
                    />
                  </td>
                </tr>
              )
            }
          ),
          <h2 class="header">Registro de nubes y precipitaciones por radar</h2>,
          h(
            TableUi,
            {},
            {
              header: (
                <tr>
                  <th width="33.3333%">Reflectividad Equivalente (dBZ)</th>
                  <th width="33.3333%">Velocidad Doppler (m/s)</th>
                  <th>Relación de decorrelación lineal</th>
                </tr>
              ),
              body: (
                <tr>
                  <td>
                    <img
                      src="http://met.igp.gob.pe/lamar/datos/Radar/ultimo.z1.png"
                      alt=""
                    />
                    <p>
                      El factor de reflectividad equivalente (Ze): específica
                      los tipos de hidrometeóros en un volumen de resolución,
                      los colores intensos de rango positivo indican gotas de
                      lluvia más grandes, mientras que los otros se trata de
                      partículas de nube.
                    </p>
                  </td>
                  <td>
                    <img
                      src="http://met.igp.gob.pe/lamar/datos/Radar/ultimo.v1.png"
                      alt=""
                    />
                    <p>
                      Velocidad Doppler (VElg): indica con qué velocidad
                      vertical se esta moviendo los hidrometeoros a medida que
                      varía la altura, se observa que los hidrometeoros
                      ascienden (valores postivos) y descendien (valores
                      negativos).
                    </p>
                  </td>
                  <td>
                    <img
                      src="http://met.igp.gob.pe/lamar/datos/Radar/ultimo.ldr.png"
                      alt=""
                    />
                    <p>
                      Relación de decorrelación lineal (LDR): el radar recibe el
                      promedio espectro de potencia en dos canales (horizontal y
                      vertical) polarizada linealmente, los cuales permiten
                      clasificar tipos de objetivos que se encuentran en la baja
                      atmósfera, como son los hidrometeoros, insectos,etc.
                    </p>
                  </td>
                </tr>
              )
            }
          ),
          <h2 class="header">Observaciones con cámara de cielo</h2>,
          h(
            TableUi,
            {},
            {
              header: (
                <tr>
                  <th width="50%">Cámara diurna</th>
                  <th>Cámara nocturna</th>
                </tr>
              ),
              body: (
                <tr>
                  <td>
                    <img
                      src="http://met.igp.gob.pe/lamar/datos/lifecam/ultimo.jpg"
                      alt=""
                    />
                  </td>
                  <td>
                    <img
                      src="http://met.igp.gob.pe/lamar/datos/oculus/ultimo.jpg"
                      alt=""
                    />
                  </td>
                </tr>
              )
            }
          ),
          <hr />,
          <b>Contacto:</b>,
          <p>
            <a href="mailto:fsilva@igp.gob.pe">Yamina Silva</a>
          </p>
        ]
      },
      {
        title: "Acceso a los Datos",
        slug: "acceso-a-datos",
        content: () => [
          <p>
            Para acceder a nuestros datos debe comunicarse con la Dra. Yamina
            Silva al e-mail:{" "}
            <a href="mailto:fsilva@igp.gob.pe">fsilva@igp.gob.pe</a>.
          </p>,
          <p>
            link:{" "}
            <a href="https://scah.igp.gob.pe/sites/datos/" target="_blank">
              https://scah.igp.gob.pe/sites/datos/
            </a>
          </p>,
          <p>
            <b>Descargar datos:</b>
          </p>,
          <List marked>
            <li>
              <a
                href="https://scah.igp.gob.pe/sites/datos/PP_prods/"
                target="_blank"
              >
                PP_prods
              </a>
            </li>
            <li>
              <a
                href="https://scah.igp.gob.pe/sites/datos/PX1000/"
                target="_blank"
              >
                PX1000
              </a>
            </li>
          </List>
        ]
      },
      {
        title: "Investigaciones",
        slug: "investigaciones",
        content: () => [
          <h2>Proyectos Especiales</h2>,
          <b>Proyecto PEER AEROSOLES en el IGP</b>,
          <p>
            Impacto del transporte transfronterizo de contaminantes del aire
            sobre los Andes Centrales de Perú relacionados a la quema de
            vegetación en la Amazonía. ({" "}
            <a
              href="http://ohy.igp.gob.pe/instrumentos/principal"
              target="_blank"
            >
              más información
            </a>{" "}
            )
          </p>,
          <b>Proyecto AERONET en la NASA</b>,
          <p>
            La colaboración de AERONET proporciona observaciones distribuidas a
            nivel mundial de profundidad óptica de aerosol espectral (AOD),
            productos de inversión y agua precipitable en diversos regímenes de
            aerosoles. ({" "}
            <a href="http://bit.ly/aeronethyo" target="_blank">
              más información
            </a>{" "}
            )
          </p>,
          <PublicationList
            collection_id="2144"
            collection_handle="handle/20.500.12816/4538"
          />
        ]
      }
    ]
  }
};

function getLaboratories(program) {
  return laboratories[program] || laboratories["cah"];
}
export { getLaboratories };
