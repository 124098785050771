<script>
import svgs from "@/assets/icons.js";
import { h } from "vue";
export default {
  name: "Icon",
  props: {
    svg: {
      type: String,
      default: "default"
    }
  },
  setup(props) {
    const svgRender = svgs[props.svg] || svgs.default;
    return () =>
      h(svgRender, {
        class: ["svg-icon", props.class]
      });
  }
};
</script>
