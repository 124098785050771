const menu = {
  cah: [
    {
      path: "/",
      tag: "Inicio",
      name: "home"
    },
    {
      path: "/acerca-de",
      tag: "Acerca de",
      name: "about"
    },
    {
      tag: "Subprogramas",
      children: [
        {
          path: "/subprogramas/oscilacion-sur",
          tag: "El Niño - Oscilación del Sur (ENSO)",
          name: "subp1"
        },
        {
          path: "/subprogramas/variabilidad-cambio-climatico",
          tag: "Variabilidad y Cambio Climático en el Perú",
          name: "subp2"
        },
        {
          path: "/subprogramas/eventos-meteorologicos-hidroclimaticos",
          tag: "Eventos meteorológicos e hidroclimáticos extremos",
          name: "subp3"
        }
      ]
    },
    {
      tag: "Proyectos",
      children: [
        {
          path: "/proyectos/investigacion",
          tag: "Proyectos de Investigación",
          name: "project1"
        },
        {
          path: "/proyectos/tecnologico",
          tag: "Proyectos de Desarrollo Tecnológico",
          name: "project2"
        }
      ]
    },
    {
      tag: "Laboratorios",
      children: [
        {
          path: "/laboratorio/dinamica/acerca-de",
          tag: "Dinámica de Fluídos Geofísicos Computacional",
          name: "lab1",
          children: [
            {
              path: "/laboratorio/dinamica/modelado-numerico",
              tag: "Inicio del Modelado Numérico en el IGP",
              name: "model"
            },
            {
              path: "/laboratorio/dinamica/hpc-linux-cluster",
              tag: "HPC-LINUX-CLUSTER",
              name: "cluster"
            },
            {
              path: "/laboratorio/dinamica/recursos",
              tag: "Acceso a Nuestros Recursos",
              name: "resources"
            },
            {
              path: "/laboratorio/dinamica/investigaciones",
              tag: "Investigaciones",
              name: "research"
            }
          ]
        },
        {
          tag: "Microfísica Atmosférica y Radiación",
          path: "/laboratorio/microfisica/acerca-de",
          name: "lamar",
          children: [
            {
              path: "/laboratorio/microfisica/instrumentos",
              tag: "Instrumentos",
              name: "tools"
            },
            {
              path: "/laboratorio/microfisica/monitoreo",
              tag: "Monitoreo Lamar",
              name: "monitoring"
            },
            {
              path: "/laboratorio/microfisica/acceso-a-datos",
              tag: "Acceso a los Datos",
              name: "data"
            },
            {
              path: "/laboratorio/microfisica/investigaciones",
              tag: "Investigaciones",
              name: "research"
            }
          ]
        }
      ]
    },
    {
      path: "https://repositorio.igp.gob.pe/handle/20.500.12816/55",
      tag: "Publicaciones",
      name: "publications"
    }
  ],
  cts: [
    {
      path: "/",
      tag: "Inicio",
      name: "home"
    },
    {
      path: "/acerca-de",
      tag: "Acerca de",
      name: "about"
    },
    {
      tag: "Subprogramas",
      children: [
        {
          path: "/subprogramas/procesos-dinamica-interna",
          tag: "Procesos de Dinámica Interna",
          name: "subp1"
        },
        {
          path: "/subprogramas/procesos-fisicos-suelos",
          tag: "Procesos Físicos de los Suelos",
          name: "subp2"
        }
      ]
    },
    {
      tag: "Proyectos",
      children: [
        {
          path: "/proyectos/investigacion",
          tag: "Proyectos de Investigación",
          name: "project1"
        },
        {
          path: "/proyectos/tecnologico",
          tag: "Proyectos de Desarrollo Tecnológico",
          name: "project2"
        }
      ]
    },
    {
      path: "https://repositorio.igp.gob.pe/handle/20.500.12816/58",
      tag: "Publicaciones",
      name: "publications"
    },
    {
      path: "/glosario",
      tag: "Glosario",
      name: "glosary"
    }
  ],
  cga: [
    {
      path: "/",
      tag: "Inicio",
      name: "home"
    },
    {
      path: "/acerca-de",
      tag: "Acerca de",
      name: "about"
    },
    {
      tag: "Subprogramas",
      children: [
        {
          path: "/subprogramas/aeronomia-clima-espacial",
          tag: "Aeronomía y Clima Espacial",
          name: "subp1"
        },
        {
          path: "/subprogramas/meteoros-objetos-espaciales",
          tag: "Meteoros y Objetos Espaciales",
          name: "subp2"
        },
        {
          path: "/subprogramas/astronomia",
          tag: "Astronomía",
          name: "subp3"
        }
      ]
    },
    {
      tag: "Proyectos",
      children: [
        {
          path: "/proyectos/investigacion",
          tag: "Proyectos de Investigación",
          name: "project1"
        },
        {
          path: "/proyectos/tecnologico",
          tag: "Proyectos de Desarrollo Tecnológico",
          name: "project2"
        }
      ]
    },
    {
      path: "https://repositorio.igp.gob.pe/handle/20.500.12816/56",
      tag: "Publicaciones",
      name: "publications"
    },
    {
      path: "/glosario",
      tag: "Glosario",
      name: "glosary"
    }
  ],
  ide: [
    {
      path: "/",
      tag: "Inicio",
      name: "home"
    },
    {
      tag: "Acerca de",
      children: [
        {
          tag: "¿Qué es la IDE-IGP?",
          path: `/acerca-de/que-es-la-IDE-IGP`,
          name: "Qué es el IDE-IGP?"
        },
        // {
        //   tag: "¿Qué es un geoportal?",
        //   path: `/acerca-de/que-es-un-geoportal`,
        //   name: "Qué es un geoportal?"
        // },
        {
          tag: "¿Cuáles son los objetivos?",
          path: `/acerca-de/cuales-son-los-objetivos`,
          name: "¿Cuáles son los objetivos?"
        }
        // {
        //   tag: "Normatividad IDE IGP",
        //   path: `/acerca-de/normatividad-IDE-IGP`,
        //   name: "Normatividad IDE IGP"
        // }
      ]
    },
    {
      tag: "Componentes",
      children: [
        {
          path: "/componentes/visor-geografico",
          tag: "Visor Geográfico",
          name: "project1"
        },
        {
          path: "/componentes/webservice",
          tag: "Webservices",
          name: "project2"
        },
        {
          path: "/componentes/metadatos",
          tag: "Catálogo de Metadatos",
          name: "project1"
        },
        {
          path: "/componentes/datos-abiertos",
          tag: "Datos Abiertos",
          name: "project2"
        }
      ]
    },
    {
      path: "/aplicaciones",
      tag: "Aplicaciones",
      name: "Aplicaciones",
      section: false
    }
  ]
};
const trail = [
  {
    path: "https://www.gob.pe/igp",
    tag: "Portal IGP",
    name: "portal"
  },
  {
    path: "../",
    tag: "Servicios",
    name: "pi"
  },
  {
    path: "/",
    tag: process.env.VUE_APP_TITLE,
    name: "root"
  }
];
const dynamicTrail = [
  {
    tag: "Subprogramas",
    path: "/subprogramas"
  },
  {
    tag: "Proyectos",
    path: "/proyectos"
  }
];
function getMenu(program) {
  return menu[program] || "cts";
}
function getTrail() {
  return trail;
}
function getDynamicTrail() {
  return dynamicTrail;
}

function getSection(program, path) {
  let children = menu[program].find(group => {
    if (group.children)
      return group.children.find(child => {
        return child.path == path;
      });
    /*  else{
                return group.path == view
            } */
  });

  return children ? children.children : null;
}
export { getSection, getMenu, getTrail, getDynamicTrail };
