<template>
  <teleport to="body">
      <div :class="`modal${ centered && ' modal--centered' || '' }${ showModal && ' active' || ''}`" @click="close">
          <div class="modal__wrapper">
              <div :class="`modal__content${ showModal && ' active' || ''}`" @click.stop>
                  <div class="modal__header">
                      <slot name="header">
                          <h2>default header</h2>
                      </slot>
                      <button type="button" class="modal__btn" data-dismiss="modal" aria-label="Close" @click="close">
                          <span aria-hidden="true">×</span>
                      </button>
                  </div>

                  <div class="modal__body">
                      <slot name="body">
                          <p>default body</p>
                      </slot>
                  </div>

                  <div class="modal__footer" v-if="hasFooter">
                      <slot name="footer">
                          default footer
                          <button class="modal-default-button" @click="close">
                              OK
                          </button>
                      </slot>
                  </div>
              </div>
          </div>
      </div>
  </teleport>
</template>
<script>
import { computed, defineComponent, onBeforeUnmount, onMounted } from 'vue'
export default defineComponent({
  props: {
      showModal: {
          type: Boolean,
          default: false
      },
      centered: {
          type: Boolean,
          default: false,
      }
  },
  emits: [ 'close' ],
  setup(props, ctx,){
      const hasFooter = computed(() => {
          return ctx.slots.footer ? true : false;
      })
      const close = () => {
          ctx.emit('close');
      }
      function handleKeyDown({key}){
          if (key === "Escape") {
              close();
          }
      }
      onMounted(() => { document.addEventListener("keydown", handleKeyDown) })
      onBeforeUnmount(() => { document.removeEventListener("keydown", handleKeyDown) })


      return {
          hasFooter,
          close
      }
  }
})
</script>
<style lang="scss" scoped>
@use "@/assets/styles/settings/_variables.scss";
.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  outline: 0;
  background-color: variables.$overlay-color; /* Black w/ opacity */
  animation-name: fadeIn;
  animation-duration: 0.4s;
  overflow-x: hidden;
  transition: opacity .15s linear, visibility .15s;
  opacity: 0;
  padding: 0 variables.$padding-container;
  visibility: hidden;
  z-index: variables.$z-modal;
  &.active {
      opacity: 1;
      visibility: visible;
  }
  &__wrapper {
      vertical-align: middle;
      max-width: variables.$md;
      margin: variables.$spacing-s auto;
      min-height: calc(100vh - ( 2rem * 2 ));
  }
  &--centered {
      .modal__wrapper {
          display: flex;
          align-items: center;
          justify-content: center;
      }
  }
  &__btn {
      float: right;
      font-size: 1.5rem;
      font-weight: 700;
      line-height: 1;
      color: #000;
      text-shadow: 0 1px 0 #fff;
      opacity: .5;
      color: currentColor;
      background-color: transparent;
      border: 0;
      padding: 1rem;
      margin: -(variables.$spacing-s) (-(variables.$spacing)) -1rem auto;
      outline: none;
      &:not(:disabled):not(.disabled) {
          cursor: pointer;
      }
      &:focus, &:hover {
          text-decoration: none;
          opacity: .75;
      }
  }
  &__content {
      pointer-events: auto;
      box-shadow: variables.$bs-modal;
      border-radius: variables.$br-box;
      transform: translateY(-200px);
      transition: transform .3s ease-out, opacity .15s linear;
      opacity: 0;
      &>:first-child {
          border-top-left-radius: variables.$br-box;
          border-top-right-radius: variables.$br-box;
      }
      &>:last-child {
          border-bottom-left-radius: variables.$br-box;
          border-bottom-right-radius: variables.$br-box;
      }
      &.active {
          transform: translateY(0);
          opacity: 1;
      }
  }
  &__header {
      padding: variables.$spacing-s variables.$spacing;
      background-color: variables.$primary-color;
      color: variables.$text-inverted-color;
      display: flex;
      align-items: flex-start;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
      h2,h3,h4,h5,h6 {
          margin: 0;
      }
  }
  &__body {
      padding: variables.$spacing ;
      background-color: variables.$background-lighter-color
  }
}
</style>