<template>
  <section class="subprograms">
    <Container tag="h2" class="is-centered">
      {{ subprogramList.title }}
    </Container>
    <ContainerLR>
      <div class="subprograms__container-l">
        <ContentL>
          <Content v-if="content" :content="content" />
        </ContentL>
      </div>
      <div class="subprograms__container-r">
        <ContentR>
          <table>
            <tbody>
              <tr
                v-for="(subprogram, index) in subprogramList.links"
                :key="index"
                class="program"
              >
                <td>
                  <h5 class="program__title">
                    <Icon svg="doubleAngleRight" />{{ subprogram.title }}
                  </h5>
                </td>
              </tr>
              <Button
                    tag="a"
                    theme="inverted"
                    href="aplicaciones"
                  >
                    Ver más
                  </Button>
            </tbody>
          </table>
        </ContentR>
      </div>
    </ContainerLR>
  </section>
</template>
<script>
import Container from "@/components/ui/objects/Container.vue";
import ContainerLR from "@/components/ui/objects/ContainerLR.vue";
import ContentL from "@/components/ui/molecules/ContentL.vue";
import ContentR from "@/components/ui/molecules/ContentR.vue";
import Content from "@/components/common/Content.vue";
import Button from "@/components/ui/atoms/Button";
import Icon from "@/components/ui/atoms/Icon.vue";
export default {
  components: {
    Container,
    ContainerLR,
    ContentR,
    ContentL,
    Content,
    Button,
    Icon
  },
  props: {
    subprograms: {
      type: Array,
      default: () => []
    },
    subprogramList: {
      type: Array,
      default: () => []
    }
  },
  setup(props) {
    const content = props.subprogramList && props.subprogramList.content;
    return {
      content
    };
  }
};
</script>
<style lang="scss" scoped>
@use "@/assets/styles/settings/_variables.scss";
.subprograms {
  background-color: variables.$background-dark-color;
  color: variables.$text-inverted-color;
  padding: variables.$spacing-l 0;
  .program {
    &__title {
      margin: 0;
      padding-top: 0.6em;
      padding-bottom: 0.6em;
    }
    .btn {
      margin-left: 1em;
    }
  }
}
</style>
