<template>
  <component :is="tag" class="content-l">
    <slot />
  </component>
</template>

<script>
export default {
  props: {
    tag: {
      type: String,
      default: "div"
    }
  }
};
</script>

<style lang="scss" scoped>
@use "@/assets/styles/settings/_variables.scss";
.content-l {
  padding-right: 15px;
  padding-left: 15px;
  width: 100%;
}
@media (min-width: variables.$sm) {
  .content-l {
    width: variables.$sm-width;
    margin-right: auto;
    margin-left: auto;
  }
}
@media (min-width: variables.$md) {
  .content-l {
    padding-right: variables.$spacing-l / 2;
    width: variables.$md-width / 2;
    margin-right: 0;
  }
}
@media (min-width: variables.$lg) {
  .content-l {
    width: variables.$lg-width / 2;
  }
}
@media (min-width: variables.$xl) {
  .content-l {
    width: variables.$xl-width / 2;
  }
}
</style>
