<template>
  <div class="input">
    <input
      type="text"
      :class="`input__field${(active && ' active') || ''}`"
      minlength="5"
      required="required"
      @focus="isFocused"
      @blur="isNotFocused"
      v-model="val"
    />
    <label for="password" :class="`input__label${(active && ' active') || ''}`"
      >Buscar</label
    >
  </div>
</template>
<script>
import { ref, watch } from "vue";
export default {
  props: {
    inputValue: {
      type: String,
      default: () => ""
    }
  },
  emits: ["getValue"],
  setup(props, { emit }) {
    const active = ref(false);
    const val = ref("");

    const isFocused = () => {
      active.value = true;
    };
    const isNotFocused = () => {
      active.value = val.value.length;
    };

    watch(val, () => {
      emit("getValue", val.value);
    });

    return {
      isFocused,
      isNotFocused,
      active,
      val
    };
  }
};
</script>
<style lang="scss" scoped>
@use "@/assets/styles/settings/_variables.scss";
.input {
  position: relative;
  &__field {
    &:invalid {
      box-shadow: none;
    }

    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-size: 1rem;
    font-weight: 400;
    text-decoration: inherit;
    text-transform: inherit;
    align-self: flex-end;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    padding: 0.75rem 1rem;
    transition: opacity 150ms cubic-bezier(0.4, 0, 0.2, 1);
    border: 1px solid variables.$input-border-color;
    border-radius: variables.$br-box;
    background: none;
    appearance: none;
    outline: 0;
    &:focus,
    &:valid {
      border-color: variables.$input-focus-border-color;
      box-shadow: 0 0 0 1px variables.$input-bs-color;
      ~ label {
        color: variables.$primary-color;
      }
    }
  }
  &__label {
    color: #9e9e9e;
    position: absolute;
    top: 0;
    left: 0;
    cursor: text;
    transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1),
      color 150ms cubic-bezier(0.4, 0, 0.2, 1);
    transform-origin: 0% 100%;
    text-align: initial;
    transform: translate(10px, 15px) scale(1);
    padding: 0.1em 0.5em;
    background-color: white;
    line-height: 1;
    pointer-events: none;
    &.active {
      transform: translate(10px, -10px) scale(0.95);
      font-size: 90%;
    }
  }
}
</style>
