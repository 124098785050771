<template>
  <component
    :is="tag"
    :class="
      `${(sm && 'col-sm-' + sm) || ''} ${(md && 'col-md-' + md) || ''} ${(lg &&
        'col-lg-' + lg) ||
        ''}`
    "
  >
    <slot />
  </component>
</template>

<script>
export default {
  name: "Columnui",
  props: {
    tag: {
      type: String,
      default: "div"
    },
    md: {
      type: String
    },
    sm: {
      type: String
    },
    lg: {
      type: String
    }
  }
};
</script>

<style lang="scss" scoped>
@use "@/assets/styles/settings/_variables.scss";
@use "@/assets/styles/tools/_mixins.scss";
.col,
.col-md-1,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-10,
.col-md-11,
.col-md-12 {
  position: relative;
  width: 100%;
  padding-right: variables.$padding-container;
  padding-left: variables.$padding-container;
}
@media (min-width: variables.$md) {
  .col-md-2 {
    flex: 0 0 16%;
    max-width: 16%;
  }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-md-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-md-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
}
@media (min-width: variables.$lg) {
   .col-lg-2 {
    flex: 0 0 16%;
    max-width: 16%;
  }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-lg-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
}
</style>