<template>
  <List marked>
    <li v-for="(subp, index) in three" :key="index">
      <router-link v-if="view != subp.path" :to="subp.path">
        {{ subp.tag }}
      </router-link>
      <span v-else>
        {{ subp.tag }}
      </span>
    </li>
  </List>
</template>
<script>
import { useRoute } from "vue-router";
import List from "@/components/ui/objects/List.vue";
export default {
  name: "EnEstaSeccion",
  components: {
    List
  },
  props: {
    three: Array
  },
  setup() {
    const view = useRoute().fullPath;
    return {
      view
    };
  },
  methods: {
    abrir: async function(clase) {
      document.querySelectorAll(`.${clase}`).forEach(item => {
        if (item.style.display == "none") {
          item.style.display = "grid";
        } else {
          item.style.display = "none";
        }
      });
    }
  }
};
</script>

<style media="screen"></style>
