<template>
    <Container tag="section">
       <Row>
             <ColumnUi md="12" lg="12">
                <Title tag="h1">
                        {{ program.title }}
                </Title>
                 <p>
                    {{ program.description }}
                    </p>
            </ColumnUi>
       </Row>
    </Container>
</template>

<script>
import Container from "@/components/ui/objects/Container";
import Row from "@/components/ui/atoms/Row.vue";
import ColumnUi from "@/components/ui/atoms/ColumnUi.vue";
import Title from "@/components/ui/atoms/Title";

    export default {
    name: "About",
    components: {
        Container,
        Row,
        ColumnUi,
        Title,
    },
    props: {
        program: {
        type: Object,
        default: () => {}
        }
      }
    };
</script>


<style lang="scss" scoped>
h1{
    margin: 2rem 0 2rem 0;
}
.title{
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.btn-about{
    width: 60%;
}
@media (max-width: 768px) {
    .btn-about{
        width: 50%;
        margin-bottom: 25px;
    }
}

@media (max-width: 550px) {
    .btn-about{
        width: 100%;
        margin-bottom: 25px;
    }
}

</style>