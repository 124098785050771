<template>
  <ul
    :class="
      `list ${(this.horizontal && 'list--horizontal') ||
        (this.marked && 'list--marked') ||
        ''} ${this.class}`
    "
  >
    <slot />
  </ul>
</template>
<script>
export default {
  props: {
    horizontal: {
      type: Boolean,
      default: false
    },
    marked: {
      type: Boolean,
      default: false
    },
    class: {
      type: String,
      default: ""
    }
  }
};
</script>

<style lang="scss">
@use "@/assets/styles/settings/_variables.scss";
.list {
  margin: 0;
  padding: 0;
  list-style: none;
  &--horizontal {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }
  &--grid {
    display: grid;
    grid-gap: 0 4em;
    grid-template-columns: repeat(2, 1fr);
  }
  &--marked {
    margin-left: 1em;
    list-style: disc;
    margin-bottom: variables.$spacing-s;
    li::marker {
      color: variables.$primary-color;
    }
  }
  .list {
    &--marked {
      list-style: circle;
    }
  }
}
</style>
