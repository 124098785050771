const svgs = {
  home: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlns="http://www.w3.org/1999/xlink"
      viewBox="0 0 460.298 460.297"
      style="enable-background:new 0 0 460.298 460.297;"
    >
      <path d="M230.149,120.939L65.986,256.274c0,0.191-0.048,0.472-0.144,0.855c-0.094,0.38-0.144,0.656-0.144,0.852v137.041    c0,4.948,1.809,9.236,5.426,12.847c3.616,3.613,7.898,5.431,12.847,5.431h109.63V303.664h73.097v109.64h109.629    c4.948,0,9.236-1.814,12.847-5.435c3.617-3.607,5.432-7.898,5.432-12.847V257.981c0-0.76-0.104-1.334-0.288-1.707L230.149,120.939    z" />{" "}
      <path d="M457.122,225.438L394.6,173.476V56.989c0-2.663-0.856-4.853-2.574-6.567c-1.704-1.712-3.894-2.568-6.563-2.568h-54.816    c-2.666,0-4.855,0.856-6.57,2.568c-1.711,1.714-2.566,3.905-2.566,6.567v55.673l-69.662-58.245    c-6.084-4.949-13.318-7.423-21.694-7.423c-8.375,0-15.608,2.474-21.698,7.423L3.172,225.438c-1.903,1.52-2.946,3.566-3.14,6.136    c-0.193,2.568,0.472,4.811,1.997,6.713l17.701,21.128c1.525,1.712,3.521,2.759,5.996,3.142c2.285,0.192,4.57-0.476,6.855-1.998    L230.149,95.817l197.57,164.741c1.526,1.328,3.521,1.991,5.996,1.991h0.858c2.471-0.376,4.463-1.43,5.996-3.138l17.703-21.125    c1.522-1.906,2.189-4.145,1.991-6.716C460.068,229.007,459.021,226.961,457.122,225.438z" />
    </svg>
  ),
  instagram: (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
      <path d="m301 256c0 24.851562-20.148438 45-45 45s-45-20.148438-45-45 20.148438-45 45-45 45 20.148438 45 45zm0 0" />
      <path d="m332 120h-152c-33.085938 0-60 26.914062-60 60v152c0 33.085938 26.914062 60 60 60h152c33.085938 0 60-26.914062 60-60v-152c0-33.085938-26.914062-60-60-60zm-76 211c-41.355469 0-75-33.644531-75-75s33.644531-75 75-75 75 33.644531 75 75-33.644531 75-75 75zm86-146c-8.285156 0-15-6.714844-15-15s6.714844-15 15-15 15 6.714844 15 15-6.714844 15-15 15zm0 0" />
      <path d="m377 0h-242c-74.4375 0-135 60.5625-135 135v242c0 74.4375 60.5625 135 135 135h242c74.4375 0 135-60.5625 135-135v-242c0-74.4375-60.5625-135-135-135zm45 332c0 49.625-40.375 90-90 90h-152c-49.625 0-90-40.375-90-90v-152c0-49.625 40.375-90 90-90h152c49.625 0 90 40.375 90 90zm0 0" />
    </svg>
  ),

  facebook: (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
      <path d="m437 0h-362c-41.351562 0-75 33.648438-75 75v362c0 41.351562 33.648438 75 75 75h151v-181h-60v-90h60v-61c0-49.628906 40.371094-90 90-90h91v90h-91v61h91l-15 90h-76v181h121c41.351562 0 75-33.648438 75-75v-362c0-41.351562-33.648438-75-75-75zm0 0" />
    </svg>
  ),

  linkedin: (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
      <path d="m437 0h-362c-41.351562 0-75 33.648438-75 75v362c0 41.351562 33.648438 75 75 75h362c41.351562 0 75-33.648438 75-75v-362c0-41.351562-33.648438-75-75-75zm-256 406h-60v-210h60zm0-240h-60v-60h60zm210 240h-60v-120c0-16.539062-13.460938-30-30-30s-30 13.460938-30 30v120h-60v-210h60v11.308594c15.71875-4.886719 25.929688-11.308594 45-11.308594 40.691406.042969 75 36.546875 75 79.6875zm0 0" />
    </svg>
  ),

  youtube: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 504 504"
      style="enable-background:new 0 0 504 504;"
    >
      <polygon
        xmlns="http://www.w3.org/2000/svg"
        points="219.6,202.4 219.6,294.4 304.4,248.8   "
      />
      <path d="M377.6,0H126C56.8,0,0,56.8,0,126.4V378c0,69.2,56.8,126,126,126h251.6c69.6,0,126.4-56.8,126.4-126.4V126.4    C504,56.8,447.2,0,377.6,0z M408,264.4c0,26.4-2.4,53.2-2.4,53.2s-2.8,22.4-12,32.4c-12,13.2-25.2,13.2-31.2,14    c-44,3.2-110,3.6-110,3.6s-82-1.2-107.2-3.6c-6.8-1.2-22.8-0.8-34.8-14c-9.6-10-12-32.4-12-32.4S96,290.8,96,264.4v-24.8    c0-26.4,2.4-53.2,2.4-53.2s2.8-22.4,12-32.4c12-13.2,25.2-13.6,31.2-14.4C186,136.4,252,136,252,136s66,0.4,110,3.6    c6,0.8,19.6,1.2,31.6,14c9.6,10,12,32.8,12,32.8s2.4,26.8,2.4,53.2V264.4z" />
    </svg>
  ),

  twitter: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 504.4 504.4"
      style="enable-background:new 0 0 504.4 504.4;"
    >
      <path d="M377.6,0.2H126.4C56.8,0.2,0,57,0,126.6v251.6c0,69.2,56.8,126,126.4,126H378c69.6,0,126.4-56.8,126.4-126.4V126.6    C504,57,447.2,0.2,377.6,0.2z M377.2,189c0,2.8,0,5.6,0,8.4c0,84-64.8,180.8-183.6,180.8c-36.4,0-70.4-10.4-98.8-28.4    c5.2,0.4,10,0.8,15.2,0.8c30.4,0,58-10,80-27.2c-28.4-0.4-52-18.8-60.4-44c4,0.8,8,1.2,12,1.2c6,0,12-0.8,17.2-2.4    c-28.8-6-50.8-31.6-50.8-62.4V215c8,4.8,18.4,7.6,28.8,8c-17.2-11.2-28.8-30.8-28.8-52.8c0-11.6,3.2-22.4,8.8-32    c32,38.4,79.2,63.6,132.8,66.4c-1.2-4.8-1.6-9.6-1.6-14.4c0-35.2,28.8-63.6,64.4-63.6c18.4,0,35.2,7.6,47.2,20    c14.8-2.8,28.4-8,40.8-15.6c-4.8,14.8-15.2,27.2-28.4,35.2c13.2-1.6,25.6-4.8,37.2-10C400.4,169,389.6,180.2,377.2,189z" />
    </svg>
  ),

  flickr: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 504.4 504.4"
      style="enable-background:new 0 0 504.4 504.4;"
    >
      <path d="M377.6,0.2H126.4C56.8,0.2,0,57,0,126.6v251.6c0,69.2,56.8,126,126.4,126H378c69.6,0,126.4-56.8,126.4-126.4V126.6    C504,57,447.2,0.2,377.6,0.2z M158.8,325.4c-41.2,0-74.4-32.8-74.4-73.2s33.2-73.2,74.4-73.2c41.2,0,74.4,32.8,74.4,73.2    C233.2,292.6,199.6,325.4,158.8,325.4z M345.2,325.4c-41.2,0-74.4-32.8-74.4-73.2S304,179,345.2,179c41.2,0,74.4,32.8,74.4,73.2    C419.6,292.6,386.4,325.4,345.2,325.4z" />
    </svg>
  ),

  folder: (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -8 394 394">
      <path d="m292.476562 48.902344h-79.675781c-1.015625-.003906-1.984375-.429688-2.667969-1.179688l-28.914062-31.628906c-9.378906-10.242188-22.621094-16.078125-36.507812-16.09375h-95.253907c-27.300781.03125-49.425781 22.15625-49.457031 49.457031v278.214844c.03125 27.300781 22.15625 49.425781 49.457031 49.457031h295.085938c27.300781-.03125 49.425781-22.15625 49.457031-49.457031v-178.214844c-.03125-27.300781-22.15625-49.425781-49.457031-49.457031h-2.609375v-1.640625c-.03125-27.300781-22.15625-49.425781-49.457032-49.457031zm-238.550781 314.226562h-4.46875c-19.574219-.023437-35.433593-15.886718-35.457031-35.457031v-278.214844c.023438-19.574219 15.882812-35.433593 35.457031-35.457031h95.253907c9.953124.011719 19.449218 4.195312 26.171874 11.535156l28.910157 31.636719c3.339843 3.644531 8.054687 5.722656 13 5.730469h79.679687c19.574219.023437 35.433594 15.882812 35.457032 35.457031v1.640625h-189.085938c-27.300781.03125-49.425781 22.15625-49.457031 49.460938v178.210937c-.023438 19.570313-15.886719 35.433594-35.460938 35.457031zm290.617188-249.128906c19.574219.023438 35.433593 15.882812 35.457031 35.457031v178.214844c-.023438 19.570313-15.882812 35.433594-35.457031 35.457031h-256.167969c9.601562-9.296875 15.015625-22.09375 15.011719-35.457031v-178.214844c.019531-19.574219 15.882812-35.433593 35.457031-35.457031zm0 0" />
      <path d="m190.53125 337.457031h102.324219c14.007812-.015625 25.359375-11.371093 25.375-25.378906v-115.839844c0-1.855469-.738281-3.640625-2.054688-4.953125l-49.566406-49.464844c-.011719-.011718-.027344-.019531-.039063-.03125-1.3125-1.351562-3.121093-2.117187-5.007812-2.117187-.160156 0-.3125.015625-.464844.023437-.15625-.007812-.3125-.023437-.46875-.023437h-70.097656c-14.011719.015625-25.363281 11.371094-25.378906 25.378906v147.027344c.015625 14.007813 11.367187 25.363281 25.378906 25.378906zm78.03125-173.910156 25.847656 25.800781h-14.472656c-6.277344-.007812-11.367188-5.097656-11.375-11.378906zm-89.410156 1.5c.007812-6.28125 5.097656-11.371094 11.378906-11.375h64.03125v24.296875c.015625 14.011719 11.367188 25.363281 25.375 25.378906h24.292969v108.730469c-.003907 6.28125-5.09375 11.371094-11.375 11.378906h-102.324219c-6.28125-.007812-11.371094-5.097656-11.378906-11.378906zm0 0" />
      <path d="m196.988281 233.148438h49.671875c3.867188 0 7-3.136719 7-7 0-3.867188-3.132812-7-7-7h-49.671875c-3.867187 0-7 3.132812-7 7 0 3.863281 3.132813 7 7 7zm0 0" />
      <path d="m196.988281 267.917969h89.410157c3.867187 0 7-3.136719 7-7 0-3.867188-3.132813-7-7-7h-89.410157c-3.867187 0-7 3.132812-7 7 0 3.863281 3.132813 7 7 7zm0 0" />
      <path d="m196.988281 302.6875h89.410157c3.867187 0 7-3.132812 7-7s-3.132813-7-7-7h-89.410157c-3.867187 0-7 3.132812-7 7s3.132813 7 7 7zm0 0" />
    </svg>
  ),

  doubleAngleRight: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 284.936 284.936"
      style="enable-background:new 0 0 284.936 284.936;"
    >
      <path d="M277.515,135.9L144.464,2.857C142.565,0.955,140.375,0,137.9,0c-2.472,0-4.659,0.955-6.562,2.857l-14.277,14.275    c-1.903,1.903-2.853,4.089-2.853,6.567c0,2.478,0.95,4.664,2.853,6.567l112.207,112.204L117.062,254.677    c-1.903,1.903-2.853,4.093-2.853,6.564c0,2.477,0.95,4.667,2.853,6.57l14.277,14.271c1.902,1.905,4.089,2.854,6.562,2.854    c2.478,0,4.665-0.951,6.563-2.854l133.051-133.044c1.902-1.902,2.851-4.093,2.851-6.567S279.417,137.807,277.515,135.9z" />
      <path d="M170.732,142.471c0-2.474-0.947-4.665-2.857-6.571L34.833,2.857C32.931,0.955,30.741,0,28.267,0s-4.665,0.955-6.567,2.857    L7.426,17.133C5.52,19.036,4.57,21.222,4.57,23.7c0,2.478,0.95,4.664,2.856,6.567L119.63,142.471L7.426,254.677    c-1.906,1.903-2.856,4.093-2.856,6.564c0,2.477,0.95,4.667,2.856,6.57l14.273,14.271c1.903,1.905,4.093,2.854,6.567,2.854    s4.664-0.951,6.567-2.854l133.042-133.044C169.785,147.136,170.732,144.945,170.732,142.471z" />
    </svg>
  ),

  angleRight: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 444.819 444.819"
      style="enable-background:new 0 0 444.819 444.819;"
    >
      <path d="M352.025,196.712L165.884,10.848C159.029,3.615,150.469,0,140.187,0c-10.282,0-18.842,3.619-25.697,10.848L92.792,32.264   c-7.044,7.043-10.566,15.604-10.566,25.692c0,9.897,3.521,18.56,10.566,25.981l138.753,138.473L92.786,361.168   c-7.042,7.043-10.564,15.604-10.564,25.693c0,9.896,3.521,18.562,10.564,25.98l21.7,21.413   c7.043,7.043,15.612,10.564,25.697,10.564c10.089,0,18.656-3.521,25.697-10.564l186.145-185.864   c7.046-7.423,10.571-16.084,10.571-25.981C362.597,212.321,359.071,203.755,352.025,196.712z" />
    </svg>
  ),

  thinAngleRight: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 792.049 792.049"
      style="enable-background:new 0 0 792.049 792.049;"
    >
      <path d="M622.955,342.127L268.424,20.521c-27.36-27.36-71.677-27.36-99.037,0c-27.36,27.36-27.36,71.676,0,99.037     l304.749,276.468L169.387,672.492c-27.36,27.359-27.36,71.676,0,99.036s71.677,27.36,99.037,0l354.531-321.606     c14.783-14.783,21.302-34.538,20.084-53.897C644.225,376.665,637.738,356.911,622.955,342.127z" />
    </svg>
  ),

  prevPage: (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path d="M0 0h24v24H0z" fill="none" />
      <path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z" />
    </svg>
  ),

  nextPage: (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path d="M0 0h24v24H0z" fill="none" />
      <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z" />
    </svg>
  ),

  firstPage: (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path d="M18.41 16.59L13.82 12l4.59-4.59L17 6l-6 6 6 6zM6 6h2v12H6z" />
      <path d="M24 24H0V0h24v24z" fill="none" />
    </svg>
  ),

  lastPage: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="black"
      width="18px"
      height="18px"
    >
      <path d="M0 0h24v24H0V0z" fill="none" />
      <path d="M5.59 7.41L10.18 12l-4.59 4.59L7 18l6-6-6-6zM16 6h2v12h-2z" />
    </svg>
  ),

  featured: (
  <svg 
    xmlns="http://www.w3.org/2000/svg"
    width="40.000000pt"
    height="40.000000pt"
    viewBox="0 0 40.000000 40.000000"
    preserveAspectRatio="xMidYMid meet">

  <g transform="translate(0.000000,40.000000) scale(0.100000,-0.100000)" stroke="none">
    <path d="M115 387 c-42 -20 -92 -74 -106 -115 -17 -52 -7 -144 21 -184 43 -63 83 -83 170 -83 87 0 127 20 171 83 20 30 24 48 24 112 0 87 -20 127 -83 171 -42 29 -149 37 -197 16z m115 -97 c0 -38 -2 -40 -30 -40 -28 0 -30 2 -30 40 0 38 2 40 30 40 28 0 30 -2 30 -40z m0 -150 l0 -80 -30 0 -30 0 0 80 0 80 30 0 30 0 0 -80z"/>
  </g>
  </svg>
  ),

  tematicas: (
    <svg
      width="64px"
      height="64px"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      fill="#0000af">
        <g stroke-linecap="round" stroke-linejoin="round"></g>
        <g>
          <path d="M3.893 15.947l-.927.547L12 21.838l9.033-5.342-.927-.547.983-.582L23 16.495 12 23 1 16.493l1.91-1.128zM23 8.495L12 15 1 8.493 12 2zm-10.999 5.343l9.033-5.342-9.033-5.335-9.035 5.333zm9.089-2.47l-.983.58.927.548-9.033 5.342-9.035-5.344.927-.547-.983-.582L1 12.493 12 19l11-6.505z"></path><path fill="none" d="M0 0h24v24H0z"></path></g></svg>
  ),
  siempre_con_el_pueblo:(
    <svg 
    version="1.0" 
    xmlns="http://www.w3.org/2000/svg"

    viewBox="0 0 489.000000 178.000000"
    preserveAspectRatio="xMidYMid meet">

  <g transform="translate(0.000000,178.000000) scale(0.100000,-0.100000)" stroke="none">
    <path d="M33 1760 c-24 -10 -37 -41 -28 -68 9 -29 49 -42 129 -42 58 0 79 -4 97 -19 23 -18 24 -25 22 -129 l-2 -110 33 1 34 1 4 101 c2 75 8 108 21 128 17 27 18 27 145 27 70 0 132 -4 139 -8 26 -17 32 -63 26 -215 l-6 -155 41 1 42 2 0 166 c0 127 3 170 14 185 13 17 30 19 138 22 121 4 125 3 154 -21 l29 -25 3 -216 3 -216 35 0 34 0 0 194 c0 111 5 206 11 224 14 40 61 62 134 62 102 0 151 -43 148 -129 -1 -20 1 -55 3 -78 5 -41 7 -43 38 -43 l33 0 6 93 c2 50 8 100 12 110 13 31 59 47 140 47 62 0 78 3 96 21 23 23 19 54 -13 82 -20 16 -70 17 -857 16 -460 0 -846 -5 -858 -9z"/> 
    <path d="M2035 1541 c-107 -28 -161 -115 -134 -214 15 -55 53 -88 135 -116 101 -35 121 -47 132 -79 29 -85 -65 -113 -203 -61 -48 18 -51 18 -63 2 -7 -10 -12 -31 -10 -48 4 -44 43 -59 159 -59 81 0 102 4 144 25 64 31 87 65 93 132 8 99 -35 146 -175 191 -94 31 -127 79 -89 132 19 25 104 25 171 0 47 -18 49 -18 62 -1 18 26 16 59 -6 79 -21 19 -165 31 -216 17z"/> 
    <path d="M2411 1538 c-31 -17 -42 -49 -27 -79 16 -34 86 -39 115 -10 24 24 27 63 8 80 -22 18 -70 22 -96 9z"/>
    <path d="M2770 1390 c-100 -18 -151 -86 -158 -210 -9 -153 46 -209 201 -210 102 0 147 18 147 59 0 39 -10 44 -54 26 -55 -23 -112 -20 -141 10 -14 13 -25 31 -25 39 0 8 -3 21 -6 29 -4 12 12 15 96 19 119 4 130 12 130 91 0 112 -73 169 -190 147z m64 -92 c9 -12 16 -34 16 -49 0 -30 -19 -39 -84 -39 l-38 0 7 37 c4 21 11 46 16 55 14 26 64 23 83 -4z"/> 
    <path d="M3940 1390 c-19 -4 -47 -12 -62 -18 -23 -10 -28 -9 -28 3 0 18 -90 21 -121 5 -26 -14 -24 -24 9 -55 l27 -25 3 -225 c4 -219 3 -225 -16 -225 -25 0 -44 -23 -36 -44 6 -14 21 -16 113 -14 106 3 106 3 106 28 0 20 -6 26 -27 28 -27 3 -28 5 -28 63 0 58 0 59 25 53 41 -10 123 15 160 50 84 80 86 308 2 360 -29 18 -83 24 -127 16z m47 -96 c12 -19 17 -49 17 -119 1 -114 -13 -138 -78 -133 l-41 3 -3 114 c-2 89 1 119 13 137 22 34 69 33 92 -2z"/>
    <path d="M4705 1393 c-56 -10 -69 -16 -105 -49 -52 -47 -75 -120 -67 -212 5 -68 22 -100 71 -136 24 -18 42 -21 136 -21 92 0 111 3 129 19 24 22 26 35 8 60 -11 16 -15 16 -49 1 -50 -20 -111 -19 -137 3 -12 9 -24 33 -27 53 l-6 37 96 4 c118 5 131 14 129 92 -2 92 -39 137 -123 148 -19 2 -44 3 -55 1z m47 -90 c10 -9 18 -30 18 -49 0 -30 -4 -34 -34 -39 -18 -4 -44 -5 -57 -3 -21 3 -24 8 -21 38 7 63 54 90 94 53z"/>
    <path d="M2350 1376 c-15 -19 -7 -46 14 -46 28 0 36 -38 36 -169 l0 -131 -24 0 c-30 0 -40 -13 -31 -40 6 -18 15 -20 104 -20 71 0 102 4 110 14 17 20 3 46 -25 46 l-24 0 -2 178 -3 177 -71 3 c-54 2 -74 -1 -84 -12z"/>
    <path d="M3038 1379 c-25 -14 -22 -34 6 -52 21 -14 24 -27 31 -101 3 -46 4 -109 0 -140 -6 -46 -11 -55 -29 -58 -15 -2 -21 -10 -21 -28 0 -25 0 -25 95 -25 l95 0 3 28 c2 19 -1 27 -12 27 -14 0 -16 19 -16 129 0 120 2 131 21 144 23 17 52 12 71 -11 9 -10 14 -57 17 -139 3 -108 1 -123 -13 -123 -11 0 -16 -9 -16 -30 l0 -31 93 3 92 3 3 28 c3 23 0 27 -22 27 l-26 0 0 114 c0 124 8 161 37 168 59 14 71 -18 75 -190 2 -105 6 -135 18 -142 17 -11 100 -13 124 -4 22 9 20 41 -4 54 -18 10 -20 21 -21 118 0 142 -11 201 -39 224 -30 25 -114 24 -164 -1 -37 -19 -38 -19 -57 0 -26 26 -109 25 -168 -1 -53 -24 -51 -24 -51 0 0 17 -7 20 -52 20 -29 0 -61 -5 -70 -11z"/>
    <path d="M4198 1379 c-24 -14 -23 -24 9 -53 27 -25 27 -27 27 -158 1 -125 0 -133 -19 -136 -29 -6 -38 -17 -30 -41 6 -21 12 -22 118 -19 l112 3 0 25 c0 21 -5 26 -32 28 l-33 3 0 117 c0 90 4 123 16 140 18 26 31 28 55 6 27 -25 71 0  80 46 4 19 5 38 3 42 -9 14 -73 8 -126 -12 -29 -11 -54 -20 -55 -20 -2 0 -3 9 -3 20 0 17 -7 20 -52 20 -29 0 -61 -5 -70 -11z"/>
    <path d="M243 1365 c-52 -22 -70 -86 -37 -133 23 -33 33 -27 27 18 -4 30 -1 45 12 60 22 24 72 26 103 4 14 -9 22 -11 22 -4 0 24 -21 48 -51 59 -39 13 -37 13 -76 -4z"/>
    <path d="M1415 1355 c-14 -13 -25 -31 -25 -39 0 -13 5 -12 33 4 63 39 117 13 117 -57 1 -36 2 -37 15 -19 23 31 19 89 -8 114 -34 32 -100 30 -132 -3z"/>
    <path d="M1432 1304 c-23 -16 -28 -49 -12 -74 8 -13 10 -13 10 2 0 23 45 52 70 45 22 -6 27 12 8 31 -17 17 -49 15 -76 -4z"/>
    <path d="M263 1303 c-7 -2 -13 -13 -13 -24 0 -16 7 -19 40 -19 27 0 43 -5 46 -15 8 -21 24 -19 24 3 0 46 -50 75 -97 55z"/>
    <path d="M655 1248 c-28 -15 -45 -38 -45 -61 0 -18 1 -17 26 2 57 45 124 16 124 -52 0 -40 24 -29 28 12 8 83 -64 137 -133 99z"/>
    <path d="M1465 1250 c-3 -6 -9 -89 -12 -185 l-5 -175 39 0 38 0 -3 173 c-2 94 -5 178 -7 185 -5 14 -41 16 -50 2z"/>
    <path d="M273 1243 c-9 -3 -13 -27 -13 -71 0 -37 -7 -143 -15 -237 -8 -93 -15 -197 -15 -230 l0 -61 35 4 35 4 0 54 c0 29 7 104 16 166 20 143 16 354 -6 368 -16 11 -19 11 -37 3z"/>
    <path d="M647 1172 c-17 -18 -23 -53 -11 -64 3 -3 16 5 30 19 18 17 33 24 50 20 26 -5 32 11 12 31 -18 18 -63 14 -81 -6z"/>
    <path d="M1043 1126 c-22 -19 -28 -32 -28 -65 0 -25 7 -47 18 -58 16 -16 17 -15 17 24 0 24 6 48 16 57 20 21 73 20 102 0 29 -20 36 -14 21 19 -23 50 -100 62 -146 23z"/>
    <path d="M687 1123 c-16 -4 -17 -12 -10 -66 10 -77 10 -252 1 -333 l-7 -62 37 -5 c20 -2 38 -3 40 -2 1 1 8 45 13 96 12 98 6 271 -11 329 -9 30 -26 51 -40 49 -3 -1 -13 -3 -23 -6z"/>
    <path d="M1077 1074 c-14 -14 -8 -44 8 -38 25 10 61 -4 72 -28 10 -22 11 -22 19 -4 13 32 -16 71 -57 74 -19 2 -38 0 -42 -4z"/>
    <path d="M1076 1003 c-7 -26 -26 -170 -26 -204 0 -26 3 -29 34 -29 l34 0 7 88 c13 161 13 157 -17 160 -18 2 -28 -2 -32 -15z"/>
    <path d="M1432 847 c-12 -13 -22 -31 -22 -41 0 -16 2 -16 26 3 57 45 132 11 119 -55 -7 -38 8 -46 25 -15 18 34 12 80 -15 106 -33 34 -102 35 -133 2z"/>
    <path d="M4017 834 c-12 -13 -8 -44 7 -44 23 0 26 -24 26 -225 l0 -192 78 -7 c81 -7 121 1 156 32 71 63 75 255 6 292 -26 14 -70 16 -120 7 l-35 -7 0 73 0 72 -55 3 c-31 2 -59 0 -63 -4z m198 -198 c15 -11 20 -30 23 -85 2 -39 1 -82 -2 -96 -7 -27 -41 -41 -75 -30 -19 6 -21 14 -21 93 0 116 25 155 75 118z"/>
    <path d="M4380 815 c0 -15 6 -25 14 -25 23 0 26 -24 26 -202 0 -161 -1 -170 -20 -175 -11 -3 -20 -14 -20 -25 0 -17 7 -19 87 -16 90 3 119 19 69 38 l-26 9 -2 208 -3 208 -62 3 c-62 3 -63 2 -63 -23z"/>
    <path d="M1452 794 c-14 -10 -22 -25 -22 -45 0 -33 15 -39 23 -9 4 16 14 20 46 20 44 0 50 9 25 34 -20 20 -43 20 -72 0z"/> 
    <path d="M1016 729 c-16 -13 -26 -30 -26 -46 l0 -26 23 21 c35 33 69 37 101 11 19 -15 27 -30 26 -48 -3 -42 0 -47 15 -27 23 31 19 83 -10 111 -31 32 -91 34 -129 4z"/>
    <path d="M1466 561 c-4 -99 -5 -185 -1 -191 11 -18 45 -11 54 13 5 12 7 25 4 30 -2 4 0 56 5 115 6 59 7 130 4 157 -6 44 -10 50 -33 53 l-26 3 -7 -180z"/>
    <path d="M4697 696 c-76 -28 -107 -80 -107 -179 0 -78 26 -124 82 -145 47 -18 117 -9 154 20 82 65 85 251 5 293 -43 22 -93 26 -134 11z m59 -54 c36 -24 48 -157 18 -200 -19 -28 -49 -28 -68 0 -22 31 -22 170 0 192 17 17 33 20 50 8z"/>
    <path d="M2760 685 c0 -8 5 -15 10 -15 6 0 10 -42 10 -105 0 -63 -4 -105 -10 -105 -5 0 -10 -7 -10 -15 0 -11 12 -15 50 -15 36 0 50 4 50 14 0 8 -7 16 -15 20 -12 4 -15 27 -15 121 l0 115 -35 0 c-24 0 -35 -5 -35 -15z"/>
    <path d="M2963 693 c-19 -7 -16 -29 7 -44 17 -12 21 -29 26 -129 9 -157 -1 -246 -28 -253 -12 -4 -18 -12 -16 -24 3 -15 15 -18 91 -21 l87 -3 0 26 c0 20 -5 25 -25 25 -23 0 -25 4 -25 45 l0 45 43 0 c99 0 157 71 157 193 0 73 -17 119 -49 137 -25 13 -102 13 -138 -1 -19 -7 -29 -7 -31 0 -4 11 -76 14 -99 4z m202 -63 c14 -15 18 -37 18 -99 1 -88 -10 -111 -52 -111 -49 0 -53 10 -50 110 3 77 7 94 23 106 27 19 40 18 61 -6z"/>
    <path d="M3323 694 c-19 -8 -16 -27 7 -47 18 -15 20 -29 20 -119 0 -63 5 -113 13 -128 18 -36 72 -47 133 -27 39 13 51 13 68 2 29 -17 86 -2 86 24 0 10 -7 21 -15 25 -11 4 -15 32 -17 138 l-3 133 -34 3 c-50 5 -81 -5 -81 -27 0 -10 7 -21 15 -25 12 -4 15 -26 15 -104 0 -63 -4 -102 -12 -110 -16 -16 -55 -15 -65 1 -4 6 -10 68 -13 137 l-5 125 -50 2 c-27 1 -56 0 -62 -3z"/>
    <path d="M3784 690 c-32 -13 -72 -67 -80 -106 -12 -65 0 -151 25 -178 30 -31 105 -50 168 -40 65 9 85 21 81 47 -3 23 -5 24 -89 11 -40 -5 -50 -3 -68 15 -11 11 -21 32 -21 46 0 24 2 25 70 25 102 0 126 29 100 122 -6 21 -21 44 -33 53 -23 16 -117 20 -153 5z m95 -71 c23 -41 9 -59 -45 -59 -38 0 -45 3 -40 16 3 9 6 22 6 29 0 20 30 46 48 43 9 -2 23 -15 31 -29z"/>
    <path d="M1042 678 c-25 -12 -38 -49 -27 -75 6 -15 8 -15 12 -3 8 24 34 40 65 40 31 0 35 11 12 34 -18 18 -30 19 -62 4z"/>
    <path d="M615 615 c-33 -33 -33 -84 0 -122 l24 -28 -6 51 c-5 46 -3 53 18 68 27 19 62 21 91 5 30 -16 34 -2 8 26 -33 35 -101 35 -135 0z"/>
    <path d="M218 610 c-33 -26 -39 -57 -9 -40 32 17 86 12 105 -9 14 -16 16 -29 11 -63 l-7 -43 23 23 c31 29 32 93 3 123 -32 31 -93 36 -126 9z"/>
    <path d="M1060 588 c0 -18 5 -64 11 -103 6 -38 14 -127 18 -198 7 -127 16 -155 45 -144 13 5 16 26 16 114 0 112 -14 260 -31 326 -8 30 -14 37 -34 37 -22 0 -25 -5 -25 -32z"/>
    <path d="M1929 607 c-43 -34 -54 -122 -18 -158 27 -27 122 -24 127 4 3 15 -2 17 -37 11 -37 -6 -41 -4 -46 18 -4 14 -5 42 -3 64 3 38 4 39 43 39 29 0 40 4 40 15 0 19 -83 25 -106 7z"/>
    <path d="M2103 605 c-44 -31 -53 -116 -16 -157 20 -22 81 -24 110 -3 44 31 53 116 16 158 -20 21 -81 23 -110 2z m65 -27 c14 -14 16 -87 2 -108 -15 -23 -44 1 -48 40 -7 66 15 99 46 68z"/>
    <path d="M2260 609 c0 -4 4 -10 9 -13 18 -12 23 -95 7 -128 -9 -17 -16 -33 -16 -35 0 -1 23 -3 50 -3 38 0 50 4 50 15 0 8 -4 15 -10 15 -13 0 -13 117 0 125 23 14 39 -17 42 -83 l3 -67 33 -3 c23 -2 32 1 32 12 0 8 -4 18 -10 21 -5 3 -10 35 -10 69 0 39 -5 68 -12 74 -15 12 -168 13 -168 1z"/>
    <path d="M2607 602 c-42 -46 -44 -129 -4 -157 27 -19 111 -20 126 -2 16 20 2 28 -34 21 -23 -4 -37 -1 -48 11 -26 26 -20 35 22 35 50 0 73 22 65 58 -4 15 -11 33 -17 40 -15 19 -91 15 -110 -6z m71 -40 c3 -18 -1 -22 -22 -22 -27 0 -31 7 -20 35 9 23 38 14 42 -13z"/>
    <path d="M662 568 c-7 -7 -12 -19 -12 -28 0 -12 8 -15 35 -12 25 3 42 -1 55 -13 19 -18 20 -17 20 7 0 46 -66 78 -98 46z"/>
    <path d="M212 548 c-7 -7 -12 -22 -12 -35 0 -23 1 -23 25 -7 17 11 36 14 55 10 21 -4 30 -2 30 7 0 22 -23 37 -55 37 -17 0 -36 -5 -43 -12z"/>
    <path d="M666 501 c-3 -4 0 -50 6 -102 8 -72 8 -128 -1 -236 -13 -142 -11 -163 13 -163 30 0 43 37 55 158 10 97 10 148 0 238 l-12 114 -28 0 c-15 0 -30 -4 -33 -9z"/>
    <path d="M233 485 c-3 -9 -4 -71 -1 -138 3 -100 8 -129 24 -157 22 -39 51 -50 61 -24 3 9 0 45 -7 80 -6 35 -12 100 -11 143 1 43 -2 86 -5 95 -8 20 -53 21 -61 1z"/>
  </g>
</svg>
  ),

  default: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlns="http://www.w3.org/1999/xlink"
      viewBox="0 0 365.442 365.442"
      style="enable-background:new 0 0 365.442 365.442;"
    >
      <path d="M212.994,274.074h-68.522c-3.042,0-5.708,1.149-7.992,3.429c-2.286,2.286-3.427,4.948-3.427,7.994v68.525    c0,3.046,1.145,5.712,3.427,7.994c2.284,2.279,4.947,3.426,7.992,3.426h68.522c3.042,0,5.715-1.144,7.99-3.426    c2.29-2.282,3.433-4.948,3.433-7.994v-68.525c0-3.046-1.14-5.708-3.433-7.994C218.709,275.217,216.036,274.074,212.994,274.074z" />
      <path d="M302.935,68.951c-7.806-14.378-17.891-26.506-30.266-36.406c-12.367-9.896-26.271-17.799-41.685-23.697    C215.567,2.952,200.246,0,185.016,0C127.157,0,83,25.315,52.544,75.946c-1.521,2.473-2.046,5.137-1.571,7.993    c0.478,2.852,1.953,5.232,4.427,7.135l46.824,35.691c2.474,1.52,4.854,2.281,7.139,2.281c3.427,0,6.375-1.525,8.852-4.57    c13.702-17.128,23.887-28.072,30.548-32.833c8.186-5.518,18.461-8.276,30.833-8.276c11.61,0,21.838,3.046,30.692,9.132    c8.85,6.092,13.271,13.135,13.271,21.129c0,8.942-2.375,16.178-7.135,21.698c-4.757,5.518-12.754,10.845-23.986,15.986    c-14.842,6.661-28.457,16.988-40.823,30.978c-12.375,13.991-18.558,28.885-18.558,44.682v12.847c0,3.62,0.994,7.187,2.996,10.708    c2,3.524,4.425,5.283,7.282,5.283h68.521c3.046,0,5.708-1.472,7.994-4.432c2.279-2.942,3.426-6.036,3.426-9.267    c0-4.757,2.617-11.14,7.847-19.13c5.235-7.994,11.752-14.186,19.562-18.565c7.419-4.186,13.219-7.56,17.411-10.133    c4.196-2.566,9.664-6.715,16.423-12.421c6.756-5.712,11.991-11.375,15.698-16.988c3.713-5.614,7.046-12.896,9.996-21.844    c2.956-8.945,4.428-18.558,4.428-28.835C314.639,98.397,310.734,83.314,302.935,68.951z" />
    </svg>
  )
};
export default svgs;
