<template>
  <main>
    <Trail />
   
    <Container class="container--main">
      <Row>
        <ColumnUi :md="three ? 9 : 12">
          <Title tag="h1">{{ title }}</Title>
          <Content
            v-if="singleTypeProjects.content"
            :content="singleTypeProjects.content"
          />
          <Paginacion
            :datosTmp="datosTmp"
            @passPaginado="passPaginado"
            sustantivo="Servicios webs"
            textoNulo="No tenemos servicios webs disponibles, Tan pronto se levanten los servicios, se estarán publicando."
          >
            <template v-slot:datosViwer>
             <div style="overflow:auto">
                <TableUi>
                <template v-slot:header>
                  <tr>
                    <th :key="i" v-for="(cabeza, i) of cabecera">
                      {{ cabeza.label }}
                    </th>
                  </tr>
                </template>
                <template v-slot:body>
                  <tr :key="i" v-for="(dato, i) in datos">
                    <td>
                      {{ dato.tema }}
                    </td>
                    <td>
                      {{ dato.titulo }}
                    </td>
                    <td>
                      {{ dato.fecha }}
                    </td>
                    <td>
                      <Button
                        v-if="dato.wfs"
                        tag="button"
                        theme="primary"
                        @click="copiar(dato.wfs)"
                        v-on:click="copyText('WFS',dato.wfs)"
                      >
                        Ver dirección
                      </Button>
                    </td>
                    <td>
                      <Button
                        v-if="dato.wms"
                        tag="button"
                        theme="primary"
                        @click="copiar(dato.wms)"
                        v-on:click="copyText('WMS',dato.wms)"
                      >
                        Ver dirección
                      </Button>
                     
                    </td>
                  </tr>
                </template>
              </TableUi>
             </div>
            </template>
          </Paginacion>
        </ColumnUi>
        <ColumnUi md="3" v-if="three">
          <EnEstaSeccion :three="three"></EnEstaSeccion>
        </ColumnUi>
      </Row>
    </Container>
   
    <Modal :showModal="showModal" centered v-on:close="close">
      <template v-slot:header>
        <h5>Descripción del servicio</h5>
      </template>
      <template v-slot:body>
        <p><b>Tipo de servicio: </b> {{ message.WebserviceType }} </p>
        <div class="msg_code">
          <code>{{ message.WebserviceURL }}</code>
        </div>
        <p class="msg__footer">(Copie y pegue el URL en su GIS Desktop o visor de mapas)</p>
      </template>
    </Modal>


  </main>
</template>
<script>
import { getProjects } from "@/projects.js";
import { Cabecera, Datos } from "@/tablaWebService.js";
import { useRoute } from "vue-router";
import Container from "@/components/ui/objects/Container.vue";
import Title from "@/components/ui/atoms/Title.vue";
import Trail from "@/components/common/Trail.vue";
import Button from "@/components/ui/atoms/Button.vue";
import TableUi from "@/components/ui/objects/TableUi.vue";
import Modal from "@/components/common/Modal.vue";
import Content from "../components/common/Content.vue";
import Row from "../components/ui/atoms/Row.vue";
import ColumnUi from "../components/ui/atoms/ColumnUi.vue";
/* import List from '../components/ui/objects/List.vue'; */
/* import Input from "@/components/ui/atoms/Input" */
import { getSection } from "@/interface-data.js";
import EnEstaSeccion from "@/components/ui/objects/EnEstaSeccion";
import Paginacion from "@/components/ui/objects/Paginacion";

import { reactive, ref, watch } from "vue";

export default {
  components: {
    Trail,
    Container,
    /*    Input, */
    TableUi,
    Title,
    Button,
    EnEstaSeccion,
    Content,
    Row,
    ColumnUi,
    Modal,
    Paginacion
  },
  emits: ["setOverlay"],
  setup(props, { emit }) {
    const WebserviceURL = ref("http://localhost/demo.component")
    const filter = ref("");
    const type = useRoute()
      .path.split("/")
      .slice(-1)[0];
    const allProjects = getProjects(process.env.VUE_APP_PREFIX);
    const singleTypeProjects = allProjects.find(
      projects => projects.slug == type
    );
    const title =
      (singleTypeProjects && singleTypeProjects.type) ||
      `No se encontraron los proyectos ${type}`;
    const projectTypes = [];
    allProjects.forEach(e => {
      projectTypes.push({ slug: e.slug, title: e.type });
    });
    let ServiceURL = ref('');
    let showModal = ref(false);
    let showProject = reactive({ data: {} });
    const openModal = project => {
      showModal.value = true;
      showProject.data = project;
    };
    const close = () => {
      showModal.value = false;
    };

    let filteredProjects =
      (singleTypeProjects && reactive({ data: singleTypeProjects.data })) || [];
    const getQuery = query => {
      filter.value = query;
      filterProjects(query);
    };
    function filterProjects(query) {
      filteredProjects.data = singleTypeProjects.data.filter(project =>
        project.title.toLowerCase().includes(query.toLowerCase())
      );
    }

    watch(
      showModal, () => {
        emit("setOverlay", showModal.value);
      },
    );
    const view = useRoute().fullPath;
    const three = getSection(process.env.VUE_APP_PREFIX, view);

    const cabecera = Cabecera();
    const datosTmp = Datos();
    let datos = ref([]);
    const passPaginado = paginado => {
      datos.value = paginado;
    };
    const copiar = dato => {
      //let value = dato
      var tempInput = document.createElement("input");
      tempInput.value = dato;
      document.body.appendChild(tempInput);
      tempInput.select();
      document.execCommand("copy");
      document.body.removeChild(tempInput);
      showModal.value = true;
      ServiceURL.value = "http://localhost"
    };
   
    return {
      title,
      filteredProjects,
      showModal,
      showProject,
      openModal,
      close,
      projectTypes,
      type,
      getQuery,
      singleTypeProjects,
      three,
      datos,
      cabecera,
      datosTmp,
      passPaginado,
      copiar,
      WebserviceURL,
      message: {
        WebserviceType: 'Type',
        WebserviceURL: '',
      }
    };
    
  },
  methods:{
     copyText(service,url) {
      this.message.WebserviceType = service;
      this.message.WebserviceURL = url;
    },
  },
}
</script>

<style>
.msg_code{
  margin-bottom: 1rem;
  padding: .8rem;
  background: #d8d8d8;
}
</style>

