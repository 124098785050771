<template>
    <div class="card">
        <Row>
           <ColumnUi md="12" class="card__info">
            <Row>
              <ColumnUi sm="8" md="8">
                <div class="card__text">
                  <p>{{ name }}</p>

                </div>
              </ColumnUi>
              <ColumnUi sm="4" md="4">
                <div class="card__icon">
                  <Icon svg="tematicas" class="card_icon" />
                </div>
              </ColumnUi>
            </Row>
           </ColumnUi>
          <ColumnUi md="12" class="card__actions">
            <div class="card__button"><slot></slot></div>
          </ColumnUi>
        </Row>
    </div>
    
</template>
<script>
import Icon from "@/components/ui/atoms/Icon";
import Row from "@/components/ui/atoms/Row";
import ColumnUi from "@/components/ui/atoms/ColumnUi";

export default {
    props:{
        name: String
    },
    components: {
        Icon,
        Row,
        ColumnUi
    },
}
</script>



<style lang="scss" scoped>
@use "@/assets/styles/settings/_variables.scss";
.card{
    border: 0.5px solid #dbdbdb;
    border-radius: 5px;
    padding: 20px;
    height: 100%;
    position: relative;

    &__text p{
        font-size: 1rem;
        color: variables.$primary-color;
        font-weight: bold;
    }
}

.card__actions{
  position: absolute;
  bottom: 15px;
}


.card__info{
  margin-bottom: 25px;
}

.card__icon{
    display: flex;
    justify-content: center;
    align-self: center;
}
.card_icon{
  width: 60px;
  height: 60px;
    color: variables.$primary-color;
}
</style>