<template>
   <div class="featured">
        <div class="featured__title">
            <p>Destacados</p>
        </div>
        <div class="featured__icon">
            <a href="https://www.igp.gob.pe/servicios/infraestructura-de-datos-espaciales/#section_featured" ><Icon svg="featured" class="icon_ftred" /></a>
        </div>
   </div>
</template>

<script>
import Icon from "@/components/ui/atoms/Icon";

export default {
    components: {
        Icon
    },
}
</script>


<style lang="scss" scoped>
.featured{
    position: fixed;
    display: flex;
    bottom: 0;
    right: 10px;
    margin-bottom: 10px;
    color: white;

    &__icon{
        background: #0000af;
        width: 60px;
        height: 60px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        
    }
    &__title{
        display: flex;
        justify-content: center;
        align-items: center;
    }
    &__title p{
            background: #0000af;
        margin: 0;
        margin-right: 10px;
        min-width: 110px;
        text-align: center;
        border-radius: 20px;
        font-size: 1rem;
    }
    &__title a{
        color: white;
    }

    .icon_ftred{
        color: white;
        width: 35px;
        height: 35px;
        max-width: 3rem;
        max-height: 3rem;
        margin: auto;
        margin-top:10px;
        
    }

}
</style>