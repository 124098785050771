<template>
  <component
    :is="tag"
    :class="`btn btn--${theme} ${(block && 'btn--block') || ''}`"
  >
    <slot />
  </component>
</template>

<script>
export default {
  props: {
    tag: {
      type: String,
      default: "a",
      validator(value) {
        return ["a", "button", "router-link"].includes(value);
      }
    },
    theme: {
      type: String,
      default: "default",
      validator(value) {
        const themes = ["primary", "secondary", "inverted", "default"];
        return themes.includes(value);
      }
    },
    block: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style lang="scss" scoped>
@use "@/assets/styles/settings/_variables.scss";
.btn {
  display: inline-block;
  padding: 0.375rem 0.75rem;
  font-size: variables.$fs-btn;
  text-align: center;
  text-decoration: none;
  border: none;
  border-radius: 0.1rem;
  white-space: nowrap;
  cursor: pointer;
  transition: all 0.2s;
  line-height: 1.3em;
  outline: 0;
  &--block {
    display: block;
    width: 100%;
  }
  &--primary {
    color: variables.$primary-color;
    background: transparent;
    border: 2px solid variables.$primary-color;
     -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none; 
    &:hover,
    &:focus {
      color: variables.$text-inverted-color;
      background: variables.$primary-color;
    }
  }
  &--inverted {
     -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none; 
    color: variables.$text-inverted-color;
    background: variables.$background-btn-color;
    border: 2px solid variables.$border-btn-inverted-color;
    &:hover,
    &:focus {
      border: 2px solid variables.$border-btn-inverted-h-color;
      background: variables.$background-btn-inverted-color;
    }
  }
  & + .btn {
    margin-left: 1em;
  }
}
.disabled{
  color: variables.$border-color;
  background: transparent;
  border: 2px solid variables.$border-color;
  cursor: default;
}
.disabled:hover{
  color: variables.$border-color;
  background: transparent;
  border: 2px solid variables.$border-color;
  cursor: default;
}
</style>
