<template>
  <li class="menu">
    <a
      v-if="!menu.path"
      :class="[{ active: String($route.fullPath) == menu.path }, 'menu__item']"
    >
      {{ menu.tag }}
      <Icon svg="thinAngleRight" class="svg-icon svg-icon--menu" />
    </a>
    <a
      v-else-if="menu.path.includes('http')"
      target="_blank"
      :href="menu.path"
      class="menu__item"
    >
      {{ menu.tag }}
    </a>
    <router-link
      v-else
      :to="menu.path"
      :class="[{ active: String($route.fullPath) == menu.path }, 'menu__item']"
    >
      {{ menu.tag }}
      <Icon
        v-if="hasChildren"
        svg="thinAngleRight"
        class="svg-icon svg-icon--menu"
      />
    </router-link>
    <ul v-if="hasChildren" class="submenu">
      <Menu
        v-for="(child, index) in menu.children"
        :key="index"
        :menu="child"
      ></Menu>
    </ul>
  </li>
</template>

<script>
import { computed } from "vue";
import Icon from "./Icon.vue";
export default {
  components: { Icon },
  name: "Menu",
  props: {
    menu: {
      type: Object,
      default: () => {}
    }
  },
  setup(props) {
    const hasChildren = computed(() => {
      return props.menu.children && props.menu.children.length;
    });

    /*const hasGrandChildren = computed(() => {
      return props.menu.children && props.menu.children.length && props.menu.children.find( children => children.children && children.children.length );
    });
    console.log(hasGrandChildren); */
    return {
      hasChildren
    };
  }
};
</script>
<style lang="scss" scoped>
@use "@/assets/styles/settings/_variables.scss";
.menu {
  width: 100%;
  &__item {
    display: block;
    padding-left: 3rem;
    padding-right: 3rem;
    line-height: 50px;
    text-decoration: none;
    color: variables.$text-header-color;
    border-left: 5px solid transparent;
    &:hover {
      color: variables.$text-header-hover-color;
    }
    &.active {
      color: variables.$text-header-hover-color;
      border-left: 5px solid variables.$primary-color;
      background-color: variables.$background-lighter-color;
    }
  }
  .submenu {
    padding: 0;
    font-size: 90%;
    .menu__item {
      line-height: 2em;
      padding-top: 0.5em;
      padding-bottom: 0.5em;
      padding-left: 4.5rem;
    }
    .submenu {
      .menu__item {
        padding-left: 6rem;
      }
    }
  }
  .svg-icon {
    &--menu {
      transition: transform 0.2s;
      height: 0.7em;
      margin: 0 0 0 5px;
    }
    transform: rotate(90deg);
  }
}
@media (min-width: 1200px) {
  .menu {
    width: auto;
    &__item {
      padding-left: 0.5em;
      padding-right: 0.5em;
      line-height: 66px;
      border-left: none;
      &.active {
        border-left: none;
        background-color: variables.$background-header-color;
      }
    }
    & > .submenu {
      max-width: 300px;
      position: absolute;
      padding: 0;
      border-top: 2px solid variables.$primary-color;
      background-color: variables.$background-header-color;
      pointer-events: none;
      list-style: none;
      opacity: 0;
      transform: translateY(10px);
      transition: transform 0.2s, opacity 0.2s;
      &:before {
        content: "";
        border-bottom: 10px solid variables.$primary-color;
        border-right: 7px solid transparent;
        border-left: 7px solid transparent;
        left: 2em;
        top: -12px;
        position: absolute;
        z-index: 0;
      }
      .menu {
        position: relative;
        .submenu {
          transition: transform 0.3s, opacity 0.3s;
          transform: translateX(calc(-100% - 10px));
          border-top: 1px solid variables.$border-header-color;
          /*border-right: 1px solid variables.$border-header-color; */
          top: -1px;
          z-index: 1;
          &:before {
            border-left: 10px solid variables.$border-header-color;
            border-top: 7px solid transparent;
            border-bottom: 7px solid transparent;
            right: -17px;
            top: 19px;
            left: initial;
            position: absolute;
          }
          .menu__item {
            padding-left: 1em;
          }
        }
        &:hover {
          .submenu {
            transform: translateX(calc(-100% + 1px));
          }
        }
      }
      .menu__item {
        padding: 1em;
        margin: 0;
        border-right: 1px solid variables.$border-header-color;
        border-left: 1px solid variables.$border-header-color;
        border-bottom: 1px solid variables.$border-header-color;
      }
    }
    &:hover {
      & > .submenu {
        pointer-events: auto;
        opacity: 1;
        transform: translateY(0px);
      }
      & > .menu__item > .svg-icon--menu {
        transform: rotate(90deg);
      }
    }
    .svg-icon {
      transform: rotate(0deg);
    }
  }
}
</style>
