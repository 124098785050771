<template>
    <div class="tag">
        <slot></slot>
    </div>
</template>

<script>

</script>

<style lang="scss" scoped>
    @use "@/assets/styles/settings/_variables.scss";
.tag {
    font-size: 0.75rem;
    font-weight: 500;
    letter-spacing: 0.025rem;
    font-style: normal;
    color: #fff;
    width: 45%;
    background-color: variables.$primary-color;
    padding: 0.15rem 0.75rem;
    border-style: solid;
    border-width: 0.125rem;
    border-color: variables.$primary-color;
    margin-bottom: 0.95rem;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    -box-shadow: none;
}
</style>