<template>
  <main>
    <Trail />
    <Container class="container--main">
      <Row>
        <ColumnUi :md="three ? 9 : 12">
          <Title tag="h1">{{ title }}</Title>
          <Content
            v-if="singleTypeProjects.content"
            :content="singleTypeProjects.content"
          />
          <Input
            label="Buscar"
            type="text"
            @getValue="getQuery"
            v-if="filteredProjects && filteredProjects.data"
          />
          <TableUi v-if="filteredProjects && filteredProjects.data">
            <template v-slot:header>
              <tr>
                <th>
                  NORMATIVA RELACIONADA A LA INFRAESTRUCTURA DE DATOS ESPACIALES
                </th>
                <th></th>
              </tr>
            </template>
            <template v-slot:body>
              <tr
                v-for="(project, index) in filteredProjects.data"
                :key="index"
              >
                <td>
                  <Content :content="project.content" />
                </td>
                <td>
                  <Button
                    tag="a"
                    theme="primary"
                    target="_blank"
                    :href="project.link"
                  >
                    Descargar
                  </Button>
                </td>
              </tr>
            </template>
          </TableUi>
          <p v-else>
            No se encontraron proyectos
          </p>
        </ColumnUi>
        <ColumnUi md="3" v-if="three">
          <EnEstaSeccion :three="three"></EnEstaSeccion>
        </ColumnUi>
      </Row>
    </Container>
  </main>
</template>
<script>
import { getProjects } from "@/projects.js";
import { useRoute } from "vue-router";
import Container from "@/components/ui/objects/Container.vue";
import Title from "@/components/ui/atoms/Title.vue";
import Trail from "@/components/common/Trail.vue";
import Button from "@/components/ui/atoms/Button.vue";
import TableUi from "@/components/ui/objects/TableUi.vue";
/* import Modal from "@/components/common/Modal.vue"; */
import Content from "../components/common/Content.vue";
import Row from "../components/ui/atoms/Row.vue";
import ColumnUi from "../components/ui/atoms/ColumnUi.vue";
/* import List from '../components/ui/objects/List.vue'; */
import Input from "@/components/ui/atoms/Input";
import { getSection } from "@/interface-data.js";
import EnEstaSeccion from "@/components/ui/objects/EnEstaSeccion";

import { reactive, ref, watch } from "vue";

export default {
  components: {
    Trail,
    Container,
    Input,
    TableUi,
    Title,
    Button,
    EnEstaSeccion,
    Content,
    Row,
    ColumnUi
    /*         List, */
  },
  emits: ["setOverlay"],
  setup(props, { emit }) {
    const filter = ref("");
    const type = useRoute()
      .path.split("/")
      .slice(-1)[0];
    const allProjects = getProjects(process.env.VUE_APP_PREFIX);
    const singleTypeProjects = allProjects.find(
      projects => projects.slug == type
    );
    const title =
      (singleTypeProjects && singleTypeProjects.type) ||
      `No se encontraron los proyectos ${type}`;
    const projectTypes = [];
    allProjects.forEach(e => {
      projectTypes.push({ slug: e.slug, title: e.type });
    });
    let showModal = ref(false);
    let showProject = reactive({ data: {} });
    const openModal = project => {
      showModal.value = true;
      showProject.data = project;
    };
    const close = () => {
      showModal.value = false;
    };

    let filteredProjects =
      (singleTypeProjects && reactive({ data: singleTypeProjects.data })) || [];
    // console.log(filteredProjects.content);
    const getQuery = query => {
      filter.value = query;
      filterProjects(query);
    };
    function filterProjects(query) {
      filteredProjects.data = singleTypeProjects.data.filter(project =>
        project.title.toLowerCase().includes(query.toLowerCase())
      );
    }

    watch(showModal, () => {
      emit("setOverlay", showModal.value);
    });
    const view = useRoute().fullPath;
    const three = getSection(process.env.VUE_APP_PREFIX, view);

    return {
      title,
      filteredProjects,
      showModal,
      showProject,
      openModal,
      close,
      projectTypes,
      type,
      getQuery,
      singleTypeProjects,
      three
    };
  }
};
</script>
