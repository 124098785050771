<script>
import { h } from "vue";
export default {
  props: {
    content: {
      type: Function,
      default: () => {}
    }
  },
  setup(props) {
    return () => h(props.content, {});
  }
};
</script>
