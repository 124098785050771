<template>
  <Suspense>
    <template #default>
      <PublicationsWrapper
        :collection_id="collection_id"
        :collection_handle="collection_handle"
      />
    </template>
    <template #fallback>
      <Loader />
    </template>
  </Suspense>
</template>
<script>
import { defineAsyncComponent } from "vue";
import Loader from "@/components/common/Loader";
export default {
  props: {
    collection_id: {
      type: String,
      default: ""
    },
    collection_handle: {
      type: String,
      default: ""
    }
  },
  components: {
    Loader,
    PublicationsWrapper: defineAsyncComponent(() =>
      import("./PublicationsWrapper")
    )
  }
};
</script>
