const projects = {
  cts: [
    {
      type: "Proyectos de Desarrollo Tecnológico",
      slug: "tecnologico",
      data: [
        {
          title:
            "Sistema de previsión de eventos hidrológicos extremos estacionales en la cuenca amazónica peruana",
          abstract: () => [
            <p>
              Entre 2014 y 2017, con el financiamiento de Innovate-Perú, -en
              colaboración con la ANA, IRD de Francia y SENAMHI- se desarrolló
              el proyecto “Sistema de previsión de eventos hidrológicos extremos
              estacionales en la cuenca amazónica peruana”, el cual tuvo como
              objetivo establecer bases científicas para elaborar un sistema de
              previsión de los eventos hidrológicos extremos en los grandes ríos
              de la Amazonía peruana. Los resultados llevaron a la sustentación
              de 3 tesis de pregrado, 1 tesis de maestría y 1 tesis de
              doctorado. Derivó también en la publicación como primer autor de
              al menos 7 artículos de investigación en revistas indexadas; estos
              son sustento técnico y científico del estudio de los eventos
              hidrológicos extremos en la Amazonía peruana, que provee reportes
              técnicos para su previsión mediante la plataforma web{" "}
              <a href="http://intranet.igp.gob.pe/eventos-extremos-amazonia-peruana/">
                http://intranet.igp.gob.pe/eventos-extremos-amazonia-peruana/
              </a>
            </p>
          ],
          team: [
            "Jhan Carlo Espinoza",
            "James Apaestegui",
            "Ricardo Zubieta",
            "Hans Segura",
            "Laura Paccini",
            "Omar Gutierrez"
          ]
        },
        {
          title:
            "Evaluación Geofísica del comportamiento dinámico de suelos de la zona del acantilado de la Costa Verde: Distrito de Magdalena",
          leaders: ["Isabel Bernal", "Kelly Pari"],
          abstract: () => [
            <p>
              Uno de los factores que contribuyen a los desastres en ciudades
              producto de la ocurrencia de sismos, son las características
              físicas de los suelos, pudiendo ser conocidas con la aplicación de
              métodos geofísicos. En este proyecto se realizaría la evaluación
              geofísica del comportamiento dinámico de suelos en la zona del
              acantilado, específicamente en el distrito de Magdalena. La
              estabilidad de los suelos será evaluada con la aplicación de
              métodos de refracción sísmica, tomografía eléctrica, georadar,
              gravimetría y razones espectrales.
            </p>
          ],
          team: [
            "Wilfredo Sulla",
            "Liliana Torres",
            "Fabiola Rosado",
            "Luz Arredondo",
            "Javier Oyola",
            "Jorge Salas",
            "José Millones",
            "Henry Salas",
            "Jesús Huarachi"
          ],
          support: ["Robert Yupanqui", "Augusto Cárdenas"],
          management: ["Marisol Enríquez"]
        },
        {
          title:
            "Evaluación de los niveles de sacudimiento del suelo en Lima Metropolitana y el Callao debido a la ocurrencia de sismos asociados a la convergencia de placas",
          leaders: ["Isabel Bernal", "Luz Arredondo"],
          abstract: () => [
            <p>
              El nivel de sacudimiento del suelo percibido en superficie debido
              a la ocurrencia de un sismo, depende principalmente de la
              proximidad a la fuente del sismo y de las características físicas
              del suelo por donde se propagan las ondas sísmicas. Por lo tanto,
              las condiciones locales de sitio, es uno de los principales
              factores responsables de los daños que se produce en cualquier
              tipo de edificación durante la ocurrencia de sismos severos. La
              reciente ocurrencia de sismos de moderada magnitud frente al borde
              occidental de la región lima, y el gran número de estaciones
              acelerométricas instaladas en campo, permiten contar con
              información para conocer el comportamiento dinámico del suelo ante
              la ocurrencia de sismos y proceder con su zonificación sísmica.
            </p>
          ],
          team: [
            "Luz Arredondo",
            "Wilfredo Suya",
            "Jorge Salas",
            "José Millones",
            "Henry Salas"
          ],
          management: ["Marisol Enríquez"]
        },
        {
          title:
            "Evaluación geodinámica, geofísica y geológica de los deslizamientos de tierra que afectan la seguridad física de la ciudad Huancabamba-Piura (Convenio 166-2017)",
          leaders: ["Juan Carlos Villegas Lanza"],
          abstract: () => [
            <p>
              Los deslizamientos de tierra ocurren con mayor frecuencia que
              cualquier otro fenómeno geológico. El Perú por su ubicación
              geográfica, características geomorfológicas y climáticas,
              dominadas por el proceso de subducción, la orogenia de la
              cordillera de los Andes y procesos hidrológicos (precipitaciones
              intensas y el fenómeno El Niño), dan lugar a la ocurrencia de
              movimientos en masa a lo largo de la Cordillera Andina, que
              afectan a los medios de vida e infraestructura de centros poblados
              que se ubican muy próximos o sobre dichos movimientos en masa. La
              ciudad de Huancabamba ubicada en el departamento de Piura,
              conocida como la “ciudad que se mueve”, se encuentra afectada en
              el 50% de su extensión por el cruce de 2 masas de material
              reptante orientadas en dirección perpendicular que se dirigen en
              direcciones casi perpendiculares; la primera en dirección SO hacia
              el río Huancabamba y la otra en dirección SE hacia la quebrada
              Lungulo. Por otro lado, las intensas precipitaciones
              características del norte del país, a veces asociadas con el
              Fenómeno del Niño, sumadas a la ocurrencia de sismos, incrementan
              la probabilidad de activación de los deslizamientos. Dado que
              existe poca información que documente las características,
              extensión y factores que controlan los fenómenos de reptación en
              dicha ciudad, este proyecto plantea realizar la evaluación
              geodinámica, geofísica y geológica de los movimientos en masa que
              afectan a dicha ciudad, con el fin de caracterizar el peligro
              natural. Este proyecto reúne a especialistas de diferentes líneas
              de investigación del IGP que incluyen Geodesia, Geodinámica,
              Ingeniería y Geofísica Aplicada, quienes desde el año 2018 vienen
              realizando trabajos interdisciplinarios a fin de caracterizar el
              peligro por movimientos en masa en Huancabamba.
            </p>,
            <p>
              Este proyecto es co-financiado por Concytec y tiene un periodo de
              duración de 3 años, siendo el 2020 el último. En esta última fase
              del proyecto que se divide en 2 hitos se llevará a cabo el
              análisis de la información geodésica de la 1ra y 2da campaña de
              medición de los puntos de control GPS. Asimismo, se llevará a cabo
              el análisis de imágenes satelitales a fin de evaluar la evolución
              histórica de los movimientos en masa e identificar las zonas de
              mayor deformación superficial. Finalmente, en la última etapa se
              llevará a cabo la correlación e interpretación de resultados a fin
              de generar un informe final del proyecto. En el marco del proyecto
              se tiene previsto también la realización de 2 tesis de grado, las
              cuales deben completarse y sustentarse antes del cierre del
              proyecto.
            </p>
          ],
          team: [
            "Juan Carlos Gómez Avalos",
            "Isabel Bernal Esquía",
            "Wendy Quiroz Sifuentes",
            "Liliana Torres",
            "Fabiola Rosado"
          ]
        }
      ]
    },
    {
      type: "Proyectos de Investigación",
      slug: "investigacion",
      data: [
        {
          title:
            "Zonificación geofísica de suelos de las localidades de El Alto y los órganos en Piura; Caleta, Cruz, Corrales, Tumbes, Zaramulla y Aguas Verdes en Tumbes",
          abstract: () => [
            <p>
              La evaluación de las características físicas del subsuelo,
              permiten conocer su comportamiento dinámico al paso de las ondas
              sísmicas; es decir, los suelos responden de acuerdo a sus
              condiciones locales, pudiendo estos modificar su contenido
              frecuencial y/o generar amplificaciones en las señales sísmicas.
              En este sentido es importante conocer las características físicas
              de los suelos a partir de la obtención de datos de campo usando
              técnicas geofísicas como la tomografía sísmica, eléctrica,
              georadar y razones espectrales, durante el año 2020, los estudios
              serán realizados en la región Piura (el Alto, Los Órganos, Caleta,
              Cruz, Corrales) y Tumbes (Tumbes, Zarumilla y Aguas Verdes).
            </p>
          ],
          leaders: ["Isabel Bernal", "Wilfredo Sulla"],
          team: [
            "Kelly Pari",
            "Liliana Torres",
            "Fabiola Rosado",
            "Luz Arredondo",
            "Javier Oyola",
            "Jorge Salas",
            "José Millones",
            "Henry Salas",
            "Jesús Huarachi"
          ],
          support: ["Robert Yupanqui", "Augusto Cárdenas"],
          management: ["Marisol Enríquez"]
        },
        {
          title:
            "Evaluación Geofísica del comportamiento dinámico de suelos de la zona del acantilado de la Costa Verde: Distrito de Magdalena",
          abstract: () => [
            <p>
              Uno de los factores que contribuyen a los desastres en ciudades
              producto de la ocurrencia de sismos, son las características
              físicas de los suelos, pudiendo ser conocidas con la aplicación de
              métodos geofísicos. En este proyecto se realizaría la evaluación
              geofísica del comportamiento dinámico de suelos en la zona del
              acantilado, específicamente en el distrito de Magdalena. La
              estabilidad de los suelos será evaluada con la aplicación de
              métodos de refracción sísmica, tomografía eléctrica, georadar,
              gravimetría y razones espectrales.
            </p>
          ],
          leaders: ["Isabel Bernal", "Kelly Pari"],
          team: [
            "Wilfredo Sulla",
            "Liliana Torres",
            "Fabiola Rosado",
            "Luz Arredondo",
            "Javier Oyola",
            "Jorge Salas",
            "José Millones",
            "Henry Salas",
            "Jesús Huarachi"
          ],
          support: ["Robert Yupanqui", "Augusto Cárdenas"],
          management: ["Marisol Enríquez"]
        },
        {
          title:
            "Evaluación de los niveles de sacudimiento del suelo en Lima Metropolitana y el Callao debido a la ocurrencia de sismos asociados a la convergencia de placas",
          abstract: () => [
            <p>
              El nivel de sacudimiento del suelo percibido en superficie debido
              a la ocurrencia de un sismo, depende principalmente de la
              proximidad a la fuente del sismo y de las características físicas
              del suelo por donde se propagan las ondas sísmicas. Por lo tanto,
              las condiciones locales de sitio, es uno de los principales
              factores responsables de los daños que se produce en cualquier
              tipo de edificación durante la ocurrencia de sismos severos. La
              reciente ocurrencia de sismos de moderada magnitud frente al borde
              occidental de la región lima, y el gran número de estaciones
              acelerométricas instaladas en campo, permiten contar con
              información para conocer el comportamiento dinámico del suelo ante
              la ocurrencia de sismos y proceder con su zonificación sísmica.
            </p>
          ],
          leaders: ["Isabel Bernal", "Luz Arredondo"],
          team: [
            "Luz Arredondo",
            "Wilfredo Suya",
            "Jorge Salas",
            "José Millones",
            "Henry Salas"
          ],
          management: ["Marisol Enríquez"]
        },
        {
          title:
            "Caracterización Geológica y geodinámica de la Falla “El Angolo” provincia de Sullana departamento de Piura",
          leaders: ["Ing. Arturo Córdova"],
          abstract: () => [
            <p>
              En los últimos tiempos, brigadas de geólogos que realizan trabajo
              de campo en la zona manifiestan (comunicación verbal) haber
              identificado sistemas de neofracturas paralelas al eje de la falla
              El Angolo que se asocian a los terrenos próximos adyacentes a
              dicha falla. Por tanto, con fines de evaluar el actual
              comportamiento tectónico de la mencionada falla, es que el IGP
              desarrollará un plan de investigación con la finalidad de
              identificar evidencias de la reactivación neotectónica de la falla
              “El Angolo”. El estudio se enfoca a determinar las características
              y tipo de las deformaciones estructurales que ocurren en la zona
              de falla como son fracturamiento de la zona (neofracturas), rasgos
              geomorfológicos, comportamiento geodinámico de cobertura,
              erosionabilidad y otros aspectos que permitan determinar el
              comportamiento neotectónico, tratando de relacionar también el
              comportamiento sísmico de la región de Piura con posibles
              movimientos asociados a la Falla Angolo."
            </p>,
            <p>
              Por tanto, para el año 2020, se tiene como actividades de campo,
              la elaboración de un programa de campo tanto geológico como
              geofísico con ubicación de puntos de control geodésico que serán
              monumentos que permitan conocer el comportamiento neotectónico de
              la zona de la mencionada falla.
            </p>
          ],
          team: [
            "Ing. Segundo Ortiz",
            "Ing. Mariana Vivanco",
            "Augusto Cárdenas Ausejo"
          ]
        },
        {
          title:
            "Evaluación geodinámica, geofísica y geológica de los deslizamientos de tierra que afectan la seguridad física de la ciudad Huancabamba-Piura (Convenio 166-2017)",
          abstract: () => [
            <p>
              Los deslizamientos de tierra ocurren con mayor frecuencia que
              cualquier otro fenómeno geológico. El Perú por su ubicación
              geográfica, características geomorfológicas y climáticas,
              dominadas por el proceso de subducción, la orogenia de la
              cordillera de los Andes y procesos hidrológicos (precipitaciones
              intensas y el fenómeno El Niño), dan lugar a la ocurrencia de
              movimientos en masa a lo largo de la Cordillera Andina, que
              afectan a los medios de vida e infraestructura de centros poblados
              que se ubican muy próximos o sobre dichos movimientos en masa. La
              ciudad de Huancabamba ubicada en el departamento de Piura,
              conocida como la “ciudad que se mueve”, se encuentra afectada en
              el 50% de su extensión por el cruce de 2 masas de material
              reptante orientadas en dirección perpendicular que se dirigen en
              direcciones casi perpendiculares; la primera en dirección SO hacia
              el río Huancabamba y la otra en dirección SE hacia la quebrada
              Lungulo. Por otro lado, las intensas precipitaciones
              características del norte del país, a veces asociadas con el
              Fenómeno del Niño, sumadas a la ocurrencia de sismos, incrementan
              la probabilidad de activación de los deslizamientos. Dado que
              existe poca información que documente las características,
              extensión y factores que controlan los fenómenos de reptación en
              dicha ciudad, este proyecto plantea realizar la evaluación
              geodinámica, geofísica y geológica de los movimientos en masa que
              afectan a dicha ciudad, con el fin de caracterizar el peligro
              natural. Este proyecto reúne a especialistas de diferentes líneas
              de investigación del IGP que incluyen Geodesia, Geodinámica,
              Ingeniería y Geofísica Aplicada, quienes desde el año 2018 vienen
              realizando trabajos interdisciplinarios a fin de caracterizar el
              peligro por movimientos en masa en Huancabamba.
            </p>,
            <p>
              Este proyecto es co-financiado por Concytec y tiene un periodo de
              duración de 3 años, siendo el 2020 el último. En esta última fase
              del proyecto que se divide en 2 hitos se llevará a cabo el
              análisis de la información geodésica de la 1ra y 2da campaña de
              medición de los puntos de control GPS. Asimismo, se llevará a cabo
              el análisis de imágenes satelitales a fin de evaluar la evolución
              histórica de los movimientos en masa e identificar las zonas de
              mayor deformación superficial. Finalmente, en la última etapa se
              llevará a cabo la correlación e interpretación de resultados a fin
              de generar un informe final del proyecto. En el marco del proyecto
              se tiene previsto también la realización de 2 tesis de grado, las
              cuales deben completarse y sustentarse antes del cierre del
              proyecto.
            </p>
          ],
          leaders: ["Juan Carlos Villegas Lanza"],
          team: [
            "Juan Carlos Gómez Avalos",
            "Isabel Bernal Esquía",
            "Wendy Quiroz Sifuentes",
            "Liliana Torres",
            "Fabiola Rosado"
          ],
          support: [],
          management: []
        }
      ]
    }
  ],
  cga: [
    {
      type: "Proyectos de Investigación",
      slug: "investigacion",
      data: [
        {
          title:
            "Estudio de las capas E esporádicas tipo blanketing sobre el Radio Observatorio de Jicamarca",
          leaders: ["José Súclupe"],
          abstract: () => [
            <p>
              En esta tesis se ha desarrollado el primer estudio estadístico de
              la ocurrencia de las capas E esporádicas tipo blanketing (Esb)
              sobre el Radio Observatorio de Jicamarca usando ionogramas
              registrados entre el 2001 y 2018. Se ha desarrollado un algoritmo
              para la identificación automática de la ocurrencia de las capas
              Esb en regiones ecuatoriales. Finalmente se discute las posibles
              condiciones que favorecen su formación.
            </p>
          ]
        },
        {
          title:
            "Aplicación de técnicas de redes neuronales convolucionales para la detección de ecos de ionogramas",
          leaders: ["César De La Jara"],
          abstract: () => [
            <p>
              En este trabajo se propone utilizar un modelo de red neuronal
              convolucional para extraer trazos de capas ionosféricas de
              ionogramas digitales, obtenidos con las ionosondas VIPIR del
              proyecto LISN. Se comparan los resultados con los obtenidos con
              dos técnicas de línea base: procesamiento de imágenes y
              agrupamiento no supervisado.
            </p>
          ]
        },
        {
          title:
            "Estudio de efectos ionosféricos en las señales de radiofrecuencia mediante el análisis de datos de receptores GNSS",
          leaders: ["George Fajardo"],
          abstract: () => [
            <p>
              Este trabajo pretende utilizar información sobre lo que afecta el
              comportamiento ionosférico, desde el sol hasta la magnetosfera,
              combinado con enfoques de machine learning para correlacionar las
              perturbaciones de la señal GNSS con los fenómenos anteriormente
              mencionados en la zona ecuatorial magnética.
            </p>
          ]
        },
        {
          title:
            "Caracterización de la Capa Límite usando radares perfiladores de vientos en la Cuenca del Mantaro",
          leaders: ["Josep Prado"],
          abstract: () => [
            <p>
              El objetivo principal de este trabajo es desarrollar un análisis
              en el cual se logre caracterizar la estructura y evolución de la
              Capa Límite Atmosférica (CLA) sobre una zona con una topografía
              compleja como lo es el valle del Mantaro, (Perú), se pretende
              identificar los fenómenos meteorológicos que tienen influencia en
              dichos procesos.
            </p>,
            <p>
              Con este objetivo se realizan estimaciones de la altura de la Capa
              de Límite, basadas en la información de un ceilómetro, un Radar
              Perfilador de Vientos y capa límite y dos campañas de
              radiosondeos. Todos los instrumentos hacen parte del Laboratorio
              de Microfísica Atmosférica y Radiación (LAMAR).
            </p>
          ]
        },
        {
          title: "Implementación de Sistema de Radar Ionosonda en Red Pitaya",
          leaders: ["Fancisco Sulca"],
          abstract: () => [
            <p>
              Es un trabajo en el campo de la microelectrónica, que consiste en
              el diseño de un sistema embebido de radar para el estudio de la
              Ionósfera (partes de transmisión, recepción, almacenamiento de
              data, envío de data) que es implementado en una tarjeta de
              desarrollo SoC Zynq-7000 "Red Pitaya".
            </p>
          ]
        },
        {
          title:
            "Desarrollo de una interfaz software basado en GNU radio para controlar el sistema de adquisición",
          leaders: ["Isaac Túpac"],
          abstract: () => [
            <p>
              Esta tesis tiene como finalidad desarrollar una interfaz software
              de código abierto utilizando las herramientas que nos proporciona
              GNU Radio para lograr controlar el nuevo sistema de adquisición
              del Radio Observatorio de Jicamarca llamado JARS 2.0.
            </p>
          ]
        },
        {
          title: "Tomografía de la Ionósfera Ecuatorial",
          leaders: ["Juan Pablo Velásquez"],
          abstract: () => [
            <p>
              La Ionósfera es la capa superior de la atmósfera que comprende
              desde los 80 km hasta los 800 km. Está conformada por una mezcla
              de electrones y iones separados por la radiación solar. Al ponerse
              el sol, la dinámica de esta cambia y da cavida a la aparición de
              irregularidades en el ecuador magnético, teniendo muchos efectos
              adversos en los sistemas de navegación y en las
              telecomunicaciones. En este trabajo, se estudia la eficiencia de
              reconstrucción de estas irregularidades, variando el número de
              receptores y satélites. Utilizamos de dos familias muy populares
              de métodos de inversión: Métodos Algebraicos Iterativos y Métodos
              de Regularización.
            </p>
          ]
        }
      ]
    },
    {
      type: "Proyectos de Desarrollo Tecnológico",
      slug: "tecnologico",
      data: [
        {
          title:
            "Desarrollo de radar meteorológico para monitoreo de lluvias en la zona de Chosica, Lima",
          abstract: () => [
            <p>
              El objetivo principal del proyecto es la construcción de un radar
              meteorológico de banda X de doble polarización para medición de
              lluvias en la zona de Chosica con las siguientes características:
            </p>,
            <table>
              <tbody>
                <tr>
                  <td>
                    <b>Frecuencia</b>
                  </td>
                  <td>X-Band</td>
                </tr>
                <tr>
                  <td>
                    <b>Rango</b>
                  </td>
                  <td>50-60 km</td>
                </tr>
                <tr>
                  <td>
                    <b>Control de posición</b>
                  </td>
                  <td>Elevación y Azimut</td>
                </tr>
                <tr>
                  <td>
                    <b>Resolución</b>
                  </td>
                  <td>1.5 grados</td>
                </tr>
                <tr>
                  <td>
                    <b>Ganancia de la antena</b>
                  </td>
                  <td>38 dB</td>
                </tr>
                <tr>
                  <td>
                    <b>Polarización</b>
                  </td>
                  <td>Dual</td>
                </tr>
              </tbody>
            </table>
          ]
        },
        {
          title: "Desarrollo de radar SAR para monitoreo de deslizamientos",
          abstract: () => [
            <p>
              Existen lugares del país donde áreas urbanas son afectadas por
              deslizamientos de tierras. En muchos casos debido a las
              dificultades técnicas o al alto costo económico de tomar medidas
              preventivas, las personas se ven forzadas a coexistir con las
              condiciones de riesgo. El IGP ha desarrollado un sistema de manejo
              de riesgo de deslizamientos que permite predecir la evolución del
              mismo a corto plazo, utilizando un radar de apertura sintética
              para monitoreo permanente del deslizamiento.
            </p>,
            <table>
              <tbody>
                <tr>
                  <td>
                    <b>Frecuencia</b>
                  </td>
                  <td>X-Band</td>
                </tr>
                <tr>
                  <td>
                    <b>Rango</b>
                  </td>
                  <td>50-60 km</td>
                </tr>
                <tr>
                  <td>
                    <b>Control de posición</b>
                  </td>
                  <td>Elevación y Azimut</td>
                </tr>
                <tr>
                  <td>
                    <b>Resolución</b>
                  </td>
                  <td>1.5 grados</td>
                </tr>
                <tr>
                  <td>
                    <b>Ganancia de la antena</b>
                  </td>
                  <td>38 dB</td>
                </tr>
                <tr>
                  <td>
                    <b>Polarización</b>
                  </td>
                  <td>Dual</td>
                </tr>
              </tbody>
            </table>
          ]
        },
        {
          title:
            "Implementación de un sistema de monitoreo de huaycos en la quebrada Jicamarca",
          abstract: () => [
            <p>
              El Sistema de Monitoreo de Huaicos (SMH) desarrollado por el
              IGP-ROJ, monitorea la crecida de caudal en los cauces de los ríos
              Huaycoloro y Rioseco mediante: sensores de continuidad, sensores
              de nivel y cámara Ip. La información obtenida es mostrada en la
              aplicación web http://jro-huaycos.igp.gob.pe. El Sistema una vez
              detectado la presencia de algún evento de huaico envía
              notificaciones de alerta mediante correos electrónicos y Facebook.
            </p>
          ]
        },
        {
          title:
            "Desarrollo de sistema de apunte electrónico de la antena principal del radar de Jicamarca",
          abstract: () => [
            <p>
              El proyecto ABS (Automatic Beam Switching) consiste en desarrollar
              un sistema que permita direccionar el haz de la antena principal
              del Radio Observatorio de Jicamarca en pocos segundos, esto
              permitirá diseñar nuevos experimentos. Actualmente el proyecto se
              ha desarrollado para los cuartos Norte y Sur y consiste en 32
              módulos de control y 64 módulos de RF.
            </p>
          ]
        },
        {
          title: "Desarrollo de SANT´s para monitoreo de volcanes",
          abstract: () => [
            <p>
              El proyecto contempla la implementación de un sistema de monitoreo
              aéreo, usando naves aéreas no tripuladas (‘UAV’s por sus siglas en
              Inglés) para resolver tres problemas: reducir drásticamente la
              exposición al peligro por parte de los investigadores, facilitar
              toma de datos de manera más seguida desde diferentes puntos de
              vista, y obtener una mejor visualización de datos de la caldera a
              través de cámaras ópticas e infrarrojas.
            </p>
          ]
        },
        {
          title:
            "Desarrollo de sistema de medición del TEC (contenido total de electrones) para nano-satélites",
          abstract: () => [
            <p>
              El proyecto consiste en el desarrollo de un instrumento de
              transmisión de señales de radiofrecuencia como carga útil de
              nanosatélites y la implementación de una estación receptora
              terrena que permita recibir las señales del transmisor que se
              propagan a través de la ionosfera para propósitos de investigación
              científica de esta región del espacio.
            </p>
          ]
        },
        {
          title: "Desarrollo de sistema de recepción de radares",
          abstract: () => [
            <p>
              El Radio Observatorio de Jicamarca cuenta con diferentes sistemas
              de radar, entre los que podemos mencionar radares ionosféricos,
              radares MST y perfiladores de vientos. Un sistema de recepción
              está compuesto por diferentes equipos entre los que podemos
              mencionar sistemas de adquisición, controlador de radar, DDS,
              generador de clock, divisores/multiplicadores de pulsos. Dada la
              naturaleza del ROJ, constantemente se realizan actualizaciones a
              estos equipos o se desarrollan nuevas versiones con nuevas
              tecnologías como es el caso del sistema de adquisición JARS cuya
              versión 2.0 se encuentra en desarrollo para mejorar su ancho de
              banda con tecnología gigabit ethernet.
            </p>
          ]
        },
        {
          title:
            "Desarrollo de librería de procesamiento de datos de radar (Signal chain)",
          abstract: () => [
            <p>
              Signal Chain es un proyecto en curso del ROJ, que tiene por
              objetivo desarrollar librerías de código abierto para el
              procesamiento de señales de la información adquirida con radares
              científicos. El objetivo final de estas librerías es compartirlas
              con la comunidad científica que utiliza estos dispositivos, a fin
              de fomentar la colaboración entre las diferentes instituciones
              dedicadas a este campo de estudio.
            </p>
          ]
        }
      ]
    }
  ],
  cah: [
    {
      type: "Proyectos de Investigación",
      slug: "investigacion",
      data: [
        {
          title:
            "Sistema de previsión de eventos hidrológicos extremos estacionales en la cuenca amazónica peruana",
          abstract: () => [
            <p>
              Entre 2014 y 2017, con el financiamiento de Innovate-Perú, -en
              colaboración con la ANA, IRD de Francia y SENAMHI- se desarrolló
              el proyecto “Sistema de previsión de eventos hidrológicos extremos
              estacionales en la cuenca amazónica peruana”, el cual tuvo como
              objetivo establecer bases científicas para elaborar un sistema de
              previsión de los eventos hidrológicos extremos en los grandes ríos
              de la Amazonía peruana. Los resultados llevaron a la sustentación
              de 3 tesis de pregrado, 1 tesis de maestría y 1 tesis de
              doctorado. Derivó también en la publicación como primer autor de
              al menos 7 artículos de investigación en revistas indexadas; estos
              son sustento técnico y científico del estudio de los eventos
              hidrológicos extremos en la Amazonía peruana, que provee reportes
              técnicos para su previsión mediante la plataforma web{" "}
              <a href="http://intranet.igp.gob.pe/eventos-extremos-amazonia-peruana/">
                http://intranet.igp.gob.pe/eventos-extremos-amazonia-peruana/
              </a>
            </p>
          ],
          team: [
            "Jhan Carlo Espinoza",
            "James Apaestegui",
            "Ricardo Zubieta",
            "Hans Segura",
            "Laura Paccini",
            "Omar Gutierrez"
          ]
        },
        {
          title:
            "Sistema Computacional de Alto Rendimiento para la Simulación de Fluidos Geofísicos",
          abstract: () => [
            <p>
              Este proyecto propuso la implementación de un sistema
              computacional de alto rendimiento para la Simulación de Fluidos
              Geofísicos que cubra la “necesidad computacional” de las actuales
              investigaciones científicas de alto nivel que se desarrollan en el
              Instituto Geofísico del Perú (IGP), con especial énfasis en las
              actividades relacionadas al modelado numérico en el estudio del
              Fenómeno El Niño y el Cambio Climático, a fin de ampliar el
              conocimiento de los fenómenos geofísicos naturales y/o de origen
              antropogénico. El sistema computacional implementado es una
              estructura moderna y de última generación capaz de procesar más de
              14TFlops, cuyo diseño “tipo clúster” permite la expansión continua
              de la capacidad de memoria y de almacenamiento de acuerdo a las
              necesidades y recursos financieros disponibles. Actualmente, el
              HPC-Linux-Cluster forma parte del Laboratorio de Dinámica de
              Fluidos Geofísicos Computacional (LDFGC) y se encuentra al
              servicio de la comunidad científica nacional (e.g., universidades)
              a través de{" "}
              <a
                href="https://scah.igp.gob.pe/laboratorios/dfgc"
                target="_blank"
              >
                https://scah.igp.gob.pe/laboratorios/dfgc
              </a>
            </p>
          ],
          team: [
            "Ivonne Montes",
            "Kobi Mosquera",
            "Berlín Segura",
            "Huber Gilt",
            "Oscar Santillán",
            "Augusto Ingunza",
            "Boris Dewitte",
            "Didier Gazen"
          ]
        },
        {
          title: "Proyecto “VAMOS Ocean-Cloud-Land-Atmosphere Study (VOCALS)",
          abstract: () => [
            <p>
              El proyecto& VOCALS es un esfuerzo internacional para mejorar el
              entendimiento de los factores que afectan el clima en el océano
              Pacífico suroriental, frente a la costa de Sudamérica. Este
              interés está fuertemente motivado por la gran influencia que
              tienen las persistentes nubes bajas en esta región sobre el
              balance de energía del planeta, las cuales son además una de las
              principales fuentes de incertidumbre en los escenarios climáticos
              futuros. La participación del Perú en el proyecto VOCALS ha estado
              orientada a un mejor entendimiento de los procesos relevantes al
              ecosistema del mar peruano. En particular, el Instituto Geofísico
              del Perú (IGP) se ha concentrado en entender los aspectos
              meteorológicos cerca a la costa utilizando una combinación de
              observaciones, teoría y modelos. Mayor información en{" "}
              <a href="https://scah.igp.gob.pe/vocals" target="_blank">
                https://scah.igp.gob.pe/vocals
              </a>
            </p>
          ],
          team: [
            "Ivonne Montes",
            "Kobi Mosquera",
            "Berlín Segura",
            "Huber Gilt",
            "Oscar Santillán",
            "Augusto Ingunza",
            "Boris Dewitte",
            "Didier Gazen"
          ]
        }
      ]
    },
    {
      type: "Proyectos de Desarrollo Tecnológico",
      slug: "tecnologico"
    }
  ],
  ide: [
 //    {
 //      slug: "normatividad-IDE-IGP",
 //      origin: "acerca-de",
 //      type: "Normatividad IDE IGP",
 //      data: [
 //        {
 //          title: ` Decreto Supremo N° 069-2011-PCM (27. Jul.2011)
 // Se crea el Portal  de la Información de Datos  Espaciales del Perú” GEOIDEP) como ventanilla única de Internet que  permite el acceso a todos los datos, información y servicios geográficos que brindan los organismos públicos y privados del Perú. En este  Portal, todas las entidades deben reportar los metadatos de los  productos que  generen. Se aplica a todas las entidades de la Administración Pública que integran el Sistema Nacional de Informática  que  generen y utilicen información de mapas cartográficos básicos y temáticos. Cabe resaltar que  se establece la obligatoriedad de las entidades de designar al Jefe  de la unidad informática o quien haga sus veces como responsable encargado de coordinar la publicación y catalogación de los datos, servicios  y aplicaciones geoespaciales de la información territorial de la entidad que  representa, quien se encargará además de actualizar las publicaciones que  se generen en forma permanente.`,
 //          content: () => [
 //            <p>Decreto Supremo N° 069-2011-PCM (27. Jul.2011)</p>,
 //            <p>
 //              Se crea el Portal de la Información de Datos Espaciales del Perú”
 //              GEOIDEP) como ventanilla única de Internet que permite el acceso a
 //              todos los datos, información y servicios geográficos que brindan
 //              los organismos públicos y privados del Perú. En este Portal, todas
 //              las entidades deben reportar los metadatos de los productos que
 //              generen. Se aplica a todas las entidades de la Administración
 //              Pública que integran el Sistema Nacional de Informática que
 //              generen y utilicen información de mapas cartográficos básicos y
 //              temáticos. Cabe resaltar que se establece la obligatoriedad de las
 //              entidades de designar al Jefe de la unidad informática o quien
 //              haga sus veces como responsable encargado de coordinar la
 //              publicación y catalogación de los datos, servicios y aplicaciones
 //              geoespaciales de la información territorial de la entidad que
 //              representa, quien se encargará además de actualizar las
 //              publicaciones que se generen en forma permanente.
 //            </p>
 //          ],
 //          link:
 //            "https://www.geoidep.gob.pe/marco-normativo-institucional-de-la-idep/normas-basicas/decretosupremon069-2011-pcm"
 //        },
 //        {
 //          title: `Decreto Supremo N° 133-2013-PCM
 // Se establece el acceso e intercambio de información espacial entre entidades de la Administración Pública. Específicamente en su artículo 9° se establece que  las entidades de la Administración Pública deben registrar los servicios web  de información espacial de las capas de información que administran, en el marco de su competencia, en la IDE del Perú.`,
 //          content: () => [
 //            <p>Decreto Supremo N° 133-2013-PCM</p>,
 //            <p>
 //              Se establece el acceso e intercambio de información espacial entre
 //              entidades de la Administración Pública. Específicamente en su
 //              artículo 9° se establece que las entidades de la Administración
 //              Pública deben registrar los servicios web de información espacial
 //              de las capas de información que administran, en el marco de su
 //              competencia, en la IDE del Perú.
 //            </p>
 //          ],
 //          link:
 //            "https://busquedas.elperuano.pe/normaslegales/decreto-supremo-mediante-el-cual-se-establece-el-acceso-e-in-decreto-supremo-n-133-2013-pcm-1032381-1"
 //        }
 //      ]
 //    },
    {
      slug: "aplicaciones",
      origin: "",
      type: "Aplicaciones",
      content: () => [
        <p>
          La IDE - IGP dispone de  herramientas para el uso de los ciudadanos tales como: 
          consultas de los servicios georreferenciados, geoprocesamiento de la información geofísica, 
          intersección de capas,  impresión de mapas, entre otros; cuyo principal objetivo es brindar 
          al ciudadano la información de manera fácil e interactiva al momento de su visualización 
          y/o consulta.
        </p>,
      ],
      data: [
        {
          title: ` Decreto Supremo N° 069-2011-PCM (27. Jul.2011)
 Se crea el Portal  de la Información de Datos  Espaciales del Perú” GEOIDEP) como ventanilla única de Internet que  permite el acceso a todos los datos, información y servicios geográficos que brindan los organismos públicos y privados del Perú. En este  Portal, todas las entidades deben reportar los metadatos de los  productos que  generen. Se aplica a todas las entidades de la Administración Pública que integran el Sistema Nacional de Informática  que  generen y utilicen información de mapas cartográficos básicos y temáticos. Cabe resaltar que  se establece la obligatoriedad de las entidades de designar al Jefe  de la unidad informática o quien haga sus veces como responsable encargado de coordinar la publicación y catalogación de los datos, servicios  y aplicaciones geoespaciales de la información territorial de la entidad que  representa, quien se encargará además de actualizar las publicaciones que  se generen en forma permanente.`,
          content: () => [
            <p>Decreto Supremo N° 069-2011-PCM (27. Jul.2011)</p>,
            <p>
              Se crea el Portal de la Información de Datos Espaciales del Perú”
              GEOIDEP) como ventanilla única de Internet que permite el acceso a
              todos los datos, información y servicios geográficos que brindan
              los organismos públicos y privados del Perú. En este Portal, todas
              las entidades deben reportar los metadatos de los productos que
              generen. Se aplica a todas las entidades de la Administración
              Pública que integran el Sistema Nacional de Informática que
              generen y utilicen información de mapas cartográficos básicos y
              temáticos. Cabe resaltar que se establece la obligatoriedad de las
              entidades de designar al Jefe de la unidad informática o quien
              haga sus veces como responsable encargado de coordinar la
              publicación y catalogación de los datos, servicios y aplicaciones
              geoespaciales de la información territorial de la entidad que
              representa, quien se encargará además de actualizar las
              publicaciones que se generen en forma permanente.
            </p>
          ],
          link:
            "https://www.geoidep.gob.pe/marco-normativo-institucional-de-la-idep/normas-basicas/decretosupremon069-2011-pcm"
        }
      ]
    },
    {
      slug: "webservice",
      origin: "",
      type: "Webservices",
      content: () => [
        <p>
          Son servicios de visualización de la información geoespacial que cumplen con los diferentes estándares aprobados 
          para la producción, uso e intercambio de datos entre entidades públicas y privadas. Mediante ellos, los usuarios 
          pueden consultar datos de un elemento geoespacial según el tipo de servicio (WMS, WFS) que se encuentre residiendo 
          en un servidor. Su funcionalidad es la de intercambiar la información con usuarios SIG a través de su uso en entornos 
          WebGIS o softwares GIS Desktop.
        </p>,
        <p>
          Los servicios WMS permiten la visualización, superposición y consulta de información geoespacial generada desde uno 
          o varios servidores en una entidad. En tanto, los servicios WFS permiten la descarga de la información geoespacial 
          vectorial completa, su geometría y tabla de atributos asociada.
        </p>,
      ],
      data: [
        {
          title: ` Decreto Supremo N° 069-2011-PCM (27. Jul.2011)
 Se crea el Portal  de la Información de Datos  Espaciales del Perú” GEOIDEP) como ventanilla única de Internet que  permite el acceso a todos los datos, información y servicios geográficos que brindan los organismos públicos y privados del Perú. En este  Portal, todas las entidades deben reportar los metadatos de los  productos que  generen. Se aplica a todas las entidades de la Administración Pública que integran el Sistema Nacional de Informática  que  generen y utilicen información de mapas cartográficos básicos y temáticos. Cabe resaltar que  se establece la obligatoriedad de las entidades de designar al Jefe  de la unidad informática o quien haga sus veces como responsable encargado de coordinar la publicación y catalogación de los datos, servicios  y aplicaciones geoespaciales de la información territorial de la entidad que  representa, quien se encargará además de actualizar las publicaciones que  se generen en forma permanente.`,
          content: () => [
            <p>Decreto Supremo N° 069-2011-PCM (27. Jul.2011)</p>,
            <p>
              Se crea el Portal de la Información de Datos Espaciales del Perú”
              GEOIDEP) como ventanilla única de Internet que permite el acceso a
              todos los datos, información y servicios geográficos que brindan
              los organismos públicos y privados del Perú. En este Portal, todas
              las entidades deben reportar los metadatos de los productos que
              generen. Se aplica a todas las entidades de la Administración
              Pública que integran el Sistema Nacional de Informática que
              generen y utilicen información de mapas cartográficos básicos y
              temáticos. Cabe resaltar que se establece la obligatoriedad de las
              entidades de designar al Jefe de la unidad informática o quien
              haga sus veces como responsable encargado de coordinar la
              publicación y catalogación de los datos, servicios y aplicaciones
              geoespaciales de la información territorial de la entidad que
              representa, quien se encargará además de actualizar las
              publicaciones que se generen en forma permanente.
            </p>
          ],
          link:
            "https://www.geoidep.gob.pe/marco-normativo-institucional-de-la-idep/normas-basicas/decretosupremon069-2011-pcm"
        }
      ]
    }
  ]
};
function getProjects(program) {
  return projects[program] || "cts";
}
export { getProjects };
