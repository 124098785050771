<template>
  <section class="projects">
    <Container>
      <Title tag="h2" class="is-centered">{{ title }}</Title>
      <Row>
        <ColumnUi md="6" lg="3" class="project">
          <div class="icon-wrapper">
            <IconPNG png="images/engineIcon.png" class="icon-wrapper" />
          </div>
          <div class="project__info">
            <h3 class="project__title">Visor Geográfico</h3>
            <span
              ><Button
                tag="router-link"
                to="/componentes/visor-geografico"
                theme="primary"
                >Conoce más</Button
              ></span
            >
          </div>
        </ColumnUi>
        <ColumnUi md="6" lg="3" class="project">
          <div class="icon-wrapper">
            <IconPNG png="images/engineIcon.png" class="icon-wrapper" />
          </div>
          <div class="project__info">
            <h3 class="project__title">Webservices</h3>
            <span
              ><Button
                tag="router-link"
                to="/componentes/webservice"
                theme="primary"
                >Conoce más</Button
              ></span
            >
          </div>
        </ColumnUi>
        <ColumnUi md="6" lg="3" class="project">
          <div class="icon-wrapper">
            <IconPNG png="images/engineIcon.png" class="icon-wrapper" />
          </div>
          <div class="project__info">
            <h3 class="project__title">Catálogo de Metadatos</h3>
            <span>
              <Button
                tag="router-link"
                to="/componentes/metadatos"
                theme="primary"
                >Conoce más</Button
              >
            </span>
          </div>
        </ColumnUi>
        <ColumnUi md="6" lg="3" class="project">
          <div class="icon-wrapper">
            <IconPNG png="images/engineIcon.png" class="icon-wrapper" />
          </div>
          <div class="project__info">
            <h3 class="project__title">Datos abiertos</h3>
            <span
              ><Button
                tag="router-link"
                to="/componentes/datos-abiertos"
                theme="primary"
                >Conoce más</Button
              ></span
            >
          </div>
        </ColumnUi>
      </Row>
    </Container>
  </section>
</template>
<script>
import Container from "@/components/ui/objects/Container";
import Button from "@/components/ui/atoms/Button";
import IconPNG from "@/components/ui/atoms/IconPNG";
import Row from "../ui/atoms/Row.vue";
import ColumnUi from "../ui/atoms/ColumnUi.vue";
import Title from "../ui/atoms/Title.vue";
export default {
  props: {
    title: String,
  },
  components: {
    Container,
    Button,
    IconPNG,
    Row,
    ColumnUi,
    Title,
  },
};
</script>
<style lang="scss" scoped>
@use "@/assets/styles/settings/_variables.scss";
@use "@/assets/styles/tools/_mixins.scss";
.projects {
  padding: variables.$spacing-l 0;
  background-color: #fafafa;
}
.project {
  display: flex;
  align-items: center;
  grid-gap: 0 1.5em;
  margin-bottom: 2em;
  .icon-wrapper {
    min-width: 100px;
    height: 100px;
    background-color: variables.$primary-color;
    border-radius: 50%;
    @include mixins.centered;
  }
  &__info {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  &__icon {
    height: 3em;
    fill: variables.$text-inverted-color;
    margin: 0;
  }
}
@media (min-width: variables.$lg) {
  .project {
    margin-bottom: 0;
  }
}
</style>
