<template>
  <table class="table">
    <thead class="table__head">
      <slot name="header" />
    </thead>
    <tbody class="table__body">
      <slot name="body" />
      <!--
            <tr class="pagination" span="2">
                <span>Filas por página</span>
                <div class="select">
                    <select :class="`input__field${active && ' active' || ''}`" minlength="5" required="required" @focus="isFocused" @blur="isNotFocused" v-model="rows">
                        <option value="5">5</option>
                        <option value="10">10</option>
                        <option value="20">20</option>
                    </select>
                    <span>1-5 de 10</span>
                </div>
                <Button tag="button">
                    <Icon svg="firstPage" />
                    <Icon svg="prevPage" />
                    <Icon svg="nextPage" />
                    <Icon svg="lastPage" />
                </Button>
            </tr>
            -->
    </tbody>
  </table>
</template>
<script>
export default {
  props: {
    marked: {
      type: Boolean,
      default: false
    }
  }
};
</script>
<style lang="scss">
@use "@/assets/styles/settings/_variables.scss";
.table {
  /* border: none;
    */
  border-radius: variables.$br-box;
  border-style: hidden;
  margin: variables.$spacing 0;
  min-width: 300px;
  box-shadow: variables.$bs-table;
  border-bottom: 1px solid variables.$primary-color;
  &--collapse {
    border-collapse: collapse;
  }
  &__head {
    tr {
      font-size: variables.$fs-thead;
      background-color: variables.$primary-color;
      color: variables.$text-inverted-color;
      text-align: left;
      th {
        font-weight: 300;
        &:first-of-type {
          border-top-left-radius: variables.$br-box;
        }
        &:last-of-type {
          border-top-right-radius: variables.$br-box;
        }
      }
    }
  }
  th,
  td {
    padding: 12px 15px;
  }
  &__body {
    tr {
      &:nth-of-type(even) {
        background-color: variables.$background-lighter-color;
      }
      &:last-of-type {
        border-bottom: 2px solid variables.$primary-color;
        td:first-of-type {
          border-bottom-left-radius: variables.$br-box;
        }
        td:last-of-type {
          border-bottom-right-radius: variables.$br-box;
        }
      }
    }
  }
}
</style>
